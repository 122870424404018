import { IAuthRepositoryResponseDTO } from "./__types__/dtoResponse";
import { Utils } from "@modules/utils";
import { TDtoUserEntry } from "@modules/utils/dto/parsers/__types__/TDtoUserEntry.type";

export class AuthRepositoryResponseDTO implements IAuthRepositoryResponseDTO {
  public static loginByEmail = (data: unknown): TDtoUserEntry =>
    Utils.dto.transform(Utils.dto.Schemes.User, data, () =>
      Utils.dto.transform(Utils.dto.Schemes.User, data, Utils.dto.Parsers.User)
    );
}
