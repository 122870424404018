import React, { useContext } from "react";
import { IScenesInstance } from "@viewModels/scenes/__types__/ScenesInstance";

export const SceneContext = React.createContext<IScenesInstance<
  any,
  any
> | null>(null);

/**
 * The useScene React Hook allows developers to access the Scene Context within
 * the React component hierarchy. It provides access to the current scene, as well as
 * the actions that can be performed on the scene such as update and delete. This gives
 * React components the power to interact with the scene and make changes to it in a
 * straightforward way. With this hook, the entire system of state and data can be
 * managed within the React component tree.
 */
export const useScene = <T extends IScenesInstance<any, any>>(): T => {
  const context = useContext(SceneContext);
  if (context === null) {
    throw new Error("Context cannot be null, please add a context provider");
  }
  return context as T;
};
