import { IScreenControllerDocContract } from "@screens/DocContractsScreen/connector/__types__/UIViewController";
import { TranslationService } from "@services/translate";
import TableOutput from "@shared/controls/TableOutput/TableOutput";
import { useControllers } from "@view/hooks/useControllers";
import { observer } from "mobx-react";
import moment from "moment";
import React from "react";

const headerList = {
  contract: [TranslationService.t("txt_col_contract")],
  // eslint-disable-next-line sort-keys/sort-keys-fix
  conclusionDate: [TranslationService.t("txt_col_date"), true],
  termDate: [TranslationService.t("txt_col_term"), true],
  // eslint-disable-next-line sort-keys/sort-keys-fix
  supplier: [TranslationService.t("txt_col_supplier")],
  // eslint-disable-next-line sort-keys/sort-keys-fix
  region: [TranslationService.t("txt_col_region")],
};

export const ContractsTable = observer((): JSX.Element => {
  const { UIViewController } = useControllers<{
    UIViewController: IScreenControllerDocContract;
  }>();

  return (
    <TableOutput
      sortBy={UIViewController.listOfContractsFilters.order}
      sortDir={UIViewController.listOfContractsFilters.direction}
      onChangeOrder={UIViewController.setFieldOrderBy}
      headerObj={headerList}
      isLoading={UIViewController.isRequestInProgress}
      resetFilters={() => UIViewController.resetFilters()}
      list={UIViewController.listOfContractsMetadata.data.map((contract) => ({
        conclusionDate: moment(contract.conclusionDate).format("DD.MM.YY"),
        contract: contract.number,
        region: contract.regions.map((region) => region.name).join(", "),
        supplier: contract.contractor.name,
        termDate: moment(contract.termDate).format("DD.MM.YY"),
      }))}
    />
  );
});
