import React, { useCallback, useRef } from "react";
import { INotificationListItemEntity } from "@entities/NotificationListItemEntity/NotificationListItemEntity";
import moment from "moment";
import { TranslationService } from "@services/translate";
import { IconCheck } from "@shared/controls/icons";
import { useWindowWidth } from "@view/hooks/useWindowWidth";

type Props = {
  notification: INotificationListItemEntity;
  onRemoveNotification?: (notification: INotificationListItemEntity) => void;
};

const NotificationItem = ({
  notification,
  onRemoveNotification,
}: Props): JSX.Element => {
  const date = useRef<string>(
    moment(notification.dt).isSame(moment(), "date")
      ? TranslationService.t("txt_now")
      : moment(notification.dt).format("DD.MM.YY")
  ).current;

  const windowWidth = useWindowWidth();

  const onCheckIconPress = useCallback(() => {
    if (onRemoveNotification) onRemoveNotification(notification);
  }, []);

  return (
    <div
      className="notification"
      style={{
        alignItems: "flex-start",
        background: "#FCFCFD",
        border: "1px #DBDCE1 solid",
        borderRadius: 6,
        display: "inline-flex",
        flexDirection: "column",
        gap: 14,
        justifyContent: "flex-start",
        minHeight: 120,
        padding: 20,
        width: windowWidth > 768 ? 316 : "100%",
      }}
    >
      <div
        className="Frame513166"
        style={{
          alignItems: "flex-start",
          alignSelf: "stretch",
          display: "inline-flex",
          justifyContent: "space-between",
        }}
      >
        <div
          className="Timestamp"
          style={{
            color: "#303B57",
            flex: "1 1 0",
            fontFamily: "Inter",
            fontSize: 12,
            fontWeight: "400",
            height: 16,
            // lineHeight: 16,
            wordWrap: "break-word",
          }}
        >
          {date}
        </div>
        <div
          className="Icons"
          onClick={onCheckIconPress}
          style={{
            alignItems: "center",
            background: "#F0F2F8",
            borderRadius: 100,
            display: "flex",
            gap: 10,
            height: 20,
            justifyContent: "center",
            padding: 6,
            width: 20,
          }}
        >
          <div
            className="Icons"
            style={{
              alignItems: "center",
              display: "flex",
              height: 10,
              justifyContent: "center",
              position: "relative",
              width: 10,
            }}
          >
            <IconCheck />
          </div>
        </div>
      </div>
      <div
        className="Frame513165"
        style={{
          alignItems: "flex-start",
          alignSelf: "stretch",
          display: "flex",
          flexDirection: "column",
          gap: 6,
          // height: 46,
          justifyContent: "flex-start",
        }}
      >
        <div
          className={`status--${notification.type}`}
          style={{
            color: "#303B57",
            fontFamily: "Inter",
            fontSize: 16,
            fontWeight: "700",
            // lineHeight: 24,
            wordWrap: "break-word",
          }}
        >
          {notification.title}
        </div>
        <div
          style={{
            color: "#303B57",
            fontFamily: "Inter",
            fontSize: 12,
            fontWeight: "400",
            // lineHeight: 16,
            wordWrap: "break-word",
          }}
        >
          {notification.message}
        </div>
      </div>
    </div>
  );
};

export default NotificationItem;
