import { ScreenControllerFuelingTurnover } from "@screens/HomeScreen/connector/UIViewController";
import { Sorting } from "@shared/controls/Sorting/Sorting";
import { useControllers } from "@view/hooks/useControllers";
import { observer } from "mobx-react";

export const SortingMobile = observer((): JSX.Element => {
  const { UIViewController } = useControllers<{
    UIViewController: ScreenControllerFuelingTurnover;
  }>();

  return (
    <Sorting
      list={[
        [
          "По остатку веса, кг",
          "remains",
          "Сначала с меньшим",
          "Сначала с большим",
        ],
        [
          "По расходу веса, кг",
          "expense",
          "Сначала с меньшим",
          "Сначала с большим",
        ],
        [
          "По приходу веса, кг",
          "income",
          "Сначала с меньшим",
          "Сначала с большим",
        ],
      ]}
      sortBy={UIViewController.listOfFuelingTurnoverFilters.order}
      sortDir={UIViewController.listOfFuelingTurnoverFilters.direction}
      onChangeOrder={UIViewController.setFieldOrderBy}
    />
  );
});
