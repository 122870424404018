/* eslint-disable @typescript-eslint/no-empty-interface */
import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";

const RecalculationsListContractorItemEntity = types.model({
  id: types.number,
  name: types.optional(types.string, ""),
  region: types.optional(types.array(types.number), []),
});

export const RecalculationsListItemEntity = types
  .model({
    contractor: types.optional(RecalculationsListContractorItemEntity, {
      id: 0,
      name: "",
    }),
    deliveryDate: types.optional(types.string, ""),
    id: types.identifierNumber,
    number: types.optional(types.string, ""),
    numberRecalculations: types.optional(types.string, ""),
    price: types.optional(types.string, ""),
    regions: types.optional(
      types.array(
        types.model({
          id: types.number,
          name: types.string,
        })
      ),
      []
    ),
    weight: types.optional(types.string, ""),
  })
  .named("RecalculationsListItemEntity");

export interface IRecalculationsListItemEntity
  extends Instance<typeof RecalculationsListItemEntity> {}
export interface IRecalculationsListItemEntityIn
  extends SnapshotIn<IRecalculationsListItemEntity> {}
export interface IRecalculationsListItemEntityOut
  extends SnapshotOut<IRecalculationsListItemEntity> {}
