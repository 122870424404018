import {
  DateFilerType,
  IDateFilterState,
  IDatePeriodFilterViewModel,
  IDatePeriodFilterViewModelParams,
} from "./__types__/IDatePeriodFilterViewModel.types";
import { observable } from "mobx";
import moment from "moment";

/**
 * Represents a mapping of date filter types to functions that generate the initial filter state.
 */
const datePeriodMapping: {
  [key in DateFilerType]:
    | (() => IDateFilterState)
    | ((start: number, end: number) => IDateFilterState);
} = {
  [DateFilerType.All]: () => ({
    end:
      moment()
        .set("year", moment().year() + 2)
        .set("hour", 23)
        .set("minutes", 53)
        .unix() * 1000,
    start:
      moment()
        .set("year", moment().year() - 4)
        .set("hour", 0)
        .set("minutes", 0)
        .unix() * 1000,
    type: DateFilerType.All,
  }),
  [DateFilerType.Year]: () => ({
    end:
      moment().endOf("year").set("hour", 23).set("minutes", 53).unix() * 1000,
    start:
      moment().startOf("year").set("hour", 0).set("minutes", 0).unix() * 1000,
    type: DateFilerType.Year,
  }),

  [DateFilerType.Month]: () => ({
    end:
      moment().endOf("month").set("hour", 23).set("minutes", 53).unix() * 1000,
    start:
      moment().startOf("month").set("hour", 0).set("minutes", 0).unix() * 1000,
    type: DateFilerType.Month,
  }),

  [DateFilerType.Week]: () => ({
    end:
      moment().endOf("week").set("hour", 23).set("minutes", 53).unix() * 1000,
    start:
      moment()
        .startOf("week")
        // .set("week", moment().week() - 1)
        .set("hour", 0)
        .set("minutes", 0)
        .unix() * 1000,
    // moment()
    //   .set("week", moment().week() - 1)
    //   .set("hour", 0)
    //   .set("minutes", 0)
    //   .unix() * 1000,
    type: DateFilerType.Week,
  }),

  [DateFilerType.Custom]: (start: number, end: number) => ({
    end,
    start,
    type: DateFilerType.Custom,
  }),
};

/**
 * Represents a view model for date period filtering.
 */
export class DatePeriodFilterViewModel implements IDatePeriodFilterViewModel {
  /**
   * The state of the view model.
   */
  public state: IDateFilterState;

  /**
   * Initializes a new instance of the DatePeriodFilterViewModel class.
   *
   * @param {IDatePeriodFilterViewModelParams} params - The parameters for the view model.
   */
  public constructor(params: IDatePeriodFilterViewModelParams) {
    if (!params.type) {
      // @ts-ignore
      this.state = observable.object(datePeriodMapping[DateFilerType.All]());
    } else if (params.type === DateFilerType.Custom) {
      this.state = observable.object(
        datePeriodMapping[DateFilerType.Custom](params.start, params.end)
      );
    } else {
      // @ts-ignore
      this.state = observable.object(datePeriodMapping[params.type]());
    }
  }

  /**
   * Sets the filter to the year period.
   */
  public setYearFilter = (): void => {
    this.state = {
      ...this.state,
      end: moment().set("hour", 23).set("minutes", 53).unix() * 1000,
      start:
        moment()
          .set("year", moment().year() - 1)
          .set("hour", 0)
          .set("minutes", 0)
          .unix() * 1000,
      type: DateFilerType.Year,
    };
  };

  /**
   * Sets the filter to the month period.
   */
  public setMonthFilter = (): void => {
    this.state = {
      ...this.state,
      end: moment().set("hour", 23).set("minutes", 53).unix() * 1000,
      start:
        moment()
          .set("month", moment().month() - 1)
          .set("hour", 0)
          .set("minutes", 0)
          .unix() * 1000,
      type: DateFilerType.Month,
    };
  };

  /**
   * Sets the filter to the week period.
   */
  public setWeekFilter = (): void => {
    this.state = {
      ...this.state,
      end: moment().set("hour", 23).set("minutes", 53).unix() * 1000,
      start:
        moment()
          .set("week", moment().week() - 1)
          .set("hour", 0)
          .set("minutes", 0)
          .unix() * 1000,
      type: DateFilerType.Week,
    };
  };

  /**
   * Sets the filter to the all period.
   */
  public setAllFilter = (): void => {
    this.state = {
      ...this.state,
      end:
        moment()
          .set("year", moment().year() + 2)
          .set("hour", 23)
          .set("minutes", 53)
          .unix() * 1000,
      start:
        moment()
          .set("year", moment().year() - 4)
          .set("hour", 0)
          .set("minutes", 0)
          .unix() * 1000,
      type: DateFilerType.All,
    };
  };

  /**
   * Sets the filter to a custom period.
   *
   * @param {number} start - The start timestamp of the custom period.
   * @param {number} end - The end timestamp of the custom period.
   */
  public setCustomFilter = (start: number, end: number): void => {
    this.state = {
      ...this.state,
      end,
      start,
      type: DateFilerType.Custom,
    };
  };

  public setFilter = (
    params: IDatePeriodFilterViewModelParams | null
  ): void => {
    let datePeriod: IDateFilterState;

    if (!params) {
      // @ts-ignore
      datePeriod = datePeriodMapping[DateFilerType.All]();
    } else if (params.type === DateFilerType.Custom) {
      datePeriod = datePeriodMapping[DateFilerType.Custom](
        params.start,
        params.end
      );
    } else {
      // @ts-ignore
      datePeriod = datePeriodMapping[params.type]();
    }

    this.state.end = datePeriod.end;
    this.state.start = datePeriod.start;
    this.state.type = datePeriod.type;
  };

  /**
   * Performs necessary clean up before destroying the view model.
   */
  public beforeDestroy(): void {
    // Object.values(this.reactions).forEach((r) => r());
  }
}
