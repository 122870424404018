import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";

export const VehicleListItemEntity = types
  .model({
    id: types.number,
    number: types.optional(types.string, ""),
  })
  .named("VehicleListItemEntity");

export type IVehicleListItemEntity = Instance<typeof VehicleListItemEntity>;
export type IRegionsListItemEntityIn = SnapshotIn<IVehicleListItemEntity>;
export type IRegionsListItemEntityOut = SnapshotOut<IVehicleListItemEntity>;
