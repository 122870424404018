import {
  IAuthRepository,
  TLoginByEmailBody,
  TAuthUserResponseEntry,
  TLoginByEmailResponse,
  TJWTDecodedUser,
  TResetPasswordEmail,
  TPlainPassword,
} from "./__types__/repository";
import { AuthRepositoryResponseDTO } from "./dtoResponse";
import jwt_decode from "jwt-decode";
import { IRequestClient } from "@modules/request/types/instance";
import axios from "axios";
// import { useNavigate } from "react-router";

export class AuthRepository implements IAuthRepository {
  // public history = useNavigate();

  public readonly abortControllers = {
    changingPassword: new AbortController(),
    loginByEmail: new AbortController(),
    resetPassword: new AbortController(),
  };

  public constructor(private requestClient: IRequestClient) {}

  public loginByEmail = async (
    data: TLoginByEmailBody
  ): Promise<TAuthUserResponseEntry> => {
    if (this.abortControllers.loginByEmail) {
      this.abortControllers.loginByEmail.abort();
    }
    this.abortControllers.loginByEmail = new AbortController();
    let error_msg: string = "";

    const response: TLoginByEmailResponse = await this.requestClient
      .post(`/login_check`, data, {
        signal: this.abortControllers.loginByEmail.signal,
      })
      .catch((error) => {
        error_msg = error?.response?.data?.message;
        localStorage.setItem("AUTH_ERROR_MSG", error_msg);
        return error;
      });

    const user: TJWTDecodedUser = jwt_decode(response.token);

    localStorage.setItem("AUTH_TOKEN", response.token);
    axios.defaults.headers.common = {
      ...axios.defaults.headers.common,
      Authorization: `Bearer ${response.token}`,
    };

    return AuthRepositoryResponseDTO.loginByEmail({
      roles: user.roles,
      username: user.username,
    });
  };

  public resetPassword = async (data: TResetPasswordEmail): Promise<any> => {
    if (this.abortControllers.resetPassword) {
      this.abortControllers.resetPassword.abort();
    }
    this.abortControllers.resetPassword = new AbortController();

    const response: TLoginByEmailResponse = await this.requestClient.post(
      `/password/reset`,
      data,
      {
        signal: this.abortControllers.resetPassword.signal,
      }
    );

    return response;
  };

  public changingPassword = async (
    token: string,
    data: TPlainPassword
  ): Promise<any> => {
    if (this.abortControllers.resetPassword) {
      this.abortControllers.resetPassword.abort();
    }
    this.abortControllers.resetPassword = new AbortController();

    const response: any = await this.requestClient.post(
      `/password/reset/${token}`,
      data,
      {
        signal: this.abortControllers.changingPassword.signal,
      }
    );

    // this.history("/");

    return response;
  };

  public beforeDestroy = (): void => {
    Object.values(this.abortControllers).forEach((abortController) =>
      abortController.abort()
    );
  };
}
