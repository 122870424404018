// import { TranslationService } from "@services/translate";
import {
  IUISceneController,
  TFuelingRequestSceneParams,
  TFuelingRequestSceneResult,
} from "../connector/UISceneController/types";
import Button from "@shared/controls/Button/Button";
import SelectComponent from "@shared/controls/Select/Select";
import Input from "@shared/controls/Input/Input";
import { observer } from "mobx-react";
import { useScene } from "@view/hooks/useScene/useScene";
import { TranslationService } from "@services/translate";
import ToggleButton from "react-bootstrap/ToggleButton";
import FilterComponent from "@shared/helpers/SideForm/SideForm";
import ButtonGroup from "react-bootstrap/esm/ButtonGroup";

const FuelingRequestForm = observer((): JSX.Element => {
  const UISceneController =
    useScene<
      IUISceneController<TFuelingRequestSceneResult, TFuelingRequestSceneParams>
    >();

  return (
    <FilterComponent
      title="Новая заправка"
      onClose={UISceneController.closeScene}
    >
      <div className="custom-form">
        <div className="custom-field">
          <label>№ борта</label>
          <SelectComponent
            isClearable
            onFocus={() => UISceneController.fetchListOfVehicles()}
            onMenuScrollToBottom={() => UISceneController.fetchListOfVehicles()}
            options={UISceneController.listOfVehiclesMetadata.data.map(
              (vehicle, index) => ({
                id: vehicle.id,
                index,
                label: vehicle.number,
                number: vehicle.number,
                value: vehicle.id,
              })
            )}
            isMulti={false}
            onInputChange={UISceneController.setVehicleSearch}
            isLoading={UISceneController.isVehiclesLoading}
            value={
              UISceneController.createFuelingState.vehicle
                ? {
                    label: UISceneController.createFuelingState.vehicle.number,
                    value: UISceneController.createFuelingState.vehicle.id,
                  }
                : null
            }
            onChange={(vehicle: any) => UISceneController.setVehicle(vehicle)}
          />
        </div>
        <div className="custom-field">
          <label>№ топливного требования</label>
          <Input
            placeholder="Введите номер топливного требования"
            value={UISceneController.createFuelingState.number}
            type="string"
            onChange={(value) => UISceneController.setFuelingNumber(value)}
          />
        </div>
        <div className="custom-field">
          <label>Вес, кг</label>
          <Input
            placeholder="Введите вес топлива"
            value={UISceneController.createFuelingState.weight}
            type="number"
            onChange={(weight: number) => UISceneController.setWeight(weight)}
          />
        </div>
        <div className="custom-field">
          <label>Объем, л</label>
          <Input
            placeholder="Введите объем договора"
            value={UISceneController.createFuelingState.volume}
            type="number"
            onChange={(volume: number) => UISceneController.setVolume(volume)}
          />
        </div>
        <div className="custom-field">
          <label>Тип заправки</label>
          {/* <ToggleButtonGroup type="radio" name="options" defaultValue={null}>
            {[
              { index: 1, text: "Основная" },
              { index: 2, text: "Перегон" },
            ].map((type) => (
              <ToggleButton
                key={type.index}
                checked={
                  Number(UISceneController.createFuelingState.fuelingType) ===
                  Number(type.index)
                }
                value={type.index}
                // onChange={(event) =>
                //   UISceneController.setFuelingType(Number(event.target.value))
                // }
                onClick={() => UISceneController.setFuelingType(type.index)}
              >
                {type.text}
              </ToggleButton>
            ))}
          </ToggleButtonGroup> */}
          <ButtonGroup>
            {[
              { text: "Основная", value: 1 },
              { text: "Перегон", value: 2 },
            ].map((radio, idx) => (
              <ToggleButton
                key={idx}
                id={`fuel-${idx}`}
                type="radio"
                variant="secondary"
                name="radio"
                value={radio.value}
                checked={
                  UISceneController.createFuelingState.fuelingType ===
                  radio.value
                }
                onChange={(event) =>
                  UISceneController.setFuelingType(
                    Number(event.currentTarget.value)
                  )
                }
              >
                {radio.text}
              </ToggleButton>
            ))}
          </ButtonGroup>
        </div>
        <div className="custom-field">
          <label>Регион</label>
          <SelectComponent
            onMenuScrollToBottom={() => UISceneController.fetchListOfRegions()}
            onFocus={() => UISceneController.fetchListOfRegions()}
            options={UISceneController.listOfRegionsMetadata.data.map(
              (region, index) => ({
                id: region.id,
                index,
                label: region.name,
                name: region.name,
                value: region.id,
              })
            )}
            isMulti={false}
            onInputChange={UISceneController.setRegionSearch}
            isClearable
            isLoading={UISceneController.isRegionsLoading}
            value={
              UISceneController.createFuelingState.region
                ? {
                    label: UISceneController.createFuelingState.region.name,
                    value: UISceneController.createFuelingState.region.id,
                  }
                : null
            }
            onChange={(region: any) => UISceneController.setRegion(region)}
          />
        </div>
        <div className="custom-field">
          <label>Поставщик</label>
          <SelectComponent
            isDisabled={!UISceneController.createFuelingState.region}
            onInputChange={UISceneController.setContractorSearch}
            isClearable
            onMenuScrollToBottom={() =>
              UISceneController.fetchListOfContractors()
            }
            onFocus={() => UISceneController.fetchListOfContractors(1, true)}
            options={UISceneController.listOfContractorsMetadata.data.map(
              (contractor, index) => ({
                id: contractor.id,
                index,
                label: contractor.name,
                name: contractor.name,
                value: contractor.id,
              })
            )}
            isLoading={UISceneController.isContractorsLoading}
            value={
              UISceneController.createFuelingState.contractor
                ? {
                    label: UISceneController.createFuelingState.contractor.name,
                    value: UISceneController.createFuelingState.contractor.id,
                  }
                : null
            }
            onChange={(contractor: any) =>
              UISceneController.setContractor(contractor)
            }
          />
        </div>
      </div>

      <div className="row">
        <Button
          text={TranslationService.t("txt_add")}
          type={"button"}
          className={"primary"}
          size="small"
          loading={UISceneController.isFuelingCreationInProgress}
          onClick={UISceneController.createFueling}
        />
      </div>
    </FilterComponent>
  );
});

export default FuelingRequestForm;
