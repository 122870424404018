import {
  IBlacklist,
  INotificationsBlackListParams,
  INotificationsBlackListViewModel,
} from "./__types__/INotificationsBlackListViewModel";
import { Utils } from "@modules/utils";
import { IReactionDisposer, observable, reaction } from "mobx";

/**
 * View model for the list of notifications.
 */
export class NotificationsBlackListViewModel<T>
  implements INotificationsBlackListViewModel<T>
{
  public reactions: { [key: string]: IReactionDisposer } = {};

  private _blacklist: IBlacklist;

  public constructor(private params: INotificationsBlackListParams) {
    const value = localStorage.getItem(params.storageKey);

    try {
      if (value) {
        this._blacklist = observable.object(
          JSON.parse(value) || ({} as IBlacklist)
        );
      } else {
        this._blacklist = observable.object({});
      }
    } catch (error) {
      this._blacklist = observable.object({});
    }

    this.reactions.sync = reaction(
      () => Object.values(this._blacklist).length,
      (current, prev) => {
        Utils.compare(current, prev, (): void => {
          this.syncChanges();
        });
      }
    );
  }

  // this data property will return an array of objects of type T
  public get list(): IBlacklist {
    return this._blacklist;
  }

  public add(item: string): void {
    this._blacklist[item] = item;
  }

  public remove(item: string): void {
    delete this._blacklist[item];
  }

  /**
   * Clean up method to be called before destroying the view model.
   */
  public beforeDestroy(): void {
    Object.values(this.reactions).forEach((r) => r());
  }

  private syncChanges = (): void => {
    localStorage.setItem(
      this.params.storageKey,
      // @ts-ignore
      JSON.stringify(this._blacklist)
    );
  };
}
