import {
  IUISceneController,
  TNotificationsSceneParams,
  TNotificationsSceneResult,
} from "./types";
import { toast } from "react-toastify";
import { ScenesInstance } from "@scenes/PrototypeScene";
import { Utils } from "@modules/utils";
import { getMessageFromError } from "@modules/utils/getMessageFromError";
import { TSceneDisplayOptions } from "@viewModels/scenes/__types__/SceneDisplay";
import { INotificationListItemEntity } from "@entities/NotificationListItemEntity/NotificationListItemEntity";

export class UISceneController<
    T extends TNotificationsSceneResult,
    P extends TNotificationsSceneParams
  >
  extends ScenesInstance<T, P>
  implements IUISceneController<T, P>
{
  public static _name: string = "NotificationsScene";

  // private NotificationsListViewModel: INotificationsListViewModel;

  public constructor(
    options: TSceneDisplayOptions,
    private params: TNotificationsSceneParams
  ) {
    super(options);

    // this.NotificationsListViewModel = new NotificationsListViewModel(
    //   params.model,
    //   {
    //     per_page: 100,
    //   }
    // );
  }

  public get _name(): string {
    return UISceneController._name;
  }

  public get listOfNotification(): INotificationListItemEntity[] {
    return this.params.model.notifications.list;
  }

  // public get isNotificationsLoading(): boolean {
  //   return this.NotificationsListViewModel.statuses.isInProgress;
  // }

  public removeNotification = (
    notification: INotificationListItemEntity
  ): void => {
    try {
      this.params.model.notifications.hideNotification(notification.hash);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public closeScene = (): void => {
    try {
      this.displays.handleRejects();
    } catch (error) {
      toast.error(Utils.getMessageFromError(error));
    }
  };

  public beforeDestroy = (): void => {};
}
