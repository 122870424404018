import {
  TDtoFuelingTurnoverContractListItemEntry,
  TDtoFuelingTurnoverContractListSubItemEntry,
} from "./__types__/TDtoFuelingTurnoverContractListItemEntry.types";
import { TParseDto } from "../__types__/dto";
import { Utils } from "../..";

export const DtoFuelingTurnoverContractListSubItemParser: TParseDto<
  TDtoFuelingTurnoverContractListSubItemEntry
> = (data: any): TDtoFuelingTurnoverContractListSubItemEntry => ({
  dt: Utils.isString(data.dt) ? data.dt : "",
  number: Utils.isString(data.number) ? data.number : "",
  type: Utils.isString(data.type) ? data.type : "",
  volume: Utils.isString(data.volume) ? data.volume : "",
  weight: Utils.isString(data.weight) ? data.weight : "",
});

export const DtoFuelingTurnoverContractListItemParser: TParseDto<
  TDtoFuelingTurnoverContractListItemEntry
> = (data: any): TDtoFuelingTurnoverContractListItemEntry => ({
  list: Object.values(data).map(DtoFuelingTurnoverContractListSubItemParser),
});
