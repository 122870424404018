/* eslint-disable @typescript-eslint/no-empty-interface */
import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";

export const UserEntity = types
  .model({
    email: types.optional(types.string, ""),
    // id: types.identifierNumber,
    name: types.optional(types.string, ""),
    roles: types.optional(types.array(types.string), []),
    username: types.optional(types.string, ""),
  })
  .named("UserEntity");

export interface IUserEntity extends Instance<typeof UserEntity> {}
export interface IUserEntityIn extends SnapshotIn<IUserEntity> {}
export interface IUserEntityOut extends SnapshotOut<IUserEntity> {}
