import { IPilotsRepository } from "./__types__/repository";
import { PilotsRepositoryResponseDto } from "./dtoResponse";
import { TDtoPilotListItemEntry } from "@modules/utils/dto/parsers/__types__/TDtoPilotListItemEntry.types";
import { IRequestClient } from "@modules/request/types/instance";
import { TDtoPaginationEntry } from "@modules/utils/dto/parsers/__types__/TDtoPaginationEntry";

export class PilotsRepository implements IPilotsRepository {
  public readonly abortControllers = {
    getPilotsList: new AbortController(),
  };

  public constructor(private requestClient: IRequestClient) {}

  public getPilotsList = async (params: {
    page: number;
    per_page: number;
    search?: string | undefined;
    order?: string | undefined;
    asc?: boolean;
    contractor?: number | undefined;
    date_from?: number;
    date_to?: number;
  }): Promise<TDtoPaginationEntry<TDtoPilotListItemEntry>> => {
    if (this.abortControllers.getPilotsList) {
      this.abortControllers.getPilotsList.abort();
    }
    this.abortControllers.getPilotsList = new AbortController();

    const response: any = await this.requestClient.get(`/pilot`, {
      params: {
        asc: params.asc,
        contractor: params.contractor,
        // date_from: params.date_from,
        // date_to: params.date_to,
        limit: params.per_page,
        order: params.order,
        page: params.page,
        search: params.search,
      },
      signal: this.abortControllers.getPilotsList.signal,
    });

    return PilotsRepositoryResponseDto.getListOfPilots(response);
  };

  public beforeDestroy = (): void => {
    Object.values(this.abortControllers).forEach((abortController) =>
      abortController.abort()
    );
  };
}
