import Joi from "joi";

export const DtoRegionListItemScheme = Joi.object({
  // contractors: Joi.array().items({
  //   id: Joi.number().required(),
  //   name: Joi.string().required().allow(""),
  //   regions: Joi.array().items(Joi.string().allow("")),
  // }),
  contractors: Joi.array().items(Joi.number()),
  id: Joi.number().required(),
  name: Joi.string().required().allow(""),
});
