import { IconLoading } from "../icons";
import "./styles.scss";

type Props = {
  text?: string | JSX.Element;
  type: React.ButtonHTMLAttributes<HTMLButtonElement>["type"];
  className?: string;
  disabled?: boolean;
  iconLeft?: JSX.Element;
  size?: string;
  onClick?: any;
  loading?: boolean;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Button = ({
  text,
  type,
  disabled,
  className,
  iconLeft,
  size,
  onClick,
  loading,
}: // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
Props) => (
  <button
    className={`btn ${className || ""} ${
      loading ? "btn--loading" : ""
    } ${size}`}
    disabled={disabled || loading}
    type={type}
    onClick={onClick}
  >
    {loading ? (
      <IconLoading />
    ) : (
      <>
        {iconLeft}
        <span>{text}</span>
      </>
    )}
  </button>
);

export default Button;
