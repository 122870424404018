/* eslint-disable sort-keys/sort-keys-fix */
import { IScreenControllerFueling } from "@screens/FuelingScreen/connector/__types__/UIViewController";
import { TranslationService } from "@services/translate";
import TableOutput from "@shared/controls/TableOutput/TableOutput";
import { useControllers } from "@view/hooks/useControllers";
import { observer } from "mobx-react";
import moment from "moment";
import React from "react";

const headerList = {
  createdAt: ["Дата", true],
  region: [TranslationService.t("txt_col_region")],
  number: ["№ топ. требования"],
  vehicle: ["№ борта"],
  weight: ["Вес топлива, кг", true],
  volume: ["Объем топлива, л", true],
  contractor: ["Поставщик"],
  comment: [null],
};

export const FuelingTable = observer((): JSX.Element => {
  const { UIViewController } = useControllers<{
    UIViewController: IScreenControllerFueling;
  }>();

  return (
    <TableOutput
      sortBy={UIViewController.listOfFuelingFilters.order}
      sortDir={UIViewController.listOfFuelingFilters.direction}
      onChangeOrder={UIViewController.setFieldOrderBy}
      headerObj={headerList}
      isLoading={UIViewController.isRequestInProgress}
      resetFilters={() => UIViewController.resetFilters()}
      list={UIViewController.listOfFuelingMetadata.data.map((fueling) => ({
        createdAt: moment(fueling.createdAt).format("DD.MM.YY"),
        region: fueling.region?.name,
        number: fueling.number,
        vehicle: fueling.vehicle?.number,
        weight: fueling.weight,
        volume: fueling.volume,
        document: [
          fueling.contract.number,
          moment(fueling.contract.conclusionDate).format("DD.MM.YY"),
        ],
        contractor: fueling.contractor.name,
        comment: () => UIViewController.openFuelingCommentScene(fueling.id),
      }))}
    />
  );
});
