import { IStatuses } from "./types/status";
import { RequestStatus } from "@constants/repositories";
import { makeObservable, observable } from "mobx";

export class Statuses implements IStatuses {
  private statuses: Map<string, RequestStatus> = new Map();

  public constructor(keys: string[]) {
    makeObservable<Statuses, "statuses">(this, {
      statuses: observable,
    });

    // Set initial status
    keys.forEach((key) => this.statuses.set(key, RequestStatus.Initial));
  }

  /** Indicate if at least one status in pending status */
  public get isInProgress(): boolean {
    // @ts-ignore
    for (const status of this.statuses.values()) {
      if (status === RequestStatus.Pending) return true;
    }
    return false;
  }

  public getStatus(name: string): RequestStatus {
    this.checkStatusIsExist(name);
    return this.statuses.get(name) as RequestStatus;
  }

  public setStatus(name: string, status: RequestStatus): void {
    this.checkStatusIsExist(name);
    this.statuses.set(name, status);
  }

  public resetStatus(name: string): void {
    this.checkStatusIsExist(name);
    this.statuses.set(name, RequestStatus.Initial);
  }

  public resetStatuses(): void {
    // @ts-ignore
    for (const status of this.statuses.keys()) {
      this.statuses.set(status, RequestStatus.Initial);
    }
  }

  /**
   * Check if the status exists
   *
   * If the status does not exist throw an error
   */
  private checkStatusIsExist(name: string): void {
    if (!this.statuses.get(name)) {
      throw new TypeError(
        `Cannot assign status to "${name}" because this name is not declared in the Statuses constructor`
      );
    }
  }
}
