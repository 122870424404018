import { IContractorsRepositoryResponseDto } from "./__types__/dtoResponse";
import { TDtoContractorListItemEntry } from "@modules/utils/dto/parsers/__types__/TDtoContractorListItemEntry.types";
import { Utils } from "@modules/index";
import { TDtoPaginationEntry } from "@modules/utils/dto/parsers/__types__/TDtoPaginationEntry";

export class ContractorsRepositoryResponseDto
  implements IContractorsRepositoryResponseDto
{
  public static getListOfContractors = (
    data: unknown
  ): TDtoPaginationEntry<TDtoContractorListItemEntry> =>
    Utils.dto.transform(
      Utils.dto.Schemes.Pagination(Utils.dto.Schemes.ContractorListItem),
      data,
      () =>
        Utils.dto.Parsers.Pagination(data, Utils.dto.Parsers.ContractorListItem)
    ) as TDtoPaginationEntry<TDtoContractorListItemEntry>;
}
