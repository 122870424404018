import React, { useRef } from "react";
import moment from "moment";
import { TranslationService } from "@services/translate";
import { IFuelingCommentListItemEntity } from "@entities/FuelingCommentListItemEntity/FuelingCommentListItemEntity";
import { useWindowWidth } from "@view/hooks/useWindowWidth";

type Props = {
  notification: IFuelingCommentListItemEntity;
  onAcceptNotification?: (notification: IFuelingCommentListItemEntity) => void;
};

const NotificationItem = ({ notification }: Props): JSX.Element => {
  const date = useRef<string>(
    moment(notification.fuelingRequest?.updatedAt).isSame(moment(), "date")
      ? TranslationService.t("txt_now")
      : moment(notification.fuelingRequest?.updatedAt).format("DD.MM.YY")
  ).current;

  const windowWidth = useWindowWidth();

  return (
    <div
      className="notification"
      style={{
        alignItems: "flex-start",
        background: "#FCFCFD",
        border: "1px #DBDCE1 solid",
        borderRadius: 6,
        display: "inline-flex",
        flexDirection: "column",
        gap: 14,
        justifyContent: "flex-start",
        minHeight: 120,
        padding: 20,
        width: windowWidth > 768 ? 316 : "100%",
      }}
    >
      <div
        className="Frame513166"
        style={{
          alignItems: "flex-start",
          alignSelf: "stretch",
          display: "inline-flex",
          justifyContent: "space-between",
        }}
      >
        <div
          className="Timestamp"
          style={{
            color: "#303B57",
            flex: "1 1 0",
            fontFamily: "Inter",
            fontSize: 12,
            fontWeight: "400",
            height: 16,
            // lineHeight: 16,
            wordWrap: "break-word",
          }}
        >
          {date}
        </div>
        {/* <div
          className="Icons"
          style={{
            alignItems: "center",
            background: "#F0F2F8",
            borderRadius: 100,
            display: "flex",
            gap: 10,
            height: 20,
            justifyContent: "center",
            padding: 6,
            width: 20,
          }}
        >
          <div
            className="Icons"
            style={{ height: 10, position: "relative", width: 10 }}
          >
            <div
              className="Vector"
              style={{
                background: "#303B57",
                // height: 6.25,
                // left: 1.02,
                // position: "absolute",
                // top: 1.87,
                // width: 7.92,
              }}
            ></div>
          </div>
        </div> */}
      </div>
      <div
        className="Frame513165"
        style={{
          alignItems: "flex-start",
          alignSelf: "stretch",
          display: "flex",
          flexDirection: "column",
          gap: 6,
          // height: 46,
          justifyContent: "flex-start",
        }}
      >
        <div
          style={{
            color: "#303B57",
            fontFamily: "Inter",
            fontSize: 16,
            fontWeight: "700",
            // lineHeight: 24,
            wordWrap: "break-word",
          }}
          className={`status${notification.status}`}
        >
          Заявка №2 {notification.id}
        </div>
        <div
          style={{
            color: "#303B57",
            fontFamily: "Inter",
            fontSize: 12,
            fontWeight: "400",
            // lineHeight: 16,
            wordWrap: "break-word",
          }}
        >
          {notification.comment}
        </div>
      </div>
    </div>
  );
};

export default NotificationItem;
