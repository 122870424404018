/* eslint-disable @typescript-eslint/no-empty-interface */
import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";

// const RegionListRegionsItemEntity = types.string;

// const RegionListContractorItemEntity = types.model({
//   id: types.number,
//   name: types.optional(types.string, ""),
//   regions: types.optional(types.array(RegionListRegionsItemEntity), []),
// });

export const RegionListItemEntity = types
  .model({
    contractors: types.optional(types.array(types.number), []),

    id: types.identifierNumber,
    name: types.optional(types.string, ""),
  })
  .named("RegionListItemEntity");

export interface IRegionListItemEntity
  extends Instance<typeof RegionListItemEntity> {}
export interface IRegionListItemEntityIn
  extends SnapshotIn<IRegionListItemEntity> {}
export interface IRegionListItemEntityOut
  extends SnapshotOut<IRegionListItemEntity> {}
