import { IScreenControllerDocScore } from "@screens/DocScoresScreen/connector/__types__/UIViewController";
import Pagination from "@shared/controls/Pagination/Pagination";
import { useControllers } from "@view/hooks/useControllers";
import { observer } from "mobx-react";
import React from "react";

export const ScoresPagination = observer(() => {
  const { UIViewController } = useControllers<{
    UIViewController: IScreenControllerDocScore;
  }>();

  return (
    <Pagination
      count={UIViewController.listOfScoresMetadata.data.length}
      total={UIViewController.listOfScoresMetadata.meta.total}
      per_page={UIViewController.listOfScoresMetadata.meta.per_page}
      loading={UIViewController.isRequestInProgress}
      onLoad={UIViewController.fetchListOfScores}
    />
  );
});
