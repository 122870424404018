import {
  IUISceneController,
  TCreateScoreSceneParams,
  TCreateScoreSceneResult,
} from "./types";
import { toast } from "react-toastify";
import { ScenesInstance } from "@scenes/PrototypeScene";
import { Utils } from "@modules/utils";
import { getMessageFromError } from "@modules/utils/getMessageFromError";
import { IRegionListItemEntity } from "@entities/RegionListItemEntity";
import moment, { Moment } from "moment";
import { IContractListItemEntity } from "@entities/ContractListItemEntity";
import {
  IScoreCreateViewModel,
  IScoreCreateState,
} from "@viewModels/ScoreCreateViewModel/__types__/IScoresListViewModel";
import { ScoreCreateViewModel } from "@viewModels/ScoreCreateViewModel/ScoreCreateViewModel";
import { TSceneDisplayOptions } from "@viewModels/scenes/__types__/SceneDisplay";
import { RegionsListViewModel } from "@viewModels/RegionsListViewModel/RegionsListViewModel";
import { IRegionsListViewModel } from "@viewModels/RegionsListViewModel/__types__/IRegionsListViewModel";
import { IPagination } from "@viewModels/pagination/types";
import { ContractsListViewModel } from "@viewModels/ContractsListViewModel/ContractsListViewModel";
import { IContractsListViewModel } from "@viewModels/ContractsListViewModel/__types__/IContractsListViewModel";
import { DateFilerType } from "@viewModels/DatePeriodFilterViewModel/__types__/IDatePeriodFilterViewModel.types";

export class UISceneController<
    T extends TCreateScoreSceneResult,
    P extends TCreateScoreSceneParams
  >
  extends ScenesInstance<T, P>
  implements IUISceneController<T, P>
{
  public static _name: string = "CreateScoreScene";

  private ScoreCreateViewModel: IScoreCreateViewModel;

  private RegionsListViewModel: IRegionsListViewModel;

  private ContractsListViewModel: IContractsListViewModel;

  public constructor(
    options: TSceneDisplayOptions,
    private params: TCreateScoreSceneParams
  ) {
    super(options);

    this.ContractsListViewModel = new ContractsListViewModel(params.model, {
      conclusionDate: {
        end:
          moment()
            // .set("year", moment().year() - 5)
            .unix() * 1000,

        start:
          moment()
            .set("year", moment().year() - 5)
            .unix() * 1000,

        type: DateFilerType.Custom,
      },
      search: "",
      termDate: {
        end:
          moment()
            .set("year", moment().year() + 5)
            .unix() * 1000,
        start:
          moment()
            // .set("year", moment().year() - 5)
            .unix() * 1000,

        type: DateFilerType.Custom,
      },
    });

    // ипа таких ?termDate[start]=<now()>&termDate[end]=<now()+10 years>&conclusionDate[start]=<now-10 yesrs>&conclusionDate[end]=<now>

    this.RegionsListViewModel = new RegionsListViewModel(params.model);

    this.ScoreCreateViewModel = new ScoreCreateViewModel(params.model);
  }

  public get _name(): string {
    return UISceneController._name;
  }

  public get createScoreState(): IScoreCreateState {
    return this.ScoreCreateViewModel.state;
  }

  public get listOfContractsMetadata(): IPagination<IContractListItemEntity> {
    return this.ContractsListViewModel.metadata;
  }

  public get listOfRegionsMetadata(): IPagination<IRegionListItemEntity> {
    return this.RegionsListViewModel.metadata;
  }

  public get isContractsLoading(): boolean {
    return this.ContractsListViewModel.statuses.isInProgress;
  }

  public get isRegionsLoading(): boolean {
    return this.RegionsListViewModel.statuses.isInProgress;
  }

  public get isScoreCreationInProgress(): boolean {
    return this.ScoreCreateViewModel.statuses.isInProgress;
  }

  public fetchListOfRegions = (
    page: number = this.RegionsListViewModel.meta.current_page + 1,
    refreshing: boolean = false
  ): void => {
    try {
      this.RegionsListViewModel.fetchItemsBatch(page, refreshing);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public fetchListOfContracts = async (): Promise<void> => {
    try {
      await this.ContractsListViewModel.fetchItemsBatch(
        this.ContractsListViewModel.meta.current_page + 1,
        false
      );
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setContract = (
    contract: { id: number; number: string } | null
  ): void => {
    try {
      const previousId = this.ScoreCreateViewModel.state.contract?.id;

      this.ScoreCreateViewModel.setContract(contract);

      if (contract?.id !== previousId) {
        // TODO: clear region and show empty field and after that need display error message that region is not match to contractor
        this.ScoreCreateViewModel.setRegions(null);

        this.RegionsListViewModel.setContractFilter(contract);

        if (contract?.id) {
          this.fetchListOfRegions(1, true);
        }
      }
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setScoreNumber = (scoreNumber: string): void => {
    try {
      this.ScoreCreateViewModel.setScoreNumber(scoreNumber);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setRegions = (regions: { id: number; name: string }[]): void => {
    try {
      this.ScoreCreateViewModel.setRegions(regions);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setDeliveryDate = (date: Moment): void => {
    try {
      this.ScoreCreateViewModel.setDeliveryDate(date);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setPrice = (price: number): void => {
    try {
      this.ScoreCreateViewModel.setPrice(price);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setAmount = (amount: number): void => {
    try {
      this.ScoreCreateViewModel.setAmount(amount);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setWeight = (weight: number): void => {
    try {
      this.ScoreCreateViewModel.setWeight(weight);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setContractSearch = (contract: string): void => {
    try {
      const previousSearchState = this.ContractsListViewModel.filters.search;

      this.ContractsListViewModel.setSearch(contract);

      if (contract !== previousSearchState) {
        this.ContractsListViewModel.fetchItemsBatchDebounce(1, true);
      }
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setRegionSearch = (region: string): void => {
    try {
      const previousSearchState = this.RegionsListViewModel.filters.search;

      this.RegionsListViewModel.setSearch(region);

      if (region !== previousSearchState) {
        this.RegionsListViewModel.fetchItemsBatchDebounce(1, true);
      }
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public createScore = async (): Promise<void> => {
    try {
      // Create contract
      await this.ScoreCreateViewModel.createScore(
        this.ScoreCreateViewModel.state
      );

      // @ts-ignore
      this.displays.handleResolve({});
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public closeScene = (): void => {
    try {
      this.displays.handleRejects();
    } catch (error) {
      toast.error(Utils.getMessageFromError(error));
    }
  };

  public clearScore = (): void => {
    try {
      this.ScoreCreateViewModel.clearState();
      this.RegionsListViewModel.clearList();
    } catch (error) {
      toast.error(Utils.getMessageFromError(error));
    }
  };

  public beforeDestroy = (): void => {
    this.clearScore();
  };
}
