import { IScreenControllerAuth } from "@screens/AuthorizationScreen/connector/__types__/UIViewController";
import { TranslationService } from "@services/translate";
import { useControllers } from "@view/hooks/useControllers";
import { useCallback } from "react";
import Button from "@shared/controls/Button/Button";
import Input from "@shared/controls/Input/Input";
import { observer } from "mobx-react";
import { Helmet } from "react-helmet";

export const Authorization = observer((): JSX.Element => {
  const { UIViewController } = useControllers<{
    UIViewController: IScreenControllerAuth;
  }>();

  const onSubmitForm = useCallback((event: any) => {
    event.preventDefault();
    UIViewController.submitLoginForm();
  }, []);

  return (
    <>
      <Helmet>
        <title>{TranslationService.t("txt_title_login")}</title>
      </Helmet>
      <form onSubmit={onSubmitForm}>
        <div className="container col">
          <h2>{TranslationService.t("txt_title_login")}</h2>
          <div className="colFormField col">
            <div
              className={`customField ${
                UIViewController.errorEmail ? "error" : ""
              }`}
            >
              <label>{TranslationService.t("txt_email_field")}</label>
              <Input
                type="email"
                value={UIViewController.email}
                placeholder={TranslationService.t("txt_input_email")}
                onChange={UIViewController.setEmail}
              />
              {UIViewController.errorEmail ? (
                <p className="error-msg">{UIViewController.errorEmail}</p>
              ) : null}
            </div>
            <div className="customField">
              <label>{TranslationService.t("txt_password_field")}</label>
              <Input
                type={"password"}
                placeholder={TranslationService.t("txt_input_password")}
                value={UIViewController.password}
                onChange={UIViewController.setPassword}
              />
              <p
                className="forget"
                onClick={() => UIViewController.setPage("forget")}
              >
                {TranslationService.t("txt_forget_password")}?
              </p>
            </div>
            <div className="remember">
              <input type="checkbox" name="remember" id="remember" />
              <label htmlFor="remember">
                {TranslationService.t("txt_remember_me")}
              </label>
            </div>
          </div>
          <div className="col">
            <Button
              loading={UIViewController.isRequestInProgress}
              text={TranslationService.t("txt_login_btn")}
              className={"primary"}
              type={"submit"}
              disabled={!UIViewController.email && !UIViewController.password}
            />
          </div>
        </div>
      </form>
    </>
  );
});
