type Props = {
  onClick?: () => void;
};
const IconDate = ({ onClick }: Props): JSX.Element => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
  >
    <path
      d="M14.6663 5.70801C14.6657 5.11764 14.4422 4.54654 14.0355 4.09583C13.6288 3.64512 13.065 3.3439 12.4441 3.24551V2.16634C12.4441 1.94533 12.3505 1.73337 12.1838 1.57709C12.0171 1.42081 11.791 1.33301 11.5552 1.33301C11.3195 1.33301 11.0934 1.42081 10.9267 1.57709C10.76 1.73337 10.6663 1.94533 10.6663 2.16634V3.20801H8.88856V2.16634C8.88856 1.94533 8.79491 1.73337 8.62821 1.57709C8.46152 1.42081 8.23542 1.33301 7.99967 1.33301C7.76393 1.33301 7.53783 1.42081 7.37114 1.57709C7.20444 1.73337 7.11079 1.94533 7.11079 2.16634V3.20801H5.33301V2.16634C5.33301 1.94533 5.23936 1.73337 5.07266 1.57709C4.90596 1.42081 4.67987 1.33301 4.44412 1.33301C4.20837 1.33301 3.98228 1.42081 3.81558 1.57709C3.64888 1.73337 3.55523 1.94533 3.55523 2.16634V3.24551C2.93431 3.3439 2.37057 3.64512 1.96385 4.09583C1.55712 4.54654 1.33365 5.11764 1.33301 5.70801V12.1663C1.33301 12.8294 1.61396 13.4653 2.11406 13.9341C2.61415 14.403 3.29243 14.6663 3.99967 14.6663H11.9997C12.7069 14.6663 13.3852 14.403 13.8853 13.9341C14.3854 13.4653 14.6663 12.8294 14.6663 12.1663V5.70801ZM12.8886 12.1663C12.8886 12.3874 12.7949 12.5993 12.6282 12.7556C12.4615 12.9119 12.2354 12.9997 11.9997 12.9997H3.99967C3.76393 12.9997 3.53783 12.9119 3.37114 12.7556C3.20444 12.5993 3.11079 12.3874 3.11079 12.1663V5.70801C3.11079 5.48699 3.20444 5.27503 3.37114 5.11875C3.53783 4.96247 3.76393 4.87467 3.99967 4.87467H11.9997C12.2354 4.87467 12.4615 4.96247 12.6282 5.11875C12.7949 5.27503 12.8886 5.48699 12.8886 5.70801V12.1663Z"
      fill="#303B57"
    />
    <path
      d="M10.2219 6.54134H5.77745C5.5417 6.54134 5.31561 6.62914 5.14891 6.78542C4.98221 6.9417 4.88856 7.15366 4.88856 7.37467C4.88856 7.59569 4.98221 7.80765 5.14891 7.96393C5.31561 8.12021 5.5417 8.20801 5.77745 8.20801H10.2219C10.4576 8.20801 10.6837 8.12021 10.8504 7.96393C11.0171 7.80765 11.1108 7.59569 11.1108 7.37467C11.1108 7.15366 11.0171 6.9417 10.8504 6.78542C10.6837 6.62914 10.4576 6.54134 10.2219 6.54134Z"
      fill="#303B57"
    />
  </svg>
);

export default IconDate;
