import { IRouterController } from "./connector/__types__/UIViewController";
import { RouterController } from "./connector/UIViewController";
import { Screens } from "@constants/screens";
import withControllers from "@view/providers/__proto__/withController";
import { Route, Routes } from "react-router-dom";
import AuthorizationScreen from "@screens/AuthorizationScreen/AuthorizationScreen";
import { useControllers } from "@view/hooks/useControllers";
import HomeScreen from "@screens/HomeScreen/HomeScreen";
import { Redirect } from "@shared/router/Redirect/Redirect";
import Sidebar from "@shared/containers/Sidebar/Sidebar";
import Header from "@shared/containers/Header/Header";
import DocContracts from "@screens/DocContractsScreen/DocContractsScreen";
import DocScores from "@screens/DocScoresScreen/DocScoresScreen";
import { observer } from "mobx-react";
import FuelingCommentsScreen from "@screens/FuelingCommentsScreen/FuelingCommentsScreen";
import { UserRoles } from "@constants/roles";
import HomePilotScreen from "@screens/FuelingScreen/FuelingScreen";
import FuelingCommentScreen from "@screens/FuelingCommentScreen/FuelingCommentScreen";
import ProfileScreen from "@screens/ProfileScreen/ProfileScreen";
import FuelingCommentEditScreen from "@screens/FuelingCommentEditScreen/FuelingCommentEditScreen";
import ReportByRegionsScreen from "@screens/ReportByRegionsScreen/ReportByRegionsScreen";
import ReportByVSScreen from "@screens/ReportByVSScreen/ReportByVSScreen";
import ReportByKVSScreen from "@screens/ReportByKVSScreen/ReportByKVSScreen";
import ReportByContractsScreen from "@screens/ReportByContractsScreen/ReportByContractsScreen";
import ErrorScreen from "@screens/ErrorScreen/ErrorScreen";
import NewPasswordScreen from "@screens/NewPasswordScreen/NewPasswordScreen";
import FuelingCreateScreen from "@screens/FuelingCreateScreen/FuelingCreateScreen";
import RecalculationsScreen from "@screens/RecalculationsScreen/RecalculationsScreen";

const RootRouter = observer(() => {
  const { UIViewController } = useControllers<{
    UIViewController: IRouterController;
  }>();

  return (
    <div className="main-wrapper">
      {UIViewController.isUserLoggedIn ? <Sidebar /> : null}

      {UIViewController.isUserLoggedIn ? (
        <div
          className="main-pane-right"
          style={{
            width: UIViewController.isOpenedSideMenu
              ? "calc(100% - 270px)"
              : "calc(100% - 105px)",
          }}
        >
          <Header />
          {UIViewController.userinfo?.roles.includes(UserRoles.EmployeeGSM) ? (
            <Routes>
              <Route path={Screens.Root.path()} element={<HomeScreen />} />
              <Route
                path={Screens.DocContracts.path()}
                element={<DocContracts />}
              />
              <Route path={Screens.DocScores.path()} element={<DocScores />} />
              <Route path={Screens.Recalculations.path()} element={<RecalculationsScreen />} />
              <Route
                path={Screens.ReportRegion.path()}
                element={<ReportByRegionsScreen />}
              />
              <Route
                path={Screens.ReportContract.path()}
                element={<ReportByContractsScreen />}
              />
              <Route
                path={Screens.ReportKvs.path()}
                element={<ReportByKVSScreen />}
              />
              <Route
                path={Screens.ReportVs.path()}
                element={<ReportByVSScreen />}
              />
              <Route
                path={Screens.Application.path()}
                element={<FuelingCommentsScreen />}
              />
              <Route
                path={Screens.ProfileScreen.path()}
                element={<ProfileScreen />}
              />
              <Route
                path={Screens.FuelingComment.path()}
                element={<FuelingCommentScreen />}
              />
              <Route
                path={Screens.FuelingCommentEdit.path()}
                element={<FuelingCommentEditScreen />}
              />

              <Route path={Screens.All.path()} element={<ErrorScreen />} />
            </Routes>
          ) : (
            <Routes>
              <Route path={Screens.Root.path()} element={<HomePilotScreen />} />
              <Route
                path={Screens.Application.path()}
                element={<FuelingCommentsScreen />}
              />
              <Route
                path={Screens.FuelingCreate.path()}
                element={<FuelingCreateScreen />}
              />
              <Route
                path={Screens.ProfileScreen.path()}
                element={<ProfileScreen />}
              />

              <Route path={Screens.All.path()} element={<ErrorScreen />} />
            </Routes>
          )}
        </div>
      ) : (
        <Routes>
          <Route path={Screens.Root.path()} element={<AuthorizationScreen />} />
          <Route
            path={Screens.PasswordReset.path()}
            element={<NewPasswordScreen />}
          />

          {/* Redirect to login when user is not logged in */}
          <Route
            path={Screens.All.path()}
            element={
              <Redirect from={Screens.All.path()} to={Screens.Root.path()} />
            }
          ></Route>
        </Routes>
      )}
    </div>
  );
});

export default withControllers(RouterController)(RootRouter);
