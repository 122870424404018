import { ISidebarViewModel } from "./__types__/SidebarViewModel.types";
import { IRootTreeModel } from "@models/RootTreeModel";

/**
 * Represents a view model for the sidebar.
 */
export class SidebarViewModel implements ISidebarViewModel {
  /**
   * Creates an instance of SidebarViewModel.
   *
   * @param {IRootTreeModel} model - The root tree model.
   */
  public constructor(private model: IRootTreeModel) {}

  /**
   * Gets the current state of the side menu.
   *
   * @returns {boolean} - True if the side menu is opened, false otherwise.
   */
  public get isOpenedSideMenu(): boolean {
    return this.model.system.isOpenedSideMenu;
  }

  public get isOpenedModalLogout(): boolean {
    return this.model.system.isOpenedModalLogout;
  }

  public toggleModalLogout(): void {
    return this.model.system.toggleModalLogout();
  }

  /**
   * Toggles the state of the side menu.
   */
  public toggleSideMenu(): void {
    return this.model.system.toggleSideMenu();
  }

  /**
   * Opens the side menu.
   */
  public openSideMenu(): void {
    return this.model.system.openSideMenu();
  }

  /**
   * Closes the side menu.
   */
  public closeSideMenu(): void {
    return this.model.system.closeSideMenu();
  }

  /**
   * Performs necessary cleanup before destroying the view model.
   */
  public beforeDestroy(): void {
    // Object.values(this.reactions).forEach((r) => r());
  }
}
