import { TDtoScoreListEntry } from "./__types__/TDtoScoreListEntry.types";
import { TParseDto } from "../__types__/dto";
import { Utils } from "../..";

export const DtoScoreListParser: TParseDto<TDtoScoreListEntry> = (
  data: any
): TDtoScoreListEntry =>
  Array.isArray(data.list)
    ? data.list.map((listElement: any) => ({
        deliveryDate: Utils.isString(listElement.deliveryDate)
          ? listElement.deliveryDate
          : "",
        id: Number(listElement.id),
        number: Utils.isString(listElement.contract.number)
          ? listElement.contract.number
          : "",
        numberScore: Utils.isString(listElement.number)
          ? listElement.number
          : "",
        price: Utils.isString(listElement.price) ? listElement.price : "",

        regions: {
          // contractors: Array.isArray(listElement.region.contractors)
          //   ? listElement.region.map((element: any) =>
          //       Utils.isString(element) ? element : ""
          //     )
          //   : [],
          id: Number(listElement.region.id),
          name: Utils.isString(listElement.region.name)
            ? listElement.region.name
            : "",
        },
        weight: Utils.isString(listElement.weight) ? listElement.weight : "",
      }))
    : [];
