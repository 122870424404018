/* eslint-disable @typescript-eslint/no-empty-interface */
import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";

const ScoreListContractorItemEntity = types.model({
  id: types.number,
  name: types.optional(types.string, ""),
  region: types.optional(types.array(types.number), []),
});

export const ScoreListItemEntity = types
  .model({
    contractor: types.optional(ScoreListContractorItemEntity, {
      id: 0,
      name: "",
    }),
    deliveryDate: types.optional(types.string, ""),
    id: types.identifierNumber,
    number: types.optional(types.string, ""),
    numberScore: types.optional(types.string, ""),
    price: types.optional(types.string, ""),
    regions: types.optional(
      types.array(
        types.model({
          id: types.number,
          name: types.string,
        })
      ),
      []
    ),
    weight: types.optional(types.string, ""),
  })
  .named("ScoreListItemEntity");

export interface IScoreListItemEntity
  extends Instance<typeof ScoreListItemEntity> {}
export interface IScoreListItemEntityIn
  extends SnapshotIn<IScoreListItemEntity> {}
export interface IScoreListItemEntityOut
  extends SnapshotOut<IScoreListItemEntity> {}
