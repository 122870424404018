import { IScreenControllerDocScore } from "@screens/DocScoresScreen/connector/__types__/UIViewController";
import { Sorting } from "@shared/controls/Sorting/Sorting";
import { useControllers } from "@view/hooks/useControllers";
import { observer } from "mobx-react";

export const SortingMobile = observer((): JSX.Element => {
  const { UIViewController } = useControllers<{
    UIViewController: IScreenControllerDocScore;
  }>();

  return (
    <Sorting
      list={[
        [
          "По дате поставки",
          "deliveryDate",
          "Сначала ранние",
          "Сначала поздние",
        ],
        ["По весу топлива", "weight", "Сначала с меньшим", "Сначала с большим"],
        ["По цене за тонну", "price", "Сначала с меньшей", "Сначала с большей"],
      ]}
      sortBy={UIViewController.listOfScoresFilters.order}
      sortDir={UIViewController.listOfScoresFilters.direction}
      onChangeOrder={UIViewController.setFieldOrderBy}
    />
  );
});
