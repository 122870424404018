import {
  IFuelingCommentsListViewModel,
  IFuelingCommentsListFilters,
  IFuelingCommentsParams,
  IFuelingCommentsListPrivateFilters,
  TFuelingCommentsFieldsOrder,
} from "./__types__/IFuelingCommentsListViewMode";
import { RequestStatus } from "../../../constants/repositories";
import { Pagination } from "../pagination";
import { IPagination, IPaginationMeta } from "../pagination/types";
import { Statuses } from "../statuses";

import { isCancel } from "axios";
import { IRootTreeModel } from "@models/RootTreeModel";

import _ from "lodash";
import { observable } from "mobx";
import { AxiosRequestClient, Utils } from "@modules/index";
import {
  FuelingCommentListItemEntity,
  IFuelingCommentListItemEntity,
} from "@entities/FuelingCommentListItemEntity/FuelingCommentListItemEntity";
import { FuelingsRepository } from "@repositories/fuelings";
import { IFuelingsRepository } from "@repositories/fuelings/__types__/repository";
import {
  IDatePeriodFilterViewModel,
  IDatePeriodFilterViewModelParams,
} from "@viewModels/DatePeriodFilterViewModel/__types__/IDatePeriodFilterViewModel.types";
import { DatePeriodFilterViewModel } from "@viewModels/DatePeriodFilterViewModel/DatePeriodFilterViewModel";
import { IFieldOrderFilterViewModel } from "@viewModels/FieldOrderFilterViewModel/__types__/IFieldOrderFilterViewModel";
import { FieldOrderFilterViewModel } from "@viewModels/FieldOrderFilterViewModel/FieldOrderFilterViewModel";

export class FuelingCommentsListViewModel
  implements IFuelingCommentsListViewModel
{
  // Represents the statuses of the ViewModel, used to track the request status
  public statuses: Statuses = new Statuses(["fetchItems"]);

  // Represents the local filter parameters applied to the request
  public _localFilter: IFuelingCommentsListPrivateFilters = observable({
    isNew: undefined,
    per_page: 12,
    status: undefined,
  });

  // Debounced function that fetches items in batches
  public fetchItemsBatchDebounce: _.DebouncedFuncLeading<
    (page?: number, refreshing?: boolean) => Promise<void>
  > = Utils.debounce(
    (page: number = 1, refreshing: boolean = false): Promise<void> =>
      this.fetchItemsBatch(page, refreshing),
    400
  );

  public FieldOrderFilterViewModel: IFieldOrderFilterViewModel<TFuelingCommentsFieldsOrder>;

  // Repository used to fetch fueling notifications
  private repository: IFuelingsRepository = new FuelingsRepository(
    new AxiosRequestClient()
  );

  // Pagination metadata of the items
  private _pagination: IPagination<IFuelingCommentListItemEntity> =
    new Pagination(undefined);

  /**
   * DatePeriodFilterViewModel instance for managing date period filters.
   */
  private DatePeriodFilterViewModel: IDatePeriodFilterViewModel;

  // Constructor of FuelingCommentsListViewModel class
  public constructor(
    private model: IRootTreeModel,
    params: IFuelingCommentsParams
  ) {
    if (params.per_page) {
      this._localFilter.per_page = params.per_page;
    }
    if (params.status) {
      this._localFilter.status = params.status;
    }

    if (params.isNew) {
      this._localFilter.isNew = params.isNew;
    }

    this.DatePeriodFilterViewModel = new DatePeriodFilterViewModel({
      type: params.dateType,
    });

    this.FieldOrderFilterViewModel =
      new FieldOrderFilterViewModel<TFuelingCommentsFieldsOrder>(
        "asc",
        "updatedAt"
      );
  }

  /**
   * List of filters applied to request. When some of the filters is changed list
   * will be automatically re-fetched with new parameters. Filters should not give ability to
   * be changed directly from external, so we should keep it private.
   */
  public get filters(): IFuelingCommentsListFilters {
    return {
      date_from: this.DatePeriodFilterViewModel.state.start,
      date_to: this.DatePeriodFilterViewModel.state.end,
      direction: this.FieldOrderFilterViewModel.state.direction,
      isNew: this._localFilter.isNew,
      order: this.FieldOrderFilterViewModel.state.field,
      per_page: this._localFilter.per_page,
      status: this._localFilter.status,
    };
  }

  // Getters for the pagination metadata and the list of items
  public get meta(): IPaginationMeta {
    return this._pagination.meta;
  }

  public get metadata(): IPagination<IFuelingCommentListItemEntity> {
    return this._pagination;
  }

  public get list(): IFuelingCommentListItemEntity[] {
    return this._pagination.data;
  }

  // Setter for the status filter
  public setStatusFilter = (status: number | undefined): void => {
    this._localFilter.status = status;
  };

  public setFieldOrder = (
    field: TFuelingCommentsFieldsOrder,
    direction: "asc" | "desc"
  ): void => {
    this.FieldOrderFilterViewModel.setFieldFilter(field);
    this.FieldOrderFilterViewModel.setDirectionFilter(direction);
  };

  /**
   * Sets the date filter for the DatePeriodFilterViewModel.
   *
   * @param {IDatePeriodFilterViewModelParams} params - The parameters for the date filter.
   * @returns {Promise<void>} A promise that resolves when the date filter is set.
   */
  public setDateFilter = async (
    params: IDatePeriodFilterViewModelParams
  ): Promise<void> => {
    // Set the date filter using the provided parameters
    this.DatePeriodFilterViewModel.setFilter(params);
  };

  // Fetches items in batches
  public fetchItemsBatch = async (
    page: number = this._pagination.meta.current_page + 1,
    refreshing: boolean
  ): Promise<void> => {
    try {
      // Check if there is a pending request or if all pages are already loaded
      if (
        !refreshing &&
        this.statuses.getStatus("fetchItems") === RequestStatus.Pending
      ) {
        console.warn("Could not make request until previous not finished");
        return;
      }
      if (
        !refreshing &&
        this._pagination.meta.current_page >= this._pagination.meta.last_page
      ) {
        console.warn(
          "Could not make request because maximum count of pages already loaded"
        );
        return;
      }

      this.statuses.setStatus("fetchItems", RequestStatus.Pending);

      // Fetch items from the repository
      const response = await this.repository.getFuelingCommentsList({
        date_from: this.filters.date_from,
        date_to: this.filters.date_to,
        direction: this.filters.direction,
        isNew: this.filters.isNew,
        order: this.filters.order,
        page,
        per_page: this.filters.per_page,
        status: this.filters.status,
      });

      if (page === 1) {
        // Set the metadata for the first page of items
        this._pagination.metadata = {
          data: response.data.map(
            (item): IFuelingCommentListItemEntity =>
              FuelingCommentListItemEntity.create(item)
          ),
          meta: response.meta,
        };
      } else {
        // Append items to the existing list in the pagination metadata
        this._pagination.metadata = {
          data: _.unionBy(
            this._pagination.data,
            response.data.map(
              (item): IFuelingCommentListItemEntity =>
                FuelingCommentListItemEntity.create(item)
            ),
            (item) => item.id
          ),
          meta: response.meta,
        };
      }
      this.statuses.setStatus("fetchItems", RequestStatus.Success);
    } catch (error) {
      // Handle errors during fetching items
      if (isCancel(error)) {
        return;
      }
      this.statuses.setStatus("fetchItems", RequestStatus.Error);
      throw error;
    }
  };

  // Clears the list of items
  public clearList = (): void => {
    this._pagination.metadata = {};
  };

  // Perform cleanup tasks before the ViewModel is destroyed
  public beforeDestroy(): void {
    this.repository.beforeDestroy();
  }
}
