import { IPagination, IPaginationInfo, IPaginationMeta } from "./types";
import { IObservableValue, observable } from "mobx";

const initialMeta: IPaginationMeta = {
  current_page: 0,
  last_page: 1,
  per_page: 10,
  total: 10,
};

export class Pagination<T extends { id: number }> implements IPagination<T> {
  private pagination: IObservableValue<IPaginationInfo<T>>;

  public constructor(initial: Partial<IPaginationInfo<T>> | undefined) {
    this.pagination = observable.box(this.cast(initial?.meta, initial?.data));
  }

  public get meta(): IPaginationMeta {
    return this.pagination.get().meta;
  }

  public get data(): T[] {
    return this.pagination.get().data;
  }

  public set metadata({ meta, data }: Partial<IPaginationInfo<T>>) {
    this.pagination.set(this.cast(meta, data));
  }

  private cast = (
    _meta: IPaginationMeta | undefined,
    _data: T[] | undefined
  ): IPaginationInfo<T> => ({
    data: Array.isArray(_data) ? _data : [],
    map: new Map(_data?.map((item) => [[item.id], [item]]) || []),
    meta: {
      current_page: _meta?.current_page || initialMeta.current_page,
      last_page: _meta?.last_page || initialMeta.last_page,
      per_page: _meta?.per_page || initialMeta.per_page,
      total: _meta?.total || initialMeta.total,
    },
  });
}
