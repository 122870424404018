/* eslint-disable sort-keys/sort-keys-fix */
import { IScreenControllerReportByKVS } from "@screens/ReportByKVSScreen/connector/__types__/UIViewController";
import { TranslationService } from "@services/translate";
import TableOutput from "@shared/controls/TableOutput/TableOutput";
import { useControllers } from "@view/hooks/useControllers";
import { observer } from "mobx-react";
import moment from "moment";
import React from "react";

const headerList = {
  createdAt: ["Дата заправки", true],
  // pilot: ["Командир ВС"],
  vehicle: ["№ борта"],
  contractor: ["Поставщик"],
  region: ["Регион"],
  document: ["№ договора"],
  requirement: ["№ топ. требования"],
  weight: ["Вес топлива, кг", true],
  volume: ["Объем топлива, л", true],
};

export const ReportByKVSsTable = observer((): JSX.Element => {
  const { UIViewController } = useControllers<{
    UIViewController: IScreenControllerReportByKVS;
  }>();

  return (
    <TableOutput
      sortBy={UIViewController.listOfFuelingFilters.order}
      sortDir={UIViewController.listOfFuelingFilters.direction}
      onChangeOrder={UIViewController.setFieldOrderBy}
      headerObj={headerList}
      isLoading={UIViewController.isRequestInProgress}
      resetFilters={() => UIViewController.resetFilters()}
      list={UIViewController.listOfFuelingMetadata.data.map((fueling) => ({
        createdAt: moment(fueling.createdAt).format("DD.MM.YY"),
        region: fueling.region?.name,
        // number: fueling.number,
        vehicle: fueling.vehicle?.number,
        weight: fueling.weight,
        volume: fueling.volume,
        contractor: fueling.contractor.name,
        pilot: fueling.pilot?.name,
        document: TranslationService.t("txt_doc_from", {
          number: fueling.contract.number,
          date: moment(fueling.contract.conclusionDate).format("DD.MM.YY"),
        }),
        requirement: TranslationService.t("txt_doc_from", {
          number: fueling.number,
          date: moment(fueling.createdAt).format("DD.MM.YY"),
        }),
        report: "report",
        reportKVS: "KVS",
        // comment: () => UIViewController.openFuelingCommentScene(fueling.id),
      }))}
    />
  );
});
