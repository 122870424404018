const IconSettings = (): JSX.Element => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.8442 4.55667C18.4495 3.78183 17.8662 3.13524 17.1559 2.68522C16.4456 2.23519 15.6347 1.99845 14.8088 2.00001H9.19118C8.36638 1.99965 7.55676 2.23696 6.84766 2.68692C6.13856 3.13688 5.55624 3.78283 5.16209 4.55667L2.55613 9.66667C2.1912 10.3826 2 11.1848 2 12C2 12.8152 2.1912 13.6174 2.55613 14.3333L5.15585 19.4367C5.54968 20.2127 6.13262 20.8607 6.84298 21.3119C7.55333 21.7632 8.36469 22.0009 9.19118 22H14.8088C15.6336 22.0004 16.4432 21.763 17.1523 21.3131C17.8614 20.8631 18.4438 20.2172 18.8379 19.4433L21.4439 14.3333C21.8088 13.6174 22 12.8152 22 12C22 11.1848 21.8088 10.3826 21.4439 9.66667L18.8442 4.55667ZM19.2499 13.0667L16.6502 18.1667C16.4704 18.5205 16.2043 18.8159 15.8801 19.0213C15.556 19.2267 15.1858 19.3345 14.8088 19.3333H9.19118C8.8153 19.3333 8.4464 19.2249 8.12341 19.0196C7.80042 18.8142 7.53532 18.5196 7.35609 18.1667L4.75013 13.0667C4.58432 12.739 4.49751 12.3724 4.49751 12C4.49751 11.6276 4.58432 11.261 4.75013 10.9333L7.34984 5.83334C7.52958 5.47946 7.79566 5.18415 8.11985 4.97874C8.44404 4.77334 8.81424 4.6655 9.19118 4.66667H14.8088C15.1847 4.6667 15.5536 4.77511 15.8766 4.98045C16.1996 5.18579 16.4647 5.48045 16.6439 5.83334L19.2436 10.9333C19.4104 11.2604 19.4983 11.6267 19.4994 11.9992C19.5005 12.3716 19.4147 12.7385 19.2499 13.0667Z"
      fill="#303B57"
    />
    <path
      d="M12 7.66667C11.1976 7.66667 10.4131 7.92082 9.74595 8.39697C9.07875 8.87312 8.55873 9.5499 8.25165 10.3417C7.94457 11.1335 7.86423 12.0048 8.02077 12.8454C8.17732 13.686 8.56373 14.4581 9.13114 15.0641C9.69855 15.6702 10.4215 16.0829 11.2085 16.2501C11.9955 16.4173 12.8113 16.3315 13.5526 16.0035C14.294 15.6755 14.9276 15.1201 15.3734 14.4075C15.8192 13.6949 16.0572 12.8571 16.0572 12C16.0572 11.4309 15.9522 10.8675 15.7483 10.3417C15.5445 9.81596 15.2456 9.33826 14.8689 8.93588C14.4921 8.53349 14.0449 8.2143 13.5526 7.99653C13.0604 7.77876 12.5328 7.66667 12 7.66667ZM12 13.6667C11.6914 13.6667 11.3897 13.5689 11.1331 13.3858C10.8764 13.2026 10.6764 12.9424 10.5583 12.6378C10.4402 12.3333 10.4093 11.9982 10.4695 11.6749C10.5297 11.3516 10.6784 11.0546 10.8966 10.8215C11.1148 10.5884 11.3929 10.4297 11.6956 10.3654C11.9983 10.3011 12.312 10.3341 12.5972 10.4602C12.8823 10.5864 13.126 10.8 13.2975 11.0741C13.4689 11.3481 13.5605 11.6704 13.5605 12C13.5605 12.442 13.396 12.866 13.1034 13.1785C12.8108 13.4911 12.4139 13.6667 12 13.6667Z"
      fill="#303B57"
    />
  </svg>
);

export default IconSettings;
