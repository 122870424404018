const IconCheck = (): JSX.Element => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.71086 6.46647L2.15097 4.79742C1.89591 4.52451 1.46689 4.52451 1.21183 4.79742L1.19263 4.81796C0.956072 5.07108 0.9567 5.46751 1.19406 5.71985L3.26342 7.91982C3.39126 8.05661 3.55106 8.125 3.71086 8.125C3.87066 8.125 4.03046 8.05661 4.1583 7.91982L8.75529 3.00115C8.99129 2.74863 8.99129 2.35332 8.75529 2.10079L8.73556 2.07968C8.48019 1.80644 8.05054 1.80682 7.79564 2.08051L3.71086 6.46647Z"
      fill="#303B57"
    />
  </svg>
);

export default IconCheck;
