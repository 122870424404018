import { TranslationService } from "@services/translate";

export enum NotificationType {
  Critical = "critical",
  Comment = "comment",
  CommentNew = "comment_new",
  CommentRejected = "comment_rejected",
  CommentApproved = "comment_approved",
  Minimal = "minimal",
  Transfer = "transfer",
}

export const NotificationsTitles = {
  [NotificationType.Minimal]: (): string =>
    TranslationService.t("txt_notification_title_minimal"),
  [NotificationType.Critical]: (): string =>
    TranslationService.t("txt_notification_title_critical"),
  [NotificationType.Transfer]: (): string =>
    TranslationService.t("txt_notification_title_transfer"),
  [NotificationType.Comment]: (): string =>
    TranslationService.t("txt_notification_title_comment_new"),
  [NotificationType.CommentNew]: (): string =>
    TranslationService.t("txt_notification_title_comment_new"),
  [NotificationType.CommentApproved]: (): string =>
    TranslationService.t("txt_notification_title_comment_approved"),
  [NotificationType.CommentRejected]: (): string =>
    TranslationService.t("txt_notification_title_comment_rejected"),
};
