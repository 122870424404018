import NotificationsList from "./components/NotificationsList";
import { observer } from "mobx-react";
import React from "react";
import "./styles.scss";

const NotificationsScene = observer(() => (
  // const UISceneController =
  //   useScene<
  //     IUISceneController<TCreateScoreSceneResult, TCreateScoreSceneParams>
  //   >();

  <div className="notifications-scene">
    <NotificationsList />
  </div>
));

export default NotificationsScene;
