import Joi from "joi";

const TFuelingRequestSchema = Joi.object({
  // comments: Joi.array().items(Joi.number()).required(),
  contractor: Joi.object({
    id: Joi.number().required(),
    name: Joi.string().required(),
    regions: Joi.array().items(Joi.number()).required(),
  }).allow(null),
  createdAt: Joi.string().required(),
  fuelingType: Joi.number().required(),
  id: Joi.number().required(),
  number: Joi.string().required(),
  region: Joi.object({
    contractors: Joi.array().items(Joi.number()).required(),
    id: Joi.number().required(),
    name: Joi.string().required(),
  }).allow(null),
  updatedAt: Joi.string().required(),
  vehicle: Joi.object({
    id: Joi.number().required(),
    number: Joi.string().required(),
  }).allow(null),
  volume: Joi.string().required(),
  weight: Joi.string().required(),
});

export const DtoFuelingCommentListItemScheme = Joi.object({
  comment: Joi.string().required(),
  file: Joi.object({
    mime: Joi.string().required(),
    size: Joi.number().required(),
    url: Joi.string().required(),
  }).allow(null),
  fuelingRequest: TFuelingRequestSchema.allow(null),
  id: Joi.number().required(),
  status: Joi.number().required(),
});
