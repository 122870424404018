import { IRegionsRepositoryResponseDto } from "./__types__/dtoResponse";
import { TDtoRegionListItemEntry } from "@modules/utils/dto/parsers/__types__/TDtoRegionListItemEntry.types";
import { Utils } from "@modules/index";
import { TDtoPaginationEntry } from "@modules/utils/dto/parsers/__types__/TDtoPaginationEntry";

export class RegionsRepositoryResponseDto
  implements IRegionsRepositoryResponseDto
{
  public static getListOfRegions = (
    data: unknown
  ): TDtoPaginationEntry<TDtoRegionListItemEntry> =>
    Utils.dto.transform(
      Utils.dto.Schemes.Pagination(Utils.dto.Schemes.RegionListItem),
      data,
      () => Utils.dto.Parsers.Pagination(data, Utils.dto.Parsers.RegionListItem)
    ) as TDtoPaginationEntry<TDtoRegionListItemEntry>;
}
