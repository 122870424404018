import { IScoresRepository } from "./__types__/repository";
import { ScoresRepositoryResponseDto } from "./dtoResponse";
import { TDtoScoreListEntry } from "@modules/utils/dto/parsers/__types__/TDtoScoreListEntry.types";
import { IRequestClient } from "@modules/request/types/instance";
import { TDtoPaginationEntry } from "@modules/utils/dto/parsers/__types__/TDtoPaginationEntry";
import moment from "moment";

export class ScoresRepository implements IScoresRepository {
  public readonly abortControllers = {
    createScore: new AbortController(),
    getScoresList: new AbortController(),
  };

  public constructor(private requestClient: IRequestClient) {}

  public getScoresList = async (params: {
    number?: string; // search by score number
    contract?: number; // search by contract id
    region?: number; // search by region
    date_from?: number;
    date_to?: number;
    asc?: boolean; // sort by field direction
    page?: number; // page to load
    limit?: number; // number of item per page
    order?: string;
    direction?: "asc" | "desc";
  }): Promise<TDtoPaginationEntry<TDtoScoreListEntry>> => {
    if (this.abortControllers.getScoresList) {
      this.abortControllers.getScoresList.abort();
    }
    this.abortControllers.getScoresList = new AbortController();

    const response: any = await this.requestClient.get(`/order`, {
      params: {
        asc: params.direction === "asc",
        contract: params.contract,
        deliveryDate:
          params.date_from && params.date_to
            ? {
                end: moment(params.date_to).format("YYYY-MM-DD"),
                start: moment(params.date_from).format("YYYY-MM-DD"),
              }
            : undefined,
        // date_from: params.date_from,
        // date_to: params.date_to,
        limit: params.limit,
        number: params.number,
        order: params.order,
        page: params.page,
        regions: [params.region], // in Swagger it is array of numbers
      },
      signal: this.abortControllers.getScoresList.signal,
    });

    return ScoresRepositoryResponseDto.getListOfScore(response);
  };

  public createScore = async (data: {
    contract: number;
    deliveryDate: string;
    number: string;
    price: number;
    weight: number;
    regions: number[];
  }): Promise<{ id: number }> => {
    if (this.abortControllers.getScoresList) {
      this.abortControllers.getScoresList.abort();
    }
    this.abortControllers.getScoresList = new AbortController();

    const response: { id: number } = await this.requestClient.post(
      `/order`,
      data,
      {
        signal: this.abortControllers.getScoresList.signal,
      }
    );

    return response;
  };

  public beforeDestroy = (): void => {
    Object.values(this.abortControllers).forEach((abortController) =>
      abortController.abort()
    );
  };
}
