import { IScreenControllerFuelingCommentScreen } from "./__types__/UIViewController";
import { IReactionDisposer } from "mobx";
import { UILogicController } from "@view/providers/__proto__/withController";
import { IRootTreeModel } from "@models/RootTreeModel";
import {
  TFuelingCommentState,
  TFuelingCommentEditState,
  IFuelingCommentViewModel,
} from "@viewModels/FuelingCommentViewModel/__types__/IFuelingCommentViewModel.types";
import { FuelingCommentViewModel } from "@viewModels/FuelingCommentViewModel/FuelingCommentViewModel";
import { ContractorsListViewModel } from "@viewModels/ContractorsListViewModel/ContractorsListViewModel";
import { IContractorsListViewModel } from "@viewModels/ContractorsListViewModel/__types__/IContractorsListViewModel";
import { FuelingRequestViewModel } from "@viewModels/FuelingRequestViewModel/FuelingRequestViewModel";
import { IFuelingRequestViewModel } from "@viewModels/FuelingRequestViewModel/__types__/IFuelingRequestViewModel";
import { RegionsListViewModel } from "@viewModels/RegionsListViewModel/RegionsListViewModel";
import { IRegionsListViewModel } from "@viewModels/RegionsListViewModel/__types__/IRegionsListViewModel";
import { VehiclesListViewModel } from "@viewModels/VehiclesListViewModel/VehiclesListViewModel";
import { IVehiclesListViewModel } from "@viewModels/VehiclesListViewModel/__types__/IVehiclesListViewModel";
import { IContractorListItemEntity } from "@entities/ContractorListItemEntity";
import { IRegionListItemEntity } from "@entities/RegionListItemEntity";
import { IVehicleListItemEntity } from "@entities/VehicleListItemEntity";
import { getMessageFromError } from "@modules/utils/getMessageFromError";
import { IPagination } from "@viewModels/pagination/types";
import { toast } from "react-toastify";
import { TranslationService } from "@services/translate";
import { RequestStatus } from "@constants/repositories";

class ScreenControllerFuelingCommentScreen
  extends UILogicController
  implements IScreenControllerFuelingCommentScreen
{
  public disposers: { [key: string]: IReactionDisposer } = {};

  private FuelingCommentViewModel: IFuelingCommentViewModel;

  private VehiclesListViewModel: IVehiclesListViewModel;

  private FuelingRequestViewModel: IFuelingRequestViewModel;

  private RegionsListViewModel: IRegionsListViewModel;

  private ContractorsListViewModel: IContractorsListViewModel;

  public constructor(model: IRootTreeModel) {
    super(model);

    this.FuelingCommentViewModel = new FuelingCommentViewModel(model);

    this.VehiclesListViewModel = new VehiclesListViewModel(model);

    this.RegionsListViewModel = new RegionsListViewModel(model);

    this.ContractorsListViewModel = new ContractorsListViewModel(model);

    this.FuelingRequestViewModel = new FuelingRequestViewModel(model);
  }

  public get state(): TFuelingCommentState {
    return this.FuelingCommentViewModel.state;
  }

  public get editState(): TFuelingCommentEditState {
    return this.FuelingCommentViewModel.editState;
  }

  /** Indicator for otp request confirmation or resending otp request */
  public get isRequestInProgress(): boolean {
    return this.FuelingCommentViewModel.statuses.isInProgress;
  }

  public get isSavingInProgress(): boolean {
    return (
      this.FuelingCommentViewModel.statuses.getStatus("saveEditState") ===
      RequestStatus.Pending
    );
  }

  public get isDecliningInProgress(): boolean {
    return (
      this.FuelingCommentViewModel.statuses.getStatus(
        "declineFuelingComment"
      ) === RequestStatus.Pending
    );
  }

  public get listOfVehiclesMetadata(): IPagination<IVehicleListItemEntity> {
    return this.VehiclesListViewModel.metadata;
  }

  public get listOfRegionsMetadata(): IPagination<IRegionListItemEntity> {
    return this.RegionsListViewModel.metadata;
  }

  public get listOfContractorsMetadata(): IPagination<IContractorListItemEntity> {
    return this.ContractorsListViewModel.metadata;
  }

  public get isContractorsLoading(): boolean {
    return this.ContractorsListViewModel.statuses.isInProgress;
  }

  public get isVehiclesLoading(): boolean {
    return this.VehiclesListViewModel.statuses.isInProgress;
  }

  public get isRegionsLoading(): boolean {
    return this.RegionsListViewModel.statuses.isInProgress;
  }

  public get isFuelingCreationInProgress(): boolean {
    return this.FuelingRequestViewModel.statuses.isInProgress;
  }

  public fetchCommentFuelingData = (id: number): void => {
    try {
      this.FuelingCommentViewModel.fetchCommentFuelingData(id);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public fetchListOfRegions = (
    page: number = this.RegionsListViewModel.meta.current_page + 1,
    refreshing: boolean = false
  ): void => {
    try {
      this.RegionsListViewModel.fetchItemsBatch(page, refreshing);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public fetchListOfVehicles = (): void => {
    try {
      this.VehiclesListViewModel.fetchItemsBatch(
        this.VehiclesListViewModel.meta.current_page + 1,
        false
      );
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public fetchListOfContractors = (
    page = this.RegionsListViewModel.meta.current_page + 1,
    refreshing: boolean = false
  ): void => {
    try {
      this.ContractorsListViewModel.fetchItemsBatch(page, refreshing);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setFuelingNumber = (number: string | null): void => {
    try {
      this.FuelingCommentViewModel.setFuelingNumber(number);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setVehicle = (
    vehicle: { id: number; number: string } | null
  ): void => {
    try {
      this.FuelingCommentViewModel.setVehicle(vehicle);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setContractor = async (contractor: {
    id: number;
    name: string;
  }): Promise<void> => {
    try {
      const previousContractorId =
        this.FuelingCommentViewModel.editState.fuelingRequest.contractor?.id;
      // clear regions if contractor is different

      this.FuelingCommentViewModel.setContractor(contractor);

      if (contractor.id !== previousContractorId) {
        // TODO: clear region and show empty field and after that need display error message that region is not match to contractor
        // this.FuelingRequestViewModel.setRegion(null);

        this.RegionsListViewModel.setContractorFilter(contractor);

        await this.fetchListOfRegions(1, true);
      }
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setWeight = (weight: string): void => {
    try {
      this.FuelingCommentViewModel.setWeight(weight);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setVolume = (volume: string): void => {
    try {
      this.FuelingCommentViewModel.setVolume(volume);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setFuelingType = (type: number): void => {
    try {
      this.FuelingCommentViewModel.setFuelingType(type);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setVehicleSearch = (contract: string): void => {
    try {
      const previousSearchState = this.VehiclesListViewModel.filters.search;

      this.VehiclesListViewModel.setSearch(contract);

      if (contract !== previousSearchState) {
        this.VehiclesListViewModel.fetchItemsBatchDebounce(1, true);
      }
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setRegion = (region: { id: number; name: string } | null): void => {
    try {
      this.FuelingCommentViewModel.setRegion(region);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setRegionSearch = (region: string): void => {
    try {
      const previousSearchState = this.RegionsListViewModel.filters.search;

      this.RegionsListViewModel.setSearch(region);

      if (region !== previousSearchState) {
        this.RegionsListViewModel.fetchItemsBatchDebounce(1, true);
      }
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setContractorSearch = (contractor: string): void => {
    try {
      const previousSearchState = this.ContractorsListViewModel.filters.search;

      this.ContractorsListViewModel.setSearch(contractor);

      if (contractor !== previousSearchState) {
        this.ContractorsListViewModel.fetchItemsBatchDebounce(1, true);
      }
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public declineFuelingComment = async (): Promise<void> => {
    try {
      // Create contract
      await this.FuelingCommentViewModel.declineFuelingComment(
        this.FuelingCommentViewModel.state.id
      );
      toast.success(
        getMessageFromError(
          TranslationService.t("txt_success_decline_fueling_comment")
        )
      );
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public beforeDestroy = (): void => {
    this.FuelingCommentViewModel.beforeDestroy();
    /** Cancel all disposers */
    Object.values(this.disposers).map((func) => func.call(undefined));
  };
}

export { ScreenControllerFuelingCommentScreen };
