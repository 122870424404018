import {
  IUISceneController,
  TFiltersSceneParams,
  TFiltersSceneResult,
  TFiltersFields,
} from "./types";
import { toast } from "react-toastify";
import { ScenesInstance } from "@scenes/PrototypeScene";
import { Utils } from "@modules/utils";
import { getMessageFromError } from "@modules/utils/getMessageFromError";
import { IContractListItemEntity } from "@entities/ContractListItemEntity";
import { IContractorListItemEntity } from "@entities/ContractorListItemEntity";
import { IRegionListItemEntity } from "@entities/RegionListItemEntity";
import { IFuelingListItemEntity } from "@entities/FuelingListItemEntity/FuelingListItemEntity";
import { IVehicleListItemEntity } from "@entities/VehicleListItemEntity";
import { TSceneDisplayOptions } from "@viewModels/scenes/__types__/SceneDisplay";
import { ContractsListViewModel } from "@viewModels/ContractsListViewModel/ContractsListViewModel";
import { RegionsListViewModel } from "@viewModels/RegionsListViewModel/RegionsListViewModel";
import { ContractorsListViewModel } from "@viewModels/ContractorsListViewModel/ContractorsListViewModel";
import { IContractsListViewModel } from "@viewModels/ContractsListViewModel/__types__/IContractsListViewModel";
import { IRegionsListViewModel } from "@viewModels/RegionsListViewModel/__types__/IRegionsListViewModel";
import { IContractorsListViewModel } from "@viewModels/ContractorsListViewModel/__types__/IContractorsListViewModel";
import { IPagination } from "@viewModels/pagination/types";
import {
  IFiltersState,
  IFiltersViewModel,
} from "@viewModels/FiltersViewModel/__types__/IFiltersViewModel";
import { FiltersViewModel } from "@viewModels/FiltersViewModel/FiltersViewModel";
import { FuelingListViewModel } from "@viewModels/FuelingListViewModel/FuelingListViewModel";
import { IFuelingListViewModel } from "@viewModels/FuelingListViewModel/__types__/IFuelingListViewModel";
import { IVehiclesListViewModel } from "@viewModels/VehiclesListViewModel/__types__/IVehiclesListViewModel";
import { VehiclesListViewModel } from "@viewModels/VehiclesListViewModel/VehiclesListViewModel";
import { IPilotsListViewModel } from "@viewModels/PilotListViewModel/__types__/IPilotListViewModel";
import { PilotsListViewModel } from "@viewModels/PilotListViewModel/PilotListViewModel";
import { IPilotListItemEntity } from "@entities/PilotListItemEntity/PilotListItemEntity";
import { FuelingTurnoverListViewModel } from "@viewModels/FuelingTurnoverListViewModel/FuelingTurnoverListViewModel";
import { IFuelingTurnoverListViewModel } from "@viewModels/FuelingTurnoverListViewModel/__types__/IFuelingTurnoverListViewModel";

export class UISceneController<
    T extends TFiltersSceneResult,
    P extends TFiltersSceneParams
  >
  extends ScenesInstance<T, P>
  implements IUISceneController<T, P>
{
  public static _name: string = "ContractsFiltersScene";

  public fields: Partial<TFiltersFields>;

  private ContractsListViewModel: IContractsListViewModel;

  private RegionsListViewModel: IRegionsListViewModel;

  private PilotsListViewModel: IPilotsListViewModel;

  private VehiclesListViewModel: IVehiclesListViewModel;

  private FuelingListViewModel: IFuelingListViewModel;

  private ContractorsListViewModel: IContractorsListViewModel;

  private FuelingTurnoverListViewModel: IFuelingTurnoverListViewModel;

  private FiltersViewModel: IFiltersViewModel;

  public constructor(
    options: TSceneDisplayOptions,
    private params: TFiltersSceneParams
  ) {
    super(options);

    this.fields = params.fields;

    this.RegionsListViewModel = new RegionsListViewModel(params.model);

    this.PilotsListViewModel = new PilotsListViewModel(params.model);

    this.VehiclesListViewModel = new VehiclesListViewModel(params.model);

    this.ContractsListViewModel = new ContractsListViewModel(params.model, {
      search: "",
    });

    this.FuelingListViewModel = new FuelingListViewModel(params.model, {
      search: "",
    });
    this.ContractorsListViewModel = new ContractorsListViewModel(params.model);

    this.FuelingTurnoverListViewModel = new FuelingTurnoverListViewModel(
      params.model
    );

    // General view model
    this.FiltersViewModel = new FiltersViewModel(params.model, {
      conclusionDate: params.conclusionDate ? params.conclusionDate : null,
      contract: params.contract,
      contractor: params.contractor,
      filterCount: params.filterCount ? params.filterCount : 0,
      fueling: params.fueling ? params.fueling : null,
      fuelingNumber: params.fuelingNumber ? params.fuelingNumber : "",
      fuelingType: params.fuelingType ? params.fuelingType : 0,
      pilot: params.pilot ? params.pilot : null,
      region: params.region ? params.region : null,
      score: params.score ? params.score : null,
      termDate: params.termDate ? params.termDate : null,
      vehicle: params.vehicle ? params.vehicle : null,
    });
  }

  public get _name(): string {
    return UISceneController._name;
  }

  public get filtersState(): IFiltersState {
    return this.FiltersViewModel.state;
  }

  public get listOfFuelingMetadata(): IPagination<IFuelingListItemEntity> {
    return this.FuelingListViewModel.metadata;
  }

  public get listOfContractsMetadata(): IPagination<IContractListItemEntity> {
    return this.ContractsListViewModel.metadata;
  }

  public get listOfContractorsMetadata(): IPagination<IContractorListItemEntity> {
    return this.ContractorsListViewModel.metadata;
  }

  public get listOfRegionsMetadata(): IPagination<IRegionListItemEntity> {
    return this.RegionsListViewModel.metadata;
  }

  public get listOfPilotsMetadata(): IPagination<IPilotListItemEntity> {
    return this.PilotsListViewModel.metadata;
  }

  public get listOfVehiclesMetadata(): IPagination<IVehicleListItemEntity> {
    return this.VehiclesListViewModel.metadata;
  }

  public get isFuelingLoading(): boolean {
    return this.FuelingListViewModel.statuses.isInProgress;
  }

  public get isContractorsLoading(): boolean {
    return this.ContractorsListViewModel.statuses.isInProgress;
  }

  public get isContractsLoading(): boolean {
    return this.ContractsListViewModel.statuses.isInProgress;
  }

  public get isRegionsLoading(): boolean {
    return this.RegionsListViewModel.statuses.isInProgress;
  }

  public get isVehiclesLoading(): boolean {
    return this.VehiclesListViewModel.statuses.isInProgress;
  }

  public get filterCount(): number {
    return this.FiltersViewModel.state.filterCount;
  }

  public fetchListOfRegions = (
    page: number = this.RegionsListViewModel.meta.current_page + 1,
    refreshing: boolean = false
  ): void => {
    try {
      this.RegionsListViewModel.fetchItemsBatch(page, refreshing);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public fetchListOfPilots = (
    page: number = this.PilotsListViewModel.meta.current_page + 1,
    refreshing: boolean = false
  ): void => {
    try {
      this.PilotsListViewModel.fetchItemsBatch(page, refreshing, false);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public fetchListOfFueling = (
    page: number = this.FuelingListViewModel.meta.current_page + 1,
    refreshing: boolean = false,
    isEmptyDocument: boolean = false
  ): void => {
    try {
      this.FuelingListViewModel.fetchItemsBatch(
        page,
        refreshing,
        isEmptyDocument
      );
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public fetchListOfVehicles = (
    page: number = this.VehiclesListViewModel.meta.current_page + 1,
    refreshing: boolean = false
  ): void => {
    try {
      this.VehiclesListViewModel.fetchItemsBatch(page, refreshing);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public fetchListOfContractors = (
    page: number = this.ContractorsListViewModel.meta.current_page + 1,
    refreshing: boolean = false
  ): void => {
    try {
      this.ContractorsListViewModel.fetchItemsBatch(page, refreshing);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public fetchListOfContracts = (
    page: number = this.ContractsListViewModel.meta.current_page + 1,
    refreshing: boolean = false
  ): void => {
    try {
      this.ContractsListViewModel.fetchItemsBatch(page, refreshing);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setContract = async (
    contract: {
      id: number;
      number: string;
    } | null
  ): Promise<void> => {
    try {
      this.FiltersViewModel.setContract(contract);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setConclusionDate = (
    date: { date_from: number; date_to: number } | null
  ): void => {
    try {
      this.FiltersViewModel.setConclusionDate(date);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setTermDate = (
    date: { date_from: number; date_to: number } | null
  ): void => {
    try {
      this.FiltersViewModel.setTermDate(date);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setFuelingNumber = (fuelingNumber: string | null): void => {
    try {
      this.FiltersViewModel.setFuelingNumber(fuelingNumber);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setFueling = (
    fueling: { id: number; number: string } | null
  ): void => {
    try {
      this.FiltersViewModel.setFueling(fueling);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setVehicle = (
    vehicle: { id: number; number: string } | null
  ): void => {
    try {
      this.FiltersViewModel.setVehicle(vehicle);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setFuelingType = (type: number | null): void => {
    try {
      this.FiltersViewModel.setFuelingType(type);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setScore = async (score: string | null): Promise<void> => {
    try {
      this.FiltersViewModel.setScore(score);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setContractor = async (
    contractor: {
      id: number;
      name: string;
    } | null
  ): Promise<void> => {
    try {
      this.FiltersViewModel.setContractor(contractor);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setRegion = (region: { id: number; name: string } | null): void => {
    try {
      this.FiltersViewModel.setRegion(region);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setPilot = (pilot: { id: number; name: string } | null): void => {
    try {
      this.FiltersViewModel.setPilot(pilot);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setFilterCount(count: number): void {
    try {
      this.FiltersViewModel.setFilterCount(count);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  }

  public setContractorSearch = (contractor: string): void => {
    try {
      const previousSearchState = this.ContractorsListViewModel.filters.search;

      this.ContractorsListViewModel.setSearch(contractor);

      if (contractor !== previousSearchState) {
        this.ContractorsListViewModel.fetchItemsBatchDebounce(1, true);
      }
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setContractSearch = (contract: string): void => {
    try {
      const previousSearchState = this.ContractsListViewModel.filters.search;

      this.ContractsListViewModel.setSearch(contract);

      if (contract !== previousSearchState) {
        this.ContractsListViewModel.fetchItemsBatchDebounce(1, true);
      }
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setRegionSearch = (region: string): void => {
    try {
      const previousSearchState = this.RegionsListViewModel.filters.search;

      this.RegionsListViewModel.setSearch(region);

      if (region !== previousSearchState) {
        this.RegionsListViewModel.fetchItemsBatchDebounce(1, true);
      }
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setPilotSearch = (pilot: string): void => {
    try {
      const previousSearchState = this.PilotsListViewModel.filters.search;

      this.PilotsListViewModel.setSearch(pilot);

      if (pilot !== previousSearchState) {
        this.PilotsListViewModel.fetchItemsBatchDebounce(1, true);
      }
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setVehiclesSearch = (vehicle: string): void => {
    try {
      const previousSearchState = this.VehiclesListViewModel.filters.search;

      this.VehiclesListViewModel.setSearch(vehicle);

      if (vehicle !== previousSearchState) {
        this.VehiclesListViewModel.fetchItemsBatchDebounce(1, true);
      }
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setFuelingSearch = (data: string): void => {
    try {
      const previousSearchState = this.FuelingListViewModel.filters.search;

      this.FuelingListViewModel.setSearch(data);

      if (data !== previousSearchState) {
        this.FuelingListViewModel.fetchItemsBatchDebounce(1, true);
      }
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public applyFilters = async (): Promise<void> => {
    try {
      const newState: any = this.FiltersViewModel.state;
      const initialState: any = this.FiltersViewModel.initialState;
      let count = 0;
      Object.keys(newState)?.map((el) => {
        if (el === "filterCount") return;
        else {
          if (initialState[el] !== newState[el] && newState[el]) {
            count++;
          } else return;
        }
      });
      this.FiltersViewModel.setFilterCount(count);
      this.displays.handleResolve({
        conclusionDate: this.FiltersViewModel.state.conclusionDate,
        contract: this.FiltersViewModel.state.contract,
        contractor: this.FiltersViewModel.state.contractor,
        filterCount: this.FiltersViewModel.state.filterCount,
        fueling: this.FiltersViewModel.state.fueling,
        fuelingNumber: this.FiltersViewModel.state.fuelingNumber,
        fuelingType: this.FiltersViewModel.state.fuelingType,
        pilot: this.FiltersViewModel.state.pilot,
        region: this.FiltersViewModel.state.region,
        score: this.FiltersViewModel.state.score,
        termDate: this.FiltersViewModel.state.termDate,
        vehicle: this.FiltersViewModel.state.vehicle,
      } as any);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public closeScene = (): void => {
    try {
      this.displays.handleRejects();
    } catch (error) {
      toast.error(Utils.getMessageFromError(error));
    }
  };

  public clearFiltersState = (): void => {
    try {
      this.FiltersViewModel.clearState();

      // Apply empty filters
      this.applyFilters();
    } catch (error) {
      toast.error(Utils.getMessageFromError(error));
    }
  };
}
