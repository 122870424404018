import { TParseDto } from "./__types__/dto";
import Joi from "joi";

export const transform = <R extends any, T = any>(
  scheme: Joi.Schema<T>,
  data: T,
  parse: TParseDto | undefined
): R => {
  const incoming = parse ? parse(data) : data;

  const { error, value } = scheme.validate(incoming, {
    abortEarly: false,
    allowUnknown: false,
    convert: true,
    stripUnknown: true,
  });

  if (error || !value) throw new Error(error?.message);

  return value as R;
};
