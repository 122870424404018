const IconMain = (): JSX.Element => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 22H22V11.2415C22 10.7292 21.8892 10.2231 21.6754 9.75771C21.4616 9.29234 21.1498 8.87877 20.7613 8.54536L13.971 2.73341C13.4237 2.26031 12.7247 2 12.0016 2C11.2785 2 10.5795 2.26031 10.0323 2.73341L3.24194 8.54536C2.85286 8.87846 2.54041 9.2919 2.32603 9.75729C2.11165 10.2227 2.00043 10.729 2 11.2415V22ZM3.93548 11.2415C3.93547 11.0083 3.98592 10.7778 4.08337 10.566C4.18082 10.3542 4.32296 10.1661 4.5 10.0145L11.2903 4.20254C11.4877 4.03232 11.7395 3.93869 12 3.93869C12.2605 3.93869 12.5123 4.03232 12.7097 4.20254L19.5 10.0145C19.677 10.1661 19.8192 10.3542 19.9166 10.566C20.0141 10.7778 20.0645 11.0083 20.0645 11.2415V20.0627H3.93548V11.2415Z"
      fill="#303B57"
    />
    <path
      d="M16.8387 15.8652H7.16129V17.8025H16.8387V15.8652Z"
      fill="#303B57"
    />
  </svg>
);

export default IconMain;
