import { Utils } from "@modules/utils";
import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";
import moment from "moment";

export const NotificationListItemEntity = types
  .model({
    // comment: types.optional(types.string, ""),
    dt: types.optional(types.string, moment().toDate().toDateString()),
    id: types.number,
    message: types.optional(types.string, ""),
    // status: types.optional(types.number, 0),
    title: types.optional(types.string, ""),
    type: types.optional(types.string, ""),
  })
  .views((self) => ({
    get hash(): string {
      return Utils.hash(self);
    },
  }))
  .named("NotificationListItemEntity");

export type INotificationListItemEntity = Instance<
  typeof NotificationListItemEntity
>;
export type INotificationListItemEntityIn =
  SnapshotIn<INotificationListItemEntity>;
export type INotificationListItemEntityOut =
  SnapshotOut<INotificationListItemEntity>;
