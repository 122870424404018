const IconSort = (): JSX.Element => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.655 7.27122C10.468 7.39287 10.2442 7.44512 10.0227 7.41916C9.8012 7.39319 9.59556 7.29045 9.44186 7.12889L7.37391 5.06094L7.37375 11.798C7.37375 12.1264 7.19857 12.4298 6.91423 12.5941C6.62989 12.7583 6.2795 12.7583 5.99519 12.5941C5.71085 12.4298 5.53567 12.1265 5.53567 11.798L5.53567 5.05848L3.44463 7.14711C3.29541 7.3005 3.0981 7.39811 2.88573 7.42359C2.67335 7.44908 2.45858 7.40099 2.2773 7.28735C2.04425 7.13637 1.89183 6.88809 1.86279 6.61176C1.83378 6.33559 1.93124 6.06102 2.1279 5.86501L4.59353 3.39939C4.85992 3.12755 5.18111 2.91533 5.53567 2.77668C6.00257 2.59652 6.51178 2.55581 7.0013 2.65983C7.49098 2.76386 7.93975 3.00813 8.293 3.36267L10.7815 5.86498C10.9745 6.05764 11.0719 6.32611 11.0476 6.59762C11.0231 6.86929 10.8792 7.11607 10.655 7.27122Z"
      fill="#303B57"
    />
    <path
      d="M9.34547 12.8323C9.53252 12.7106 9.75629 12.6584 9.97778 12.6844C10.1993 12.7103 10.4049 12.8131 10.5586 12.9746L12.6266 15.0426L12.6267 8.30549C12.6267 7.97707 12.8019 7.67367 13.0863 7.50938C13.3706 7.34525 13.721 7.34525 14.0053 7.50938C14.2896 7.67367 14.4648 7.97706 14.4648 8.30549L14.4648 15.045L16.5559 12.9564C16.7051 12.803 16.9024 12.7054 17.1148 12.6799C17.3271 12.6544 17.5419 12.7025 17.7232 12.8162C17.9562 12.9671 18.1087 13.2154 18.1377 13.4918C18.1667 13.7679 18.0693 14.0425 17.8726 14.2385L15.407 16.7041C15.1406 16.976 14.8194 17.1882 14.4648 17.3268C13.9979 17.507 13.4887 17.5477 12.9992 17.4437C12.5095 17.3397 12.0607 17.0954 11.7075 16.7408L9.21897 14.2385C9.02599 14.0459 8.92854 13.7774 8.9529 13.5059C8.97742 13.2342 9.12124 12.9874 9.34547 12.8323Z"
      fill="#303B57"
    />
  </svg>
);

export default IconSort;
