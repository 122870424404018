import { Validation } from "./components/Validation/Validation";
import { IScreenControllerProfile } from "@screens/ProfileScreen/connector/__types__/UIViewController";
import { TranslationService } from "@services/translate";
import Button from "@shared/controls/Button/Button";
import Input from "@shared/controls/Input/Input";
import { useControllers } from "@view/hooks/useControllers";
import { useWindowWidth } from "@view/hooks/useWindowWidth";
import { observer } from "mobx-react";
import styled from "styled-components";

export const ProfileSecureForm = observer(() => {
  const { UIViewController } = useControllers<{
    UIViewController: IScreenControllerProfile;
  }>();

  const windowWidth = useWindowWidth();

  return (
    <FlexGroup className="row justify-between">
      <Gap42 className="col">
        <Gap24 className="col">
          <h3>{TranslationService.t("txt_subtitle_password")}</h3>
          <Gap30 className="col">
            <div
              className={`custom-field ${
                UIViewController.profileErrors.errorOldPassword ? "error" : ""
              }`}
            >
              <label>Старый пароль</label>
              <div className="col">
                <Input
                  value={UIViewController.profileEditState.oldPassword}
                  type={"password"}
                  placeholder="Введите старый пароль"
                  onChange={(value) => UIViewController.setOldPassword(value)}
                />
                {/* <ForgetPassword>Забыли пароль?</ForgetPassword> */}
                {UIViewController.profileErrors.errorOldPassword && (
                  <Error>Неверный пароль</Error>
                )}
              </div>
            </div>
            <div
              className={`custom-field ${
                UIViewController.profileErrors
                  .errorPasswordValidationBigLetter ||
                UIViewController.profileErrors.errorPasswordValidationNumber ||
                UIViewController.profileErrors.errorPasswordValidationSymbols ||
                UIViewController.profileErrors.errorPasswordNotEqual
                  ? "error"
                  : ""
              }`}
            >
              <label>Новый пароль</label>
              <div className="col">
                <Input
                  value={UIViewController.profileEditState.password}
                  type={"password"}
                  placeholder="Введите новый пароль"
                  onChange={(value) => UIViewController.setPassword(value)}
                />
                {(UIViewController.profileErrors
                  .errorPasswordValidationBigLetter ||
                  UIViewController.profileErrors
                    .errorPasswordValidationNumber ||
                  UIViewController.profileErrors
                    .errorPasswordValidationSymbols) && (
                  <Error>Новый пароль не соответствует требованиям</Error>
                )}
              </div>
            </div>
            {windowWidth <= 1024 ? <Validation /> : null}
            <div
              className={`custom-field ${
                UIViewController.profileErrors.errorPasswordNotEqual
                  ? "error"
                  : ""
              }`}
            >
              <label>Новый пароль</label>
              <div className="col">
                <Input
                  value={UIViewController.profileEditState.repeatPassword}
                  type={"password"}
                  placeholder="Введите новый пароль ещё раз"
                  onChange={(value) =>
                    UIViewController.setRepeatPassword(value)
                  }
                />
                {UIViewController.profileErrors.errorPasswordNotEqual && (
                  <Error>Пароли не совпадают</Error>
                )}
              </div>
            </div>
          </Gap30>
        </Gap24>
        <Button
          text="Изменить"
          type="button"
          className="primary"
          size="small"
          onClick={UIViewController.saveProfileSecureInfo}
          loading={
            UIViewController.isSecureInProgress ||
            UIViewController.isFetchingInProgress
          }
          disabled={
            !UIViewController.profileEditState.password?.length ||
            !UIViewController.profileEditState.oldPassword?.length ||
            !UIViewController.profileEditState.repeatPassword?.length ||
            UIViewController.isSecureInProgress ||
            UIViewController.isFetchingInProgress
          }
        />
      </Gap42>
      {windowWidth > 1024 ? (
        <div className="row">
          <Validation />
        </div>
      ) : null}
    </FlexGroup>
  );
});

const FlexGroup = styled.div`
  gap: 26px;

  > div {
    &:first-child {
      width: 50%;
    }
    &:last-child {
      width: calc(50% - 26px);
    }
    @media (max-width: 1024px) {
      width: 100% !important;
    }
  }
`;

const Gap24 = styled.div`
  gap: 24px;
`;

const Gap42 = styled.div`
  gap: 42px;
`;

const Gap30 = styled.div`
  gap: 30px;
`;

const Error = styled.p`
  position: absolute;
  left: 0;
  bottom: -20px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #ef5d58;
`;

const ForgetPassword = styled.p`
  position: absolute;
  right: 0;
  bottom: -20px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  border-bottom: 1px dashed #303b57;
`;

const DefaultPoint = styled.div`
  content: "";
  background: #303b57;
  width: 13px;
  height: 13px;
  border-radius: 50%;
`;
