import {
  IRequestServiceViewModel,
  IRequestServiceViewModelParams,
} from "./__types__/IRequestServiceViewModel"; // Importing necessary types from IAuthViewModel
import {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
  isAxiosError,
} from "axios";

export class RequestServiceViewModel implements IRequestServiceViewModel {
  // Constructor that takes in the parameters and initializes the class properties
  public constructor(private params: IRequestServiceViewModelParams) {}

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public onRequest = async (
    config: InternalAxiosRequestConfig<any>
  ): Promise<InternalAxiosRequestConfig<any>> => {
    try {
      return config;
    } catch (error) {
      return config;
    }
  };

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public onResponse = async (response: any): Promise<AxiosResponse> => {
    try {
      return response;
    } catch (error) {
      return response;
    }
  };

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public onResponseError = async (error: any): Promise<AxiosError> => {
    try {
      if (isAxiosError(error)) {
        if (error.response?.status === 401) {
          this.params.model.user.logout();
        }
      }

      return Promise.reject(error);
    } catch (err) {
      return Promise.reject(error);
    }
  };

  public beforeDestroy = (): void => {
    // No-op method
  };
}
