// import { TranslationService } from "@services/translate";
import {
  IUISceneController,
  TFuelTransferSceneParams,
  TFuelTransferSceneResult,
} from "../connector/UISceneController/types";
import SelectComponent from "@shared/controls/Select/Select";
import { useEffect } from "react";
import Input from "@shared/controls/Input/Input";
import { observer } from "mobx-react";
import { useScene } from "@view/hooks/useScene/useScene";
import FilterComponent from "@shared/helpers/SideForm/SideForm";
import Button from "@shared/controls/Button/Button";
// import { TranslationService } from "@services/translate";
import styled from "styled-components";
import { IconInfo } from "@shared/controls/icons";

const FuelTransferForm = observer((): JSX.Element => {
  const UISceneController =
    useScene<
      IUISceneController<TFuelTransferSceneResult, TFuelTransferSceneParams>
    >();

  const errorWidth = false;

  useEffect(() => {
    // Load list of contractors on mount
    if (!UISceneController.createFuelTransferState.contractor)
      UISceneController.fetchListOfContractors();
    UISceneController.fetchListOfRegions();
  }, []);

  return (
    <FilterComponent
      title="Перенос топлива"
      onClose={UISceneController.closeScene}
    >
      <div className="custom-form">
        <div className="custom-field">
          <label>Поставщик</label>
          <SelectComponent
            onInputChange={UISceneController.setContractorSearch}
            onMenuScrollToBottom={() =>
              UISceneController.fetchListOfContractors()
            }
            options={UISceneController.listOfContractorsMetadata.data.map(
              (contractor, index) => ({
                id: contractor.id,
                index,
                label: contractor.name,
                name: contractor.name,
                value: contractor.id,
              })
            )}
            isLoading={UISceneController.isContractorsLoading}
            value={
              UISceneController.createFuelTransferState?.contractor
                ? {
                    label:
                      UISceneController.createFuelTransferState.contractor.name,
                    value:
                      UISceneController.createFuelTransferState.contractor.id,
                  }
                : UISceneController.createFuelTransferState.contractor?.id
            }
            // isDisabled={
            //   UISceneController.createFuelTransferState.contractor
            //     ? true
            //     : false
            // }
            onChange={(contractor: any) =>
              UISceneController.setContractor(contractor)
            }
          />
        </div>
        <div className="custom-field">
          <label>Вес топлива, кг</label>
          <Input
            placeholder="Введите вес топлива"
            value={UISceneController.createFuelTransferState.weight}
            type="number"
            max={800}
            onChange={(weight: number) => UISceneController.setWeight(+weight)}
          />
          {errorWidth ? null : <label>Макс. значение: 800</label>}
        </div>
        {errorWidth ? (
          <ErrorBlock className="row">
            <IconInfo />
            <label>
              Вес топлива не может быть больше, чем 800, установлено
              максимальное значение
            </label>
          </ErrorBlock>
        ) : null}
        <div className="gap14 col">
          <Title>Откуда</Title>
          <div className="custom-field">
            <label>Регион</label>
            <SelectComponent
              onInputChange={UISceneController.setRegionSearch}
              options={undefined}
              isMulti={false}
              onMenuScrollToBottom={() =>
                UISceneController.fetchListOfRegions()
              }
              // isDisabled={!UISceneController.FuelTransferState.contractor}
              // isLoading={UISceneController.isRegionsLoading}
              value={
                UISceneController.createFuelTransferState?.regionFrom
                  ? {
                      label:
                        UISceneController.createFuelTransferState.regionFrom
                          .name,
                      value:
                        UISceneController.createFuelTransferState.regionFrom.id,
                    }
                  : null
              }
              // value={{
              //   label: UISceneController.fields.regionFrom?.name,
              //   value: UISceneController.fields.regionFrom?.id,
              // }}
              // isDisabled
              onChange={(region: any) =>
                UISceneController.setRegionFrom(region)
              }
            />
          </div>
        </div>
        <div className="gap14 col">
          <Title>Куда</Title>
          <div className="custom-field">
            <label>Регион</label>
            <SelectComponent
              onMenuScrollToBottom={() =>
                UISceneController.fetchListOfRegions()
              }
              onInputChange={UISceneController.setRegionSearch}
              options={UISceneController.listOfRegionsMetadata.data.map(
                (region, index) => ({
                  id: region.id,
                  index,
                  label: region.name,
                  name: region.name,
                  value: region.id,
                })
              )}
              isMulti={false}
              // isDisabled={!UISceneController.FuelTransferState.contractor}
              isLoading={UISceneController.isRegionsLoading}
              value={
                UISceneController.createFuelTransferState?.regionTo
                  ? {
                      label:
                        UISceneController.createFuelTransferState.regionTo.name,
                      value:
                        UISceneController.createFuelTransferState.regionTo.id,
                    }
                  : null
              }
              // value={UISceneController.createFuelTransferState.regionTo}
              onChange={(region: any) => UISceneController.setRegionTo(region)}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <Button
          text={"Подтвердить"}
          type={"button"}
          className={"primary"}
          size="small"
          disabled={
            !UISceneController.createFuelTransferState.contractor ||
            !UISceneController.createFuelTransferState.regionFrom ||
            !UISceneController.createFuelTransferState.regionTo ||
            !UISceneController.createFuelTransferState.weight
          }
          onClick={UISceneController.createFuelTransfer}
        />
      </div>
    </FilterComponent>
  );
});

export default FuelTransferForm;

const Title = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;

const ErrorBlock = styled.div`
  background: #ef5d581a;
  padding: 16px 16px 16px 8px;
  border-radius: 6px;

  label {
    color: #ef5d58;
    width: fit-content;
  }

  svg {
    width: 24px;
    height: 24px;
    path {
      fill: #ef5d58;
    }
  }
`;
