import { IScreenControllerRecalculations } from "@screens/RecalculationsScreen/connector/__types__/UIViewController";
import Pagination from "@shared/controls/Pagination/Pagination";
import { useControllers } from "@view/hooks/useControllers";
import { observer } from "mobx-react";
import React from "react";

export const RecalculationsPagination = observer(() => {
  const { UIViewController } = useControllers<{
    UIViewController: IScreenControllerRecalculations;
  }>();

  return (
    <Pagination
      count={UIViewController.listOfRecalculationsMetadata.data.length}
      total={UIViewController.listOfRecalculationsMetadata.meta.total}
      per_page={UIViewController.listOfRecalculationsMetadata.meta.per_page}
      loading={UIViewController.isRequestInProgress}
      onLoad={UIViewController.fetchListOfRecalculations}
    />
  );
});
