import Joi from "joi";

export const DtoFuelsByRegionReportScheme = Joi.object({
  board_number: Joi.number().required(),
  fuel_volume: Joi.number().required(),
  id: Joi.number().required(),
  pilot_name: Joi.string().required().allow(""),
  provider: Joi.string().required().allow(""),
  refueling_date: Joi.number().required(),
  weight: Joi.number().required(),
});
