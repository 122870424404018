const IconLogout = (): JSX.Element => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.4876 19.6794C19.5877 20.6452 18.3816 21.0984 16.1911 21.9204C15.8803 22.0369 15.536 22.0251 15.234 21.8877C14.9319 21.7502 14.6967 21.4983 14.5803 21.1875C14.4639 20.8766 14.4757 20.5322 14.6131 20.2301C14.7505 19.9279 15.0023 19.6927 15.3131 19.5763C17.2067 18.8668 18.1597 18.5105 18.6597 17.9729C18.8656 17.7522 19.0382 17.5026 19.1721 17.2321C19.5002 16.5757 19.5002 15.5568 19.5002 13.5315V10.4622C19.5002 8.43681 19.5002 7.41788 19.1721 6.76152C19.0382 6.49099 18.8656 6.24144 18.6597 6.02076C18.1597 5.48317 17.2067 5.12686 15.3131 4.41736C15.0052 4.2991 14.7565 4.06389 14.6212 3.76301C14.4859 3.46213 14.475 3.11996 14.5909 2.81107C14.7067 2.50218 14.9399 2.25162 15.2397 2.11399C15.5394 1.97636 15.8814 1.96282 16.1911 2.07632C18.3784 2.89521 19.5877 3.34842 20.4876 4.31421C20.8625 4.71179 21.1782 5.16126 21.425 5.64882C22 6.83028 22 8.12113 22 10.4622V13.5315C22 15.8725 22 17.1633 21.4125 18.3448C21.1696 18.8316 20.8581 19.281 20.4876 19.6794Z"
      fill="#303B57"
    />
    <path
      d="M14.8131 10.7466C15.1446 10.7466 15.4625 10.8783 15.6969 11.1128C15.9313 11.3472 16.063 11.6652 16.063 11.9968C16.063 12.3284 15.9313 12.6464 15.6969 12.8808C15.4625 13.1153 15.1446 13.247 14.8131 13.247L5.33265 13.247L8.14491 16.06C8.36388 16.2691 8.50306 16.548 8.53842 16.8487C8.57379 17.1494 8.50313 17.453 8.33864 17.7072C8.23441 17.8579 8.09833 17.9838 7.94005 18.076C7.78177 18.1683 7.60516 18.2246 7.42271 18.241C7.24026 18.2574 7.05644 18.2335 6.88425 18.1709C6.71207 18.1084 6.55573 18.0088 6.4263 17.8791L3.07971 14.5316C2.70895 14.1688 2.42021 13.7308 2.2329 13.247C1.98975 12.6116 1.9356 11.9194 2.07698 11.2539C2.21837 10.5884 2.54925 9.97798 3.02971 9.49637L6.42943 6.1114C6.5611 5.97931 6.72065 5.87836 6.89639 5.81596C7.07213 5.75356 7.25959 5.73128 7.44506 5.75076C7.63052 5.77024 7.80928 5.83099 7.96822 5.92854C8.12717 6.0261 8.26227 6.15799 8.36364 6.31456C8.51824 6.5611 8.58365 6.85321 8.54896 7.14215C8.51427 7.4311 8.38158 7.69942 8.17303 7.90234L5.33265 10.7466L14.8131 10.7466Z"
      fill="#303B57"
    />
  </svg>
);

export default IconLogout;
