import { TDtoFuelsByRegionEntry } from "./__types__/TDtoFuelsByRegionEntry.types";
import { TParseDto } from "../__types__/dto";
import { Utils } from "../..";

export const DtoFuelsByRegionReportParser: TParseDto<TDtoFuelsByRegionEntry> = (
  data: any
): TDtoFuelsByRegionEntry => ({
  board_number: Number(data.board_number),
  fuel_volume: Number(data.fuel_volume),
  id: Number(data.id),
  pilot_name: Utils.isString(data.pilot_name) ? data.pilot_name : "",
  provider: Utils.isString(data.provider) ? data.provider : "",
  refueling_date: Number(data.refueling_date),
  weight: Number(data.weight),
});
