import { TDtoContractListItemEntry } from "./__types__/TDtoContractListItemEntry.types";
import { TParseDto } from "../__types__/dto";
import { Utils } from "../..";

export const DtoContractListItemParser: TParseDto<TDtoContractListItemEntry> = (
  data: any
): TDtoContractListItemEntry => ({
  conclusionDate: Utils.isString(data.conclusionDate)
    ? data.conclusionDate
    : "",
  contractor: {
    id: Number(data.contractor.id),
    name: Utils.isString(data.contractor.name) ? data.contractor.name : "",
    // regions: Array.isArray(data.regions)
    //   ? data.regions.map((region: any) => ({
    //       contractors: Array.isArray(region.contractors)
    //         ? region.map((element: any) =>
    //             Utils.isString(element) ? element : ""
    //           )
    //         : [],
    //       id: Utils.isString(region.id) ? region.id : "",
    //       name: Utils.isString(region.name) ? region.name : "",
    //     }))
    //   : [],
    // regions: Array.isArray(data.regions)
    //   ? data.regions.filter(Boolean).map((region: any) => Number(region))
    //   : [],
  },
  id: Number(data.id),
  number: Utils.isString(data.number) ? data.number : "",
  regions: Array.isArray(data.regions)
    ? data.regions.map((region: any) => ({
        // contractors: Array.isArray(region.contractors)
        //   ? region.map((element: any) =>
        //       Utils.isString(element) ? element : ""
        //     )
        //   : [],
        id: Number(region.id),
        name: Utils.isString(region.name) ? region.name : "",
      }))
    : [],
  // region: Array.isArray(data.region)
  //   ? data.region.filter(Boolean).map((regionId: any) => Number(regionId))
  //   : [],
  // {
  //   contractors: Array.isArray(data.region.contractors)
  //     ? data.region.map((element: any) =>
  //         Utils.isString(element) ? element : ""
  //       )
  //     : [],
  //   id: Number(data.region.id),
  //   name: Utils.isString(data.region.name) ? data.region.name : "",
  // },
  termDate: Utils.isString(data.termDate) ? data.termDate : "",
});
