import { TDtoUserEntry } from "./__types__/TDtoUserEntry.type";
import { TParseDto } from "../__types__/dto";
import { Utils } from "../..";

export const DtoUserParser: TParseDto<TDtoUserEntry> = (
  data: any
): TDtoUserEntry => ({
  email: Utils.isString(data.email) ? data.email : "",
  // id: Number(data.id),
  name: Utils.isString(data.name) ? data.name : "",
  roles: Array.isArray(data.roles) ? data.roles : [],
  username: Utils.isString(data.username) ? data.username : "",
});
