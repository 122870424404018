import { IRootTreeModel } from "..";
import { Utils } from "@modules/utils";

import { NotificationsListViewModel } from "@viewModels/NotificationsListViewModel/NotificationsListViewModel";
import { debounce } from "lodash";
import { IReactionDisposer, reaction } from "mobx";
import {
  Instance,
  types,
  SnapshotIn,
  SnapshotOut,
  getRoot,
} from "mobx-state-tree";

export const NotificationsTreeModel = types
  .model({})
  .extend((self) => {
    const root = getRoot(self) as IRootTreeModel;

    const notificationsViewModel = new NotificationsListViewModel(root, {
      per_page: 1000,
    });

    return {
      actions: {
        fetchNotifications: debounce(
          async () => {
            try {
              await notificationsViewModel.fetchItemsBatch(1, true);
            } catch (error) {
              console.error({ error });
            }
          },
          10000,
          { leading: true }
        ),

        hideNotification: (hash: string) => {
          try {
            notificationsViewModel.markNotificationAsRead(hash);
          } catch (error) {
            console.error({ error });
          }
        },
      },

      views: {
        get count() {
          return this.list.length;
        },

        get list() {
          return notificationsViewModel.metadata.data.filter(
            (item) => !notificationsViewModel.blacklist[item.hash]
          );
        },
      },
    };
  })
  .actions((self) => {
    const root = getRoot(self) as IRootTreeModel;

    let disposer: IReactionDisposer;
    let interval: NodeJS.Timer;

    return {
      afterCreate(): void {
        disposer = reaction(
          () => root.user.isLogin,
          (current, prev) =>
            Utils.compare(current, prev, (): void => {
              if (current) {
                self.fetchNotifications();
              }
            }),
          { fireImmediately: true }
        );

        interval = setInterval(() => self.fetchNotifications(), 60000);
      },

      beforeDestroy(): void {
        if (disposer) disposer();
        if (interval) clearInterval(interval);
      },
    };
  });

export type INotificationsTreeModel = Instance<typeof NotificationsTreeModel>;
export type INotificationsTreeModelIn = SnapshotIn<INotificationsTreeModel>;
export type INotificationsTreeModelOut = SnapshotOut<INotificationsTreeModel>;
