import { IScreenControllerProfile } from "@screens/ProfileScreen/connector/__types__/UIViewController";
import { TranslationService } from "@services/translate";
import { IconCheck, IconCross } from "@shared/controls/icons";
import { useControllers } from "@view/hooks/useControllers";
import { observer } from "mobx-react";
import styled from "styled-components";

export const Validation = observer(() => {
  const { UIViewController } = useControllers<{
    UIViewController: IScreenControllerProfile;
  }>();

  return (
    <ValidationBlock
      className={`
          ${
            UIViewController.profileErrors.errorPasswordValidationBigLetter ===
              true ||
            UIViewController.profileErrors.errorPasswordValidationNumber ===
              true ||
            UIViewController.profileErrors.errorPasswordValidationSymbols ===
              true
              ? "error"
              : UIViewController.profileErrors
                  .errorPasswordValidationBigLetter === false &&
                UIViewController.profileErrors.errorPasswordValidationNumber ===
                  false &&
                UIViewController.profileErrors
                  .errorPasswordValidationSymbols === false
              ? "success"
              : null
          }`}
    >
      <li>
        {UIViewController.profileErrors.errorPasswordValidationSymbols ===
        true ? (
          <div className="error">
            <IconCross />
          </div>
        ) : UIViewController.profileErrors.errorPasswordValidationSymbols ===
          false ? (
          <div className="success">
            <IconCheck />
          </div>
        ) : (
          <DefaultPoint />
        )}
        {TranslationService.t("txt_validation_symbols")}
      </li>
      <li>
        {UIViewController.profileErrors.errorPasswordValidationNumber ===
        true ? (
          <div className="error">
            <IconCross />
          </div>
        ) : UIViewController.profileErrors.errorPasswordValidationNumber ===
          false ? (
          <div className="success">
            <IconCheck />
          </div>
        ) : (
          <DefaultPoint />
        )}
        {TranslationService.t("txt_validation_numbers")}
      </li>
      <li>
        {UIViewController.profileErrors.errorPasswordValidationBigLetter ===
        true ? (
          <div className="error">
            <IconCross />
          </div>
        ) : UIViewController.profileErrors.errorPasswordValidationBigLetter ===
          false ? (
          <div className="success">
            <IconCheck />
          </div>
        ) : (
          <DefaultPoint />
        )}
        {TranslationService.t("txt_validation_big_letter")}
      </li>
    </ValidationBlock>
  );
});

const ValidationBlock = styled.ul`
  border-radius: 6px;
  border: 1px solid #303b57;
  padding: 17px 20px !important;
  // width: fit-content;

  align-self: center;
  margin-left: 60px;
  list-style: none;
  gap: 8px;
  display: flex;
  flex-direction: column;

  @media (max-width: 1024px) {
    margin-left: 0;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
  }

  li {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &.error {
    border-color: #ef5d58;

    svg {
      width: 16px;
      height: 16px;

      path {
        fill: #ef5d58;
      }
    }
  }

  &.success,
  .success {
    border-color: #48b415;

    svg {
      width: 16px;
      height: 16px;

      path {
        fill: #48b415;
      }
    }
  }
`;

const DefaultPoint = styled.div`
  content: "";
  background: #303b57;
  width: 13px;
  height: 13px;
  border-radius: 50%;
`;
