import {
  IAuthViewModel,
  IAuthViewModelParams,
} from "./__types__/IAuthViewModel"; // Importing necessary types from IAuthViewModel
import { IReactionDisposer, observable, runInAction } from "mobx"; // Importing required Mobx functionalities
import Joi from "joi"; // Importing the Joi library for data validation
import { AuthRepository } from "@repositories/auth"; // Importing AuthRepository from the "auth" repository
import { AxiosRequestClient } from "@modules/request"; // Importing AxiosRequestClient from the "request" module
import { Utils } from "@modules/utils"; // Importing Utils from the "utils" module
import { IUserEntity, UserEntity } from "@entities/UserEntity"; // Importing IUserEntity and UserEntity from the "UserEntity" entity
import { IRootTreeModel } from "@models/RootTreeModel"; // Importing IRootTreeModel from "RootTreeModel" model
import { TranslationService } from "@services/translate";
import { isAxiosError } from "axios";

// Define the AuthViewModel class implementing IAuthViewModel interface

export class AuthViewModel implements IAuthViewModel {
  // Define a reactions object to store Mobx reaction disposers

  public readonly reactions: {
    [key: string]: IReactionDisposer;
  } = {};

  // Define the state object to store the email, password, error, and loading properties

  public state: {
    email: string;
    password: string;
    error: string;
    loading: boolean;
    changingEmail: string;
    plainPassword: string;
    plainPasswordRepeat: string;
    page: string;
    errorEmail: string | null;
  };

  // Define repositories object containing the auth repository

  public repositories = {
    auth: new AuthRepository(new AxiosRequestClient()),
  };

  // Define the constructor of AuthViewModel class

  public constructor(
    private model: IRootTreeModel, // Inject the model instance of IRootTreeModel
    params?: IAuthViewModelParams // Optional parameter of type IAuthViewModelParams
  ) {
    // Initialize the state object as an observable with the provided parameters or default values

    this.state = observable.object({
      changingEmail: params?.changingEmail || "",
      email: params?.email || "",
      error: params?.error || "",
      errorEmail: params?.errorEmail || "",
      loading: false,
      page: params?.page || "main",
      password: params?.password || "",
      plainPassword: params?.plainPassword || "",
      plainPasswordRepeat: params?.plainPasswordRepeat || "",
    });
  }

  /** Form validations errors */
  public get errors(): {
    email: boolean;
    password: boolean;
  } {
    // Define the errors object with email and password properties

    return {
      // Validate the email using Joi library and return true if there is any validation error, otherwise false
      email: Boolean(
        Utils.validate(Joi.string().required(), this.state.email).error
      ),

      // Validate the password using Joi library and return true if there is any validation error, otherwise false
      password: Boolean(
        Utils.validate(Joi.string().min(8).required(), this.state.password)
          .error
      ),
    };
  }

  public setPage(page: string): void {
    runInAction(() => {
      this.state.page = page;
    });
  }

  // Set the email value in the state object using Mobx runInAction

  public setEmail = (email: string): void => {
    runInAction(() => {
      this.state.email = email;
      this.state.errorEmail = null;
    });
  };

  public setChangingEmail = (email: string): void => {
    runInAction(() => {
      this.state.changingEmail = email;
    });
  };

  // Set the password value in the state object using Mobx runInAction

  public setPassword = (password: string): void => {
    runInAction(() => {
      this.state.password = password;
    });
  };

  public setPlainPassword = (password: string): void => {
    runInAction(() => {
      this.state.plainPassword = password;
    });
  };

  public setPlainPasswordRepeat = (password: string): void => {
    runInAction(() => {
      this.state.plainPasswordRepeat = password;
    });
  };

  // Set the error value in the state object using Mobx runInAction

  public setError = (error: string): void => {
    runInAction(() => {
      this.state.error = error;
    });
  };

  public setErrorEmail = (error: string | null): void => {
    runInAction(() => {
      this.state.errorEmail = error;
    });
  };

  // Authenticate the user using the provided email and password

  public loginByEmail = async (
    email: string,
    password: string
  ): Promise<IUserEntity> => {
    try {
      // Set the loading property to true using Mobx runInAction

      runInAction(() => {
        this.state.loading = true;
      });

      // Only latin letters
      // if (/^[^\u0400-\u04FF]+$/) {
      //   throw new Error(TranslationService.t("txt_wrong_email_format"));
      // }

      if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
        throw new Error(TranslationService.t("txt_wrong_email_format"));
      }

      if (!password || password.length < 6) {
        throw new Error(TranslationService.t("txt_wrong_current_password"));
      }

      // Call the loginByEmail method of AuthRepository to authenticate the user

      const response = await this.repositories.auth.loginByEmail({
        password,
        username: email,
      });
      // Create a UserEntity object with the response data

      const user = UserEntity.create({
        email: response.email,
        name: response.name,
        roles: response.roles,
        username: response.username,
      });

      // Call the auth method of the user instance in the model

      this.model.user.auth({
        user,
      });

      // Set the loading property to false using Mobx runInAction

      runInAction(() => {
        this.state.loading = false;
      });

      // Return the authenticated user

      return user;
    } catch (error: any) {
      // Set the loading property to false using Mobx runInAction in case of an error

      runInAction(() => {
        this.state.loading = false;
      });

      if (localStorage.getItem("AUTH_ERROR_MSG")) {
        this.setErrorEmail(localStorage.getItem("AUTH_ERROR_MSG"));
        throw localStorage.removeItem("AUTH_ERROR_MSG");
      }
      if (isAxiosError(error)) {
        if (error.status === 404)
          throw new Error(
            TranslationService.t("txt_user_with_credentials_not_exist")
          );
      }
      throw this.setErrorEmail(TranslationService.t("txt_error_login"));

      // Throw the error to the caller

      // throw error;
    }
  };

  public resetPassword = async (email: string): Promise<any> => {
    try {
      // Set the loading property to true using Mobx runInAction

      runInAction(() => {
        this.state.loading = true;
      });

      if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
        throw new Error(TranslationService.t("txt_wrong_email_format"));
      }

      // Call the loginByEmail method of AuthRepository to authenticate the user

      const response = await this.repositories.auth.resetPassword({
        username: email,
      });

      // Create a UserEntity object with the response data

      // const user = UserEntity.create({
      //   email: response.email,
      //   name: response.name,
      //   roles: response.roles,
      //   username: response.username,
      // });

      // Call the auth method of the user instance in the model

      // this.model.user.auth({
      //   user,
      // });

      // Set the loading property to false using Mobx runInAction

      runInAction(() => {
        this.state.loading = false;
      });

      // Return the authenticated user

      return response;
    } catch (error: any) {
      // Set the loading property to false using Mobx runInAction in case of an error

      runInAction(() => {
        this.state.loading = false;
      });

      if (isAxiosError(error)) {
        if (error.status === 404)
          throw new Error(
            TranslationService.t("txt_user_with_credentials_not_exist")
          );
      }

      // Throw the error to the caller

      throw error;
    }
  };

  // Logout the user by calling the logout method of the user instance in the model

  public logout = (): void => {
    runInAction(() => {
      this.model.user.logout();
    });
  };

  // Define a no-op method beforeDestroy

  public beforeDestroy = (): void => {
    // No-op method
  };
}
