import { ScreenControllerFuelingCommentEditScreen } from "./connector/UIViewController";
import { IScreenControllerFuelingCommentEditScreen } from "./connector/__types__/UIViewController";
import { FuelingCommentEditActionForm } from "./components/ActionsForm/ActionForm";
import { FuelingCommentEditFieldsForm } from "./components/FieldsForm/FieldsForm";
import styled from "styled-components";
import { observer } from "mobx-react";
import { IconArrowLeft } from "@shared/controls/icons";
import { Link, useParams } from "react-router-dom";
import { useEffect } from "react";
import withControllers from "@view/providers/__proto__/withController";
import { useControllers } from "@view/hooks/useControllers";
import { FuelingCommentStatus } from "@constants/fueling";
import { Screens } from "@constants/screens";
import { Helmet } from "react-helmet";

const TranslateStatusMap = {
  [FuelingCommentStatus.Pending]: "Загрузка",
  [FuelingCommentStatus.New]: "Новая",
  [FuelingCommentStatus.Fixed]: "Исправлена",
  [FuelingCommentStatus.Declined]: "Отклонена",
};

const FuelingCommentEditScreen = observer((): JSX.Element => {
  const { UIViewController } = useControllers<{
    UIViewController: IScreenControllerFuelingCommentEditScreen;
  }>();
  const params = useParams();

  useEffect(() => {
    UIViewController.fetchCommentFuelingData(Number(params.id));
  }, []);

  useEffect(() => {
    if (!UIViewController.state.id) return;

    // Fetch initial dependencies
    UIViewController.fetchListOfContractors();
    UIViewController.fetchListOfRegions();
    UIViewController.fetchListOfVehicles();
  }, [UIViewController.state.id]);

  return (
    <Col32 className="col filter-form">
      <Helmet>
        <title>{`Редактирование заявки №${UIViewController.state.id}`}</title>
      </Helmet>
      <Title>
        <Link to={Screens.Application.path()}>
          <IconArrowLeft />
        </Link>
        Заявка №{UIViewController.state.id}
        <span className={`status status${UIViewController.state.status}`}>
          {
            TranslateStatusMap[
              UIViewController.state.status as FuelingCommentStatus
            ]
          }
        </span>
      </Title>
      <Col48 className="col">
        <Col32 className="col">
          <Col16 className="col">
            <Comment>{UIViewController.state.comment}</Comment>
            {UIViewController.state.file?.url ? (
              <img src={UIViewController.state.file?.url} />
            ) : null}
          </Col16>
          {UIViewController.state.id ? <FuelingCommentEditFieldsForm /> : null}
        </Col32>

        <FuelingCommentEditActionForm />
      </Col48>
    </Col32>
  );
});

export default withControllers(ScreenControllerFuelingCommentEditScreen)(
  FuelingCommentEditScreen
);

const Col48 = styled.div`
  gap: 48px;
`;

const Col32 = styled.div`
  gap: 32px;
  height: 100%;
`;

const Col16 = styled.div`
  gap: 16px;
`;

const Title = styled.h2`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  svg {
    width: 24px;
    height: 24px;
  }

  .status {
    color: #fcfcfd;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    padding: 4px 12px;
    margin-left: 4px;
    border-radius: 4px;
  }

  .status1 {
    background: #ff9900;
  }

  .status2 {
    background: #417d24;
  }

  .status3 {
    background: #ef5d58;
  }

  + div {
    padding-bottom: 40px;
  }
`;

const Comment = styled.p`
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  color: #303b57;

  + img {
    width: fit-content;
    height: fit-content;
    max-height: 500px;
    max-width: 540px;
  }

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const Table = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  background: #f0f2f8;
  padding: 16px 20px;
  border-radius: 6px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ColTable = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  p {
    color: #303b57;
    font-weight: 400;
    line-height: 20px;

    &.change-value {
      text-decoration: line-through;
    }
  }

  p:first-child {
    font-size: 13px;
    opacity: 0.8;
  }
  p:last-child {
    font-size: 15px;
  }
`;

const FlexWrapper = styled.form`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;

  .custom-field {
    width: calc(25% - 12px) !important;
    min-width: 250px;
    display: flex;
    flex-direction: column;
    gap: 6px;

    label + input {
      height: 40px;
      width: 100%;
    }

    .btn-group,
    .btn-group-vertical {
      display: inline-flex;
      position: relative;
      vertical-align: middle;
    }

    .btn-group {
      border-radius: 6px;
    }
    .bs-example .btn-group,
    .d-inline-flex {
      display: inline-flex !important;
    }

    .btn-check {
      clip: rect(0, 0, 0, 0);
      pointer-events: none;
      position: absolute;
    }

    color: #303b57;

    .btn-check:checked + .btn,
    .btn.active,
    .btn.show,
    .btn:first-child:active,
    :not(.btn-check) + .btn:active {
      background: #dbdce1;
      border-color: #dbdce1;
    }

    .btn {
      flex: 1 1 auto;
    }
  }

  .custom-select {
    width: 100%;
  }
`;
