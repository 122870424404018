import { I18n } from "i18n-js";
import common from "@assets/locales/common.json";

export const i18n = new I18n(
  /** Inject default languages */
  common.languages.reduce((acc: any, language) => {
    acc[language.code] =
      common.trans[language.code as keyof typeof common.trans];
    return acc;
  }, {})
);
