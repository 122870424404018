import { FuelingType } from "@constants/fueling";
import { IScreenControllerFuelingCommentScreen } from "@screens/FuelingCommentScreen/connector/__types__/UIViewController";
import { TranslationService } from "@services/translate";
import { useControllers } from "@view/hooks/useControllers";
import { useWindowWidth } from "@view/hooks/useWindowWidth";
import { observer } from "mobx-react";
import React from "react";
import styled from "styled-components";

const FuelingTypeMap = {
  [FuelingType.Unknown]: "Неизвеcтно",
  [FuelingType.General]: "Основная",
  [FuelingType.Transfer]: "Перегон",
};

export const FuelingCommentPreview = observer((): JSX.Element => {
  const { UIViewController } = useControllers<{
    UIViewController: IScreenControllerFuelingCommentScreen;
  }>();

  const windowWidth = useWindowWidth();

  const list = [
    [
      TranslationService.t("txt_col_bort"),
      UIViewController.state.fuelingRequest.vehicle?.number
        ? UIViewController.state.fuelingRequest.vehicle.number
        : "",
    ],
    [
      TranslationService.t("txt_col_requirement"),
      UIViewController.state.fuelingRequest.number,
    ],
    [
      TranslationService.t("txt_col_weight"),
      UIViewController.state.fuelingRequest.weight,
    ],
    [
      TranslationService.t("txt_col_volume"),
      UIViewController.state.fuelingRequest.volume,
    ],
    [
      TranslationService.t("txt_col_fueling_type"),
      FuelingTypeMap[UIViewController.state.fuelingRequest.fuelingType],
    ],
    [
      TranslationService.t("txt_col_region"),
      UIViewController.state.fuelingRequest.region?.name || "",
    ],
    [
      TranslationService.t("txt_col_supplier"),
      UIViewController.state.fuelingRequest.contractor?.name || "",
    ],
  ];

  const listMobile = [
    [
      [
        TranslationService.t("txt_col_bort"),
        UIViewController.state.fuelingRequest.vehicle?.number
          ? UIViewController.state.fuelingRequest.vehicle.number
          : "",
      ],
    ],
    [
      [
        TranslationService.t("txt_col_requirement"),
        UIViewController.state.fuelingRequest.number,
      ],
    ],
    [
      [
        TranslationService.t("txt_col_region"),
        UIViewController.state.fuelingRequest.region?.name || "",
      ],
    ],
    [
      [
        TranslationService.t("txt_col_weight"),
        UIViewController.state.fuelingRequest.weight,
      ],
      [
        TranslationService.t("txt_col_volume"),
        UIViewController.state.fuelingRequest.volume,
      ],
    ],
    [
      [
        TranslationService.t("txt_col_fueling_type"),
        FuelingTypeMap[UIViewController.state.fuelingRequest.fuelingType],
      ],
      [
        TranslationService.t("txt_col_supplier"),
        UIViewController.state.fuelingRequest.contractor?.name || "",
      ],
    ],
  ];

  return (
    <Col16 className="col">
      <p>Требование</p>
      <Table>
        {windowWidth > 1024 ? (
          <>
            {list.map((item, index) => (
              <ColTable key={index}>
                <p>{item[0]}</p>
                <p>{item[1]}</p>
              </ColTable>
            ))}
          </>
        ) : (
          <>
            {listMobile?.map((item, index) => (
              <div key={item[0][0] + index} className="row">
                {item?.map((el, ind) => (
                  <ColTable key={el[1] + ind} className="w-50">
                    <p>{el[0]}</p>
                    <p>{el[1]}</p>
                  </ColTable>
                ))}
              </div>
            ))}
          </>
        )}
      </Table>
    </Col16>
  );
});

const Col16 = styled.div`
  gap: 16px;
`;

const Table = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  background: #f0f2f8;
  padding: 16px 20px;
  border-radius: 6px;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const ColTable = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  p {
    color: #303b57;
    font-weight: 400;
    line-height: 20px;

    &.change-value {
      text-decoration: line-through;
    }
  }

  p:first-child {
    font-size: 13px;
    opacity: 0.8;
  }
  p:last-child {
    font-size: 15px;
  }
`;
