import { IScreenControllerReportByVS } from "./__types__/UIViewController";
import { IReactionDisposer } from "mobx";
import { UILogicController } from "@view/providers/__proto__/withController";
import { IRootTreeModel } from "@models/RootTreeModel";
import { toast } from "react-toastify";
import { getMessageFromError } from "@modules/utils/getMessageFromError";
import { IFuelingListItemEntity } from "@entities/FuelingListItemEntity";
import { RequestStatus } from "@constants/repositories";
import { TranslationService } from "@services/translate";
import { SceneControllerContractsFilters } from "@scenes/FiltersScene";
import { SceneControllerFuelingRequest } from "@scenes/FuelingRequestScene";
import { SceneControllerFuelingCommentRequest } from "@scenes/FuelingRequestCommentScene";
import {
  IFuelingListFilters,
  IFuelingListViewModel,
  TFuelingFieldsOrder,
  TFuelingTotals,
} from "@viewModels/FuelingListViewModel/__types__/IFuelingListViewModel";
import { FuelingListViewModel } from "@viewModels/FuelingListViewModel/FuelingListViewModel";
import { IPagination } from "@viewModels/pagination/types";
import { FuelingRequestViewModel } from "@viewModels/FuelingRequestViewModel/FuelingRequestViewModel";
import {
  IFuelingRequestState,
  IFuelingRequestViewModel,
} from "@viewModels/FuelingRequestViewModel/__types__/IFuelingRequestViewModel";
import { ScenesViewModel } from "@viewModels/scenes/ScenesViewModel";
import { IFiltersViewModel } from "@viewModels/FiltersViewModel/__types__/IFiltersViewModel";
import { FiltersViewModel } from "@viewModels/FiltersViewModel/FiltersViewModel";
import { ErrorScene, ScenesErrors } from "@viewModels/scenes/ErrorScene";
import {
  DateFilerType,
  IDatePeriodFilterViewModelParams,
} from "@viewModels/DatePeriodFilterViewModel/__types__/IDatePeriodFilterViewModel.types";
import { IVehiclesListViewModel } from "@viewModels/VehiclesListViewModel/__types__/IVehiclesListViewModel";
import { VehiclesListViewModel } from "@viewModels/VehiclesListViewModel/VehiclesListViewModel";
import { IVehicleListItemEntity } from "@entities/VehicleListItemEntity";
import { Utils } from "@modules/utils";

class ScreenControllerReportByVS
  extends UILogicController
  implements IScreenControllerReportByVS
{
  public disposers: { [key: string]: IReactionDisposer } = {};

  private FuelingListViewModel: IFuelingListViewModel;

  private VehiclesListViewModel: IVehiclesListViewModel;

  private FuelingRequestViewModel: IFuelingRequestViewModel;

  private FiltersViewModel: IFiltersViewModel;

  public constructor(model: IRootTreeModel) {
    super(model);

    this.FuelingListViewModel = new FuelingListViewModel(model, {
      dateType: DateFilerType.Month,
      search: "",
    });

    this.FuelingRequestViewModel = new FuelingRequestViewModel(model);

    this.VehiclesListViewModel = new VehiclesListViewModel(model);

    this.FiltersViewModel = new FiltersViewModel(model, {});
  }

  /** Indicator for otp request confirmation or resending otp request */
  public get isRequestInProgress(): boolean {
    return (
      this.FuelingListViewModel.statuses.getStatus("fetchItems") ===
        RequestStatus.Pending ||
      this.FuelingListViewModel.statuses.getStatus("fetchItemsTotals") ===
        RequestStatus.Pending
    );
  }

  public get isVehiclesLoading(): boolean {
    return (
      this.VehiclesListViewModel.statuses.getStatus("fetchItems") ===
      RequestStatus.Pending
    );
  }

  public get totalNumberOfFueling(): number {
    return this.FuelingListViewModel.meta.total;
  }

  public get listOfFuelingMetadata(): IPagination<IFuelingListItemEntity> {
    return this.FuelingListViewModel.metadata;
  }

  public get listOfVehiclesMetadata(): IPagination<IVehicleListItemEntity> {
    return this.VehiclesListViewModel.metadata;
  }

  public get listOfFuelingFilters(): IFuelingListFilters {
    return this.FuelingListViewModel.filters;
  }

  public get listOfFuelingTotals(): TFuelingTotals {
    return this.FuelingListViewModel.fuelingTotals;
  }

  public get createFuelingState(): IFuelingRequestState {
    return this.FuelingRequestViewModel.state;
  }

  public get filterCount(): number {
    return this.FiltersViewModel.state.filterCount;
  }

  public setFilterCount(count: number): void {
    try {
      this.FiltersViewModel.setFilterCount(count);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  }

  public setFieldOrderBy = (
    field: TFuelingFieldsOrder,
    direction: "asc" | "desc"
  ): void => {
    try {
      this.FuelingListViewModel.setFieldOrder(field, direction);

      this.fetchListOfFueling(1, true);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setDateFilter = (params: IDatePeriodFilterViewModelParams): void => {
    try {
      this.FuelingListViewModel.setDateFilter(params);

      this.fetchListOfFueling(1, true);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setVehicle = (
    data: { id: number; number: string; name: string } | null
  ): void => {
    try {
      this.FuelingListViewModel.setVehicle(data);

      this.fetchListOfFueling(1, true);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setVehicleSearch = (data: string): void => {
    try {
      const previousSearchState = this.VehiclesListViewModel.filters.search;

      this.VehiclesListViewModel.setSearch(data);

      if (data !== previousSearchState) {
        this.VehiclesListViewModel.fetchItemsBatchDebounce(1, true);
      }
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public fetchListOfFueling = async (
    page: number = this.FuelingListViewModel.meta.current_page + 1,
    refreshing: boolean = false
  ): Promise<void> => {
    try {
      await Promise.all([
        this.FuelingListViewModel.fetchItemsBatch(
          page,
          refreshing,
          !this.FuelingListViewModel.filters.vehicle
        ),

        this.FuelingListViewModel.fetchItemsTotals(
          !this.FuelingListViewModel.filters.vehicle
        ),
      ]);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public fetchListOfVehicles = async (): Promise<void> => {
    try {
      await this.VehiclesListViewModel.fetchItemsBatch(
        this.VehiclesListViewModel.meta.current_page + 1,
        false
      );
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public openCreateFuelingScene = async (): Promise<void> => {
    try {
      await ScenesViewModel.openRegisteredScene(SceneControllerFuelingRequest, {
        model: this.model,
      });

      // Notify user about success action
      toast.success(TranslationService.t("txt_success_fueling_created"));

      await this.fetchListOfFueling(1, true);
    } catch (error) {
      if (error instanceof ErrorScene) {
        if (error.cause === ScenesErrors.UserClosedScene) {
          return;
        }
      }

      toast.error(getMessageFromError(error));
    }
  };

  public resetFilters = async (): Promise<void> => {
    try {
      this.FuelingListViewModel.setContract(null);
      this.FuelingListViewModel.setRegion(null);
      this.FuelingListViewModel.setPilot(null);
      this.FuelingListViewModel.setVehicle(null);
      // this.FuelingListViewModel.setFuelingType(result.fuelingType);
      this.FuelingListViewModel.setFuelingNumber(null);
      this.FuelingListViewModel.setContractor(null);
      this.setFilterCount(0);

      await this.fetchListOfFueling(1, true);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public openFuelingFiltersScene = async (): Promise<void> => {
    try {
      const result = await ScenesViewModel.openRegisteredScene(
        SceneControllerContractsFilters,
        {
          contract: this.FuelingListViewModel.filters.contract,
          contractor: this.FuelingListViewModel.filters.contractor,
          fields: {
            contract: true,
            contractor: true,
            fuelingNumber: true,
            // fuelingType: true,
            pilot: true,
            region: true,
            // vehicle: true,
          },
          fuelingNumber: this.FuelingListViewModel.filters.fuelingNumber,
          // fuelingType: this.FuelingListViewModel.filters.fuelingType,
          model: this.model,
          pilot: this.FuelingListViewModel.filters.pilot || undefined,
          region: this.FuelingListViewModel.filters.region || undefined,
          // vehicle: this.FuelingListViewModel.filters.vehicle,
        }
      );

      this.FuelingListViewModel.setContract(result.contract);
      this.FuelingListViewModel.setRegion(result.region);
      this.FuelingListViewModel.setPilot(result.pilot);
      // this.FuelingListViewModel.setVehicle(result.vehicle);
      // this.FuelingListViewModel.setFuelingType(result.fuelingType);
      this.FuelingListViewModel.setFuelingNumber(result.fuelingNumber);
      this.FuelingListViewModel.setContractor(result.contractor);
      this.setFilterCount(result.filterCount);

      await this.fetchListOfFueling(1, true);
    } catch (error) {
      if (error instanceof ErrorScene) {
        if (error.cause === ScenesErrors.UserClosedScene) {
          return;
        }
      }

      toast.error(getMessageFromError(error));
    }
  };

  public openFuelingCommentScene = async (fuelingId: number): Promise<void> => {
    try {
      await ScenesViewModel.openRegisteredScene(
        SceneControllerFuelingCommentRequest,
        {
          fuelingRequest: fuelingId,
          model: this.model,
        }
      );

      toast.success(
        TranslationService.t("txt_success_fueling_comment_created")
      );
    } catch (error) {
      if (error instanceof ErrorScene) {
        if (error.cause === ScenesErrors.UserClosedScene) {
          return;
        }
      }

      toast.error(getMessageFromError(error));
    }
  };

  public downloadExcelReport = async (): Promise<void> => {
    try {
      const blob = await this.FuelingListViewModel.fetchItemsDocument(
        "xls",
        "vehicle",
        !this.FuelingListViewModel.filters.vehicle
      );

      if (!blob) return;

      Utils.downloadFile(blob, "xls");
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public downloadPDFReport = async (): Promise<void> => {
    try {
      const blob = await this.FuelingListViewModel.fetchItemsDocument(
        "pdf",
        "vehicle",
        !this.FuelingListViewModel.filters.vehicle
      );

      if (!blob) return;

      Utils.downloadFile(blob, "pdf");
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public downloadPrintReport = async (): Promise<void> => {
    try {
      window.print();
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public beforeDestroy = (): void => {
    this.FuelingListViewModel.beforeDestroy();
    this.FuelingRequestViewModel.beforeDestroy();
    this.FiltersViewModel.beforeDestroy();
    /** Cancel all disposers */
    Object.values(this.disposers).map((func) => func.call(undefined));
  };
}

export { ScreenControllerReportByVS };
