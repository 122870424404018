import { IScreenControllerAuth } from "./__types__/UIViewController";
import { IReactionDisposer } from "mobx";
import { UILogicController } from "@view/providers/__proto__/withController";
import { IRootTreeModel } from "@models/RootTreeModel";
import { toast } from "react-toastify";
import { Utils } from "@modules/utils";
import { IAuthViewModel } from "@viewModels/AuthViewModel/__types__/IAuthViewModel";
import { AuthViewModel } from "@viewModels/AuthViewModel/AuthViewModel";
import { IAuthResetViewModel } from "@viewModels/ResetPasswordViewModel/__types__/ResetPasswordViewModel";
import { AuthResetViewModel } from "@viewModels/ResetPasswordViewModel/ResetPasswordViewModel";

class ScreenControllerAuth
  extends UILogicController
  implements IScreenControllerAuth
{
  public disposers: { [key: string]: IReactionDisposer } = {};

  private AuthViewModel: IAuthViewModel;

  private AuthResetViewModel: IAuthResetViewModel;

  public constructor(model: IRootTreeModel) {
    super(model);

    this.AuthViewModel = new AuthViewModel(model);
    this.AuthResetViewModel = new AuthResetViewModel(model);
  }

  /** Indicator for otp request confirmation or resending otp request */
  public get isRequestInProgress(): boolean {
    return this.AuthViewModel.state.loading;
  }

  /** User email */
  public get email(): string {
    return this.AuthViewModel.state.email;
  }

  public get errorEmail(): string | null {
    return this.AuthViewModel.state.errorEmail;
  }

  public get page(): string {
    return this.AuthViewModel.state.page;
  }

  /** Current password */
  public get password(): string {
    return this.AuthViewModel.state.password;
  }

  public get plainPassword(): string {
    return this.AuthViewModel.state.plainPassword;
  }

  public get plainPasswordRepeat(): string {
    return this.AuthViewModel.state.plainPasswordRepeat;
  }

  public get changingEmail(): string {
    return this.AuthViewModel.state.changingEmail;
  }

  public get errors(): boolean {
    return Boolean(Object.keys(this.AuthViewModel.errors));
  }

  public setPage = (page: string): void => {
    this.AuthViewModel.setPage(page);
  };

  public setEmail = (email: string): void => {
    this.AuthViewModel.setEmail(email);
  };

  public setErrorEmail = (error: string | null): void => {
    this.AuthViewModel.setErrorEmail(error);
  };

  public setChangingEmail = (email: string): void => {
    this.AuthViewModel.setChangingEmail(email);
  };

  public setPassword = (password: string): void => {
    this.AuthViewModel.setPassword(password);
  };

  public setPlainPassword = (password: string): void => {
    this.AuthViewModel.setPlainPassword(password);
  };

  public setPlainPasswordRepeat = (password: string): void => {
    this.AuthViewModel.setPlainPasswordRepeat(password);
  };

  public submitLoginForm = async (): Promise<void> => {
    try {
      await this.AuthViewModel.loginByEmail(
        this.AuthViewModel.state.email,
        this.AuthViewModel.state.password
      );
    } catch (error) {
      // toast.error(Utils.getMessageFromError(error));
    }
  };

  public submitResetEmail = async (email: string): Promise<void> => {
    try {
      await this.AuthViewModel.resetPassword(email);
    } catch (error) {
      toast.error(Utils.getMessageFromError(error));
    }
  };

  public submitPlainPassword = async (
    token: string,
    plainPassword: string
  ): Promise<void> => {
    try {
      await this.AuthResetViewModel.changePassword(token, plainPassword);
    } catch (error) {
      toast.error(Utils.getMessageFromError(error));
    }
  };

  public beforeDestroy = (): void => {
    this.AuthViewModel.beforeDestroy();
    /** Cancel all disposers */
    Object.values(this.disposers).map((func) => func.call(undefined));
  };
}

export { ScreenControllerAuth };
