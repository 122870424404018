type Props = {
  onClick?: () => void;
};

const IconCross = ({ onClick }: Props): JSX.Element => (
  <svg
    onClick={onClick}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.3535 1.64585C14.1534 1.44572 13.882 1.33301 13.5989 1.33301C13.3159 1.33301 13.0443 1.44572 12.8443 1.64585L7.99952 6.49066L3.15471 1.64585C2.8852 1.37634 2.4922 1.27107 2.12394 1.36965C1.75567 1.46841 1.46814 1.75596 1.36934 2.12425C1.27076 2.49252 1.37604 2.88551 1.64555 3.15501L6.49036 7.99983L1.64555 12.8446C1.37604 13.1142 1.27076 13.5072 1.36934 13.8754C1.4681 14.2437 1.75565 14.5312 2.12394 14.63C2.49221 14.7286 2.88521 14.6233 3.15471 14.3538L7.99952 9.50899L12.8443 14.3538C13.1138 14.6233 13.5068 14.7286 13.8751 14.63C14.2434 14.5312 14.5309 14.2437 14.6297 13.8754C14.7283 13.5071 14.623 13.1141 14.3535 12.8446L9.50868 7.99983L14.3535 3.15501C14.5536 2.95507 14.6663 2.68352 14.6663 2.40041C14.6663 2.11733 14.5536 1.84594 14.3535 1.64581L14.3535 1.64585Z"
      fill="#303B57"
    />
  </svg>
);

export default IconCross;
