/* eslint-disable sort-keys/sort-keys-fix */
import { IScreenControllerFuelingComments } from "@screens/FuelingCommentsScreen/connector/__types__/UIViewController";
import TableOutput from "@shared/controls/TableOutput/TableOutput";
import { useControllers } from "@view/hooks/useControllers";
import { observer } from "mobx-react";
import moment from "moment";
import { useEffect } from "react";
import { UserRoles } from "@constants/roles";

// import moment from "moment";
// import React from "react";

const headerListGsm = {
  id: ["Номер", true],
  updatedAt: ["Дата", true],
  comment_text: ["Комментарий"],
  file: ["Фото"],
  document: ["Документ"],
  status: ["Статус", true],
};

const headerListPilot = {
  id: ["Номер", true],
  updatedAt: ["Дата", true],
  comment_text: ["Комментарий"],
  file: ["Фото"],
  status: ["Статус", true],
};

export const CommentsTable = observer((): JSX.Element => {
  const { UIViewController } = useControllers<{
    UIViewController: IScreenControllerFuelingComments;
  }>();

  useEffect(() => {
    UIViewController.fetchListOfFuelingComments();
    UIViewController.setFieldOrderBy("updatedAt", "desc");
  }, []);

  return (
    <TableOutput
      sortBy={UIViewController.listOfFuelingCommentsFilters.order}
      sortDir={UIViewController.listOfFuelingCommentsFilters.direction}
      onChangeOrder={UIViewController.setFieldOrderBy}
      headerObj={
        UIViewController.userinfo?.roles.includes(UserRoles.EmployeeGSM)
          ? headerListGsm
          : headerListPilot
      }
      list={UIViewController.listOfFuelingCommentsMetadata.data.map(
        (comment) => ({
          comment_text: comment.comment,
          updatedAt: moment(comment.fuelingRequest?.updatedAt).format(
            "DD.MM.YY"
          ),
          file: comment.file,
          id: comment.id,
          status: [
            UIViewController.userinfo?.roles.includes(UserRoles.EmployeeGSM)
              ? 0
              : 1,
            comment.status,
          ],
          document: [
            comment.fuelingRequest?.number,
            moment(comment.fuelingRequest?.createdAt).format("DD.MM.YY"),
          ],
        })
      )}
    />
  );
});
