const IconUpload = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
  >
    <path
      d="M2.52067 11.951C1.95729 11.4261 1.69292 10.7225 1.2134 9.44473C1.14547 9.26345 1.15234 9.06261 1.23252 8.88639C1.31271 8.71018 1.45962 8.57302 1.64095 8.5051C1.82229 8.43718 2.02318 8.44405 2.19944 8.52421C2.3757 8.60437 2.51289 8.75125 2.58083 8.93254C2.99471 10.0371 3.20256 10.5931 3.51615 10.8847C3.64488 11.0048 3.79045 11.1055 3.94826 11.1837C4.33114 11.375 4.92552 11.375 6.10697 11.375H7.89739C9.07885 11.375 9.67323 11.375 10.0561 11.1837C10.2139 11.1055 10.3595 11.0048 10.4882 10.8847C10.8018 10.5931 11.0097 10.0371 11.4235 8.93254C11.4925 8.75292 11.6297 8.60785 11.8052 8.52894C11.9808 8.45003 12.1804 8.44367 12.3605 8.51125C12.5407 8.57883 12.6869 8.71488 12.7672 8.88973C12.8474 9.06459 12.8553 9.26408 12.7891 9.44473C12.3115 10.7207 12.0471 11.4261 11.4837 11.951C11.2518 12.1697 10.9896 12.3539 10.7052 12.4979C10.016 12.8333 9.263 12.8333 7.89739 12.8333H6.10697C4.74137 12.8333 3.98837 12.8333 3.29919 12.4906C3.01523 12.3488 2.75306 12.1671 2.52067 11.951Z"
      fill="#303B57"
    />
    <path
      d="M7.73148 8.64089C7.73148 8.83427 7.65464 9.01972 7.51788 9.15645C7.38111 9.29318 7.19561 9.37 7.00219 9.37C6.80876 9.37 6.62327 9.29318 6.4865 9.15645C6.34973 9.01972 6.27289 8.83427 6.27289 8.64089L6.27289 3.11063L4.63198 4.75112C4.51004 4.87885 4.34733 4.96004 4.17192 4.98067C3.99651 5.0013 3.8194 4.96008 3.67113 4.86413C3.58324 4.80332 3.50979 4.72395 3.45598 4.63162C3.40217 4.53929 3.36932 4.43626 3.35976 4.32983C3.35019 4.2234 3.36414 4.11617 3.40062 4.01573C3.4371 3.91529 3.49521 3.82409 3.57085 3.7486L5.52354 1.79642C5.73517 1.58014 5.99069 1.41171 6.27289 1.30245C6.64355 1.16061 7.04735 1.12902 7.43556 1.21149C7.82378 1.29397 8.17984 1.48698 8.46078 1.76725L10.4353 3.75042C10.5124 3.82723 10.5713 3.9203 10.6077 4.02281C10.6441 4.12533 10.6571 4.23468 10.6457 4.34287C10.6343 4.45106 10.5989 4.55533 10.542 4.64805C10.4851 4.74077 10.4082 4.81958 10.3168 4.87871C10.173 4.96889 10.0026 5.00705 9.83407 4.98681C9.66552 4.96658 9.509 4.88918 9.39063 4.76752L7.73148 3.11063L7.73148 8.64089Z"
      fill="#303B57"
    />
  </svg>
);

export default IconUpload;
