import { IRouterController } from "./__types__/UIViewController";
import { IReactionDisposer } from "mobx";
import { UILogicController } from "@view/providers/__proto__/withController";
import { IRootTreeModel } from "@models/RootTreeModel";
import { IUserEntity } from "@entities/UserEntity";
import { ISidebarViewModel } from "@viewModels/SidebarViewModel/__types__/SidebarViewModel.types";
import { SidebarViewModel } from "@viewModels/SidebarViewModel/SidebarViewModel";
import { AuthViewModel } from "@viewModels/AuthViewModel/AuthViewModel";
import { IAuthViewModel } from "@viewModels/AuthViewModel/__types__/IAuthViewModel";
import { AxiosRequestClient } from "@modules/request";
import { RequestServiceViewModel } from "@viewModels/RequestServiceViewModel/RequestServiceViewModel";
import { IRequestServiceViewModel } from "@viewModels/RequestServiceViewModel/__types__/IRequestServiceViewModel";

class RouterController extends UILogicController implements IRouterController {
  public disposers: { [key: string]: IReactionDisposer } = {};

  public SidebarViewModel: ISidebarViewModel;

  public AuthViewModel: IAuthViewModel;

  public RequestServiceViewModel: IRequestServiceViewModel;

  public constructor(model: IRootTreeModel) {
    super(model);

    this.SidebarViewModel = new SidebarViewModel(this.model);

    this.AuthViewModel = new AuthViewModel(this.model);

    this.RequestServiceViewModel = new RequestServiceViewModel({
      model: this.model,
    });

    AxiosRequestClient.setGlobalInterceptorsListeners({
      handleError: this.RequestServiceViewModel.onResponseError,
      handleRequest: this.RequestServiceViewModel.onRequest,
      handleResponse: this.RequestServiceViewModel.onResponse,
    });
  }

  public get userinfo(): IUserEntity | null {
    return this.model.user.user;
  }

  public get isUserLoggedIn(): boolean {
    return Boolean(this.model.user.isLogin);
  }

  public get isOpenedSideMenu(): boolean {
    return this.SidebarViewModel.isOpenedSideMenu;
  }

  public closeSideMenu = (): void => this.SidebarViewModel.closeSideMenu();

  public openSideMenu = (): void => this.SidebarViewModel.openSideMenu();

  public toggleSideMenu = (): void => this.SidebarViewModel.toggleSideMenu();

  public beforeDestroy = (): void => {
    /** Cancel all disposers */
    Object.values(this.disposers).map((func) => func.call(undefined));

    this.SidebarViewModel.beforeDestroy();
  };
}

export { RouterController };
