import { IScreenControllerAuth } from "@screens/AuthorizationScreen/connector/__types__/UIViewController";
import { TranslationService } from "@services/translate";
import { IconCheck } from "@shared/controls/icons";
import { useControllers } from "@view/hooks/useControllers";
import { observer } from "mobx-react";
import { useEffect } from "react";
import styled from "styled-components";

export const ChangingPassword = observer((): JSX.Element => {
  const { UIViewController } = useControllers<{
    UIViewController: IScreenControllerAuth;
  }>();
  useEffect(() => {
    setTimeout(() => {
      UIViewController.setPage("main");
    }, 3000);
  }, []);

  return (
    <CustomForm>
      <div className="container col align-center">
        <SuccessIcon>
          <IconCheck />
        </SuccessIcon>
        <div className="col align-center gap10">
          <h2>{TranslationService.t("txt_success_changing_password")}</h2>
        </div>
      </div>
    </CustomForm>
  );
});

const CustomForm = styled.form`
  @media (min-width: 768.1px) {
    padding: 139px 60px !important;
  }
`;

export const SuccessIcon = styled.div`
  background: #303b57;
  width: 184px;
  height: 178px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 100px;
    height: 100px;
    path {
      fill: #fcfcfd;
    }
  }

  @media (max-width: 768px) {
    width: 178px;
    height: 178px;

    svg {
      width: 94px;
      height: 94px;
    }
  }
`;
