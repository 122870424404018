import logoIcon from "@assets/images/logoIcon.svg";
import logo from "@assets/images/logo.svg";
import artu from "@assets/images/artu.svg";
import errorImg from "@assets/images/errorImg.webp";
import "../AuthorizationScreen/styles.scss";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { TranslationService } from "@services/translate";
import { Helmet } from "react-helmet";

const ErrorScreen = (): JSX.Element => (
  <Wrapper className="wrapper-auth">
    <Helmet>
      <title>{TranslationService.t("txt_title_error")}</title>
    </Helmet>
    <div className="container col h-100 justify-between">
      <div className="row justify-center">
        <div className="colLogo col justify-center">
          <div className="row">
            <img src={logoIcon} />
            <img src={logo} />
          </div>
        </div>
      </div>
      <form>
        <div className="container col align-center gap20">
          <ImageError src={errorImg} />
          <div className="col align-center gap20">
            <Error404>{TranslationService.t("txt_name_error")}</Error404>
            <div className="col align-center gap14">
              <Title>{TranslationService.t("txt_title_error")}</Title>
              <Hint>{TranslationService.t("txt_hint_error")}</Hint>
            </div>
            <PrimaryLink to="/">
              {TranslationService.t("txt_to_main")}
            </PrimaryLink>
          </div>
        </div>
      </form>
      <div className="row justify-center">
        <p>{TranslationService.t("txt_developed_by")}</p>
        <img src={artu} />
      </div>
    </div>
  </Wrapper>
);

export default ErrorScreen;

const Wrapper = styled.div`
  position: fixed;
  z-index: 999999;
  left: 0;
  top: 0;
`;

const ImageError = styled.img`
  width: 100%;

  @media (max-width: 768px) {
    max-width: 150px;
  }
`;

const Error404 = styled.div`
  background: #dbdce1;
  padding: 8px 10px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  color: #fcfcfd;
  width: fit-content;

  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 16px;
  }
`;

const Title = styled.h2`
  font-size: 36px;
  font-weight: 700;
  line-height: 42px;

  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 30px;
  }
`;

const Hint = styled.p`
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 18px;
  }
`;

const PrimaryLink = styled(Link)`
  background: #ef5d58;
  border-radius: 6px;
  height: 40px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #fcfcfd;
  padding: 0 16px;
`;
