import { useWindowWidth } from "@view/hooks/useWindowWidth";
import "./styles.scss";
import { IconArrowLeftLong, IconCross } from "@shared/controls/icons";
import { PropsWithChildren } from "react";
import { TranslationService } from "@services/translate";

export type TFilterComponentProps = {
  title: string;
  onClose?: () => void;
  class?: string;
};

const FilterComponent = (
  props: PropsWithChildren<TFilterComponentProps>
): JSX.Element => {
  const windowWidth = useWindowWidth();

  return (
    <div className="side-form">
      <div className="form-header" onClick={props.onClose}>
        {props.class === "dropzone" ||
        (props.class !== "dropzone" && windowWidth > 768) ? (
          <IconCross onClick={props.onClose} />
        ) : (
          <div className="form-close" onClick={props.onClose}>
            <IconArrowLeftLong />
            <span>{TranslationService.t("txt_back")}</span>
          </div>
        )}
      </div>

      <div className="col w-100">
        <h3>{props.title}</h3>
        <form action="" className={`filter-form ${props.class}`}>
          {props.children}
        </form>
      </div>
    </div>
  );
};

export default FilterComponent;
