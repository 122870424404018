const IconApplication = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M18.2501 4.95153L14.394 4.95153L12.6253 3.21707C12.393 2.98949 12.1172 2.80902 11.8137 2.68598C11.5103 2.56294 11.185 2.49975 10.8566 2.5L5.75056 2.5C4.75604 2.5 3.80224 2.88743 3.09901 3.57705C2.39577 4.26668 2.00069 5.20201 2.00069 6.17729L2.00069 10.161C2.00069 10.4861 2.13239 10.7979 2.3668 11.0278C2.60121 11.2576 2.91914 11.3868 3.25065 11.3868C3.58216 11.3868 3.90009 11.2576 4.1345 11.0278C4.36892 10.7979 4.50061 10.4861 4.50061 10.161L4.50061 6.17729C4.50061 5.8522 4.6323 5.54042 4.86671 5.31054C5.10112 5.08067 5.41906 4.95153 5.75056 4.95153L10.8566 4.95153L12.6253 6.68598C12.8577 6.91357 13.1334 7.09403 13.4369 7.21707C13.7404 7.34011 14.0656 7.4033 14.394 7.40305L18.2501 7.40305C18.5816 7.40305 18.8996 7.53219 19.134 7.76207C19.3684 7.99194 19.5001 8.30372 19.5001 8.62881V17.822C19.5001 18.1471 19.3684 18.4589 19.134 18.6888C18.8996 18.9187 18.5816 19.0478 18.2501 19.0478H13.5628C13.2313 19.0478 12.9134 19.1769 12.6789 19.4068C12.4445 19.6367 12.3128 19.9485 12.3128 20.2736C12.3128 20.5986 12.4445 20.9104 12.6789 21.1403C12.9134 21.3702 13.2313 21.4993 13.5628 21.4993H18.2501C19.2447 21.4993 20.1985 21.1119 20.9017 20.4223C21.6049 19.7326 22 18.7973 22 17.822V8.62881C22 7.65354 21.6049 6.7182 20.9017 6.02858C20.1985 5.33895 19.2447 4.95153 18.2501 4.95153Z"
      fill="#303B57"
    />
    <path
      d="M7.31301 13.8383L3.25065 13.8383C2.91914 13.8383 2.60121 13.9674 2.3668 14.1973C2.13239 14.4272 2.00069 14.739 2.00069 15.0641C2.00069 15.3892 2.13239 15.7009 2.3668 15.9308C2.60121 16.1607 2.91914 16.2898 3.25065 16.2898H5.54432L2.36631 19.4063C2.13176 19.6363 2 19.9483 2 20.2736C2 20.5988 2.13176 20.9108 2.36631 21.1408C2.60085 21.3708 2.91896 21.5 3.25065 21.5C3.58234 21.5 3.90045 21.3708 4.135 21.1408L7.31301 18.0243V20.2736C7.31301 20.5986 7.4447 20.9104 7.67911 21.1403C7.91353 21.3702 8.23146 21.4993 8.56297 21.4993C8.89448 21.4993 9.21241 21.3702 9.44682 21.1403C9.68123 20.9104 9.81292 20.5986 9.81292 20.2736L9.81292 16.2898C9.81292 15.6396 9.54954 15.0161 9.08072 14.5563C8.61189 14.0966 7.97603 13.8383 7.31301 13.8383Z"
      fill="#303B57"
    />
  </svg>
);

export default IconApplication;
