import { SuccessIcon } from "../../../NewPasswordScreen/components/ChangingPassword/ChangingPassword";
import { Back } from "../ForgetPassword/ForgetPassword";
import { IScreenControllerAuth } from "@screens/AuthorizationScreen/connector/__types__/UIViewController";
import { TranslationService } from "@services/translate";
import { IconArrowLeftLong, IconCheck } from "@shared/controls/icons";
import { useControllers } from "@view/hooks/useControllers";
import styled from "styled-components";

export const SendingPassword = (): JSX.Element => {
  const { UIViewController } = useControllers<{
    UIViewController: IScreenControllerAuth;
  }>();

  return (
    <form>
      <Back className="row" onClick={() => UIViewController.setPage("main")}>
        <IconArrowLeftLong />
        <span>{TranslationService.t("txt_back")}</span>
      </Back>
      <div className="container col align-center">
        <SuccessIcon>
          <IconCheck />
        </SuccessIcon>
        <div className="col align-center gap10">
          <h2>{TranslationService.t("txt_sending_changing_password")}</h2>
          <Email>{UIViewController.changingEmail}</Email>
        </div>
      </div>
    </form>
  );
};

const Email = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
`;
