import { IScreenControllerFuelingCreate } from "./__types__/UIViewController";
import { UILogicController } from "@view/providers/__proto__/withController";
import { toast } from "react-toastify";
import { getMessageFromError } from "@modules/utils/getMessageFromError";

import { IPagination } from "@viewModels/pagination/types";
import { FuelingRequestViewModel } from "@viewModels/FuelingRequestViewModel/FuelingRequestViewModel";
import {
  IFuelingRequestState,
  IFuelingRequestViewModel,
} from "@viewModels/FuelingRequestViewModel/__types__/IFuelingRequestViewModel";
import { Utils } from "@modules/utils";
import { IContractorListItemEntity } from "@entities/ContractorListItemEntity";
import { IRegionListItemEntity } from "@entities/RegionListItemEntity";
import { IVehicleListItemEntity } from "@entities/VehicleListItemEntity";
import { VehiclesListViewModel } from "@viewModels/VehiclesListViewModel/VehiclesListViewModel";
import { RegionsListViewModel } from "@viewModels/RegionsListViewModel/RegionsListViewModel";
import { ContractorsListViewModel } from "@viewModels/ContractorsListViewModel/ContractorsListViewModel";
import { IContractorsListViewModel } from "@viewModels/ContractorsListViewModel/__types__/IContractorsListViewModel";
import { IRegionsListViewModel } from "@viewModels/RegionsListViewModel/__types__/IRegionsListViewModel";
import { IVehiclesListViewModel } from "@viewModels/VehiclesListViewModel/__types__/IVehiclesListViewModel";
import { IRootTreeModel } from "@models/index";

class ScreenControllerFuelingCreate
  extends UILogicController
  implements IScreenControllerFuelingCreate
{
  private VehiclesListViewModel: IVehiclesListViewModel;

  private FuelingRequestViewModel: IFuelingRequestViewModel;

  private RegionsListViewModel: IRegionsListViewModel;

  private ContractorsListViewModel: IContractorsListViewModel;

  public constructor(model: IRootTreeModel) {
    super(model);

    this.VehiclesListViewModel = new VehiclesListViewModel(model);

    this.RegionsListViewModel = new RegionsListViewModel(model);

    this.ContractorsListViewModel = new ContractorsListViewModel(model);

    this.FuelingRequestViewModel = new FuelingRequestViewModel(model);
  }

  public get createFuelingState(): IFuelingRequestState {
    return this.FuelingRequestViewModel.state;
  }

  public get listOfVehiclesMetadata(): IPagination<IVehicleListItemEntity> {
    return this.VehiclesListViewModel.metadata;
  }

  public get listOfRegionsMetadata(): IPagination<IRegionListItemEntity> {
    return this.RegionsListViewModel.metadata;
  }

  public get listOfContractorsMetadata(): IPagination<IContractorListItemEntity> {
    return this.ContractorsListViewModel.metadata;
  }

  public get isContractorsLoading(): boolean {
    return this.ContractorsListViewModel.statuses.isInProgress;
  }

  public get isVehiclesLoading(): boolean {
    return this.VehiclesListViewModel.statuses.isInProgress;
  }

  public get isRegionsLoading(): boolean {
    return this.RegionsListViewModel.statuses.isInProgress;
  }

  public get isFuelingCreationInProgress(): boolean {
    return this.FuelingRequestViewModel.statuses.isInProgress;
  }

  public fetchListOfRegions = (
    page: number = this.RegionsListViewModel.meta.current_page + 1,
    refreshing: boolean = false
  ): void => {
    try {
      this.RegionsListViewModel.fetchItemsBatch(page, refreshing);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public fetchListOfVehicles = (
    page = this.VehiclesListViewModel.meta.current_page + 1,
    refreshing: boolean = false
  ): void => {
    try {
      this.VehiclesListViewModel.fetchItemsBatch(page, refreshing);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public fetchListOfContractors = (
    page = this.ContractorsListViewModel.meta.current_page + 1,
    refreshing: boolean = false
  ): void => {
    try {
      this.ContractorsListViewModel.fetchItemsBatch(page, refreshing);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setFuelingNumber = (number: string | null): void => {
    try {
      this.FuelingRequestViewModel.setFuelingNumber(number);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setVehicle = (
    vehicle: { id: number; number: string } | null
  ): void => {
    try {
      this.FuelingRequestViewModel.setVehicle(vehicle);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setContractor = async (contractor: {
    id: number;
    name: string;
  }): Promise<void> => {
    try {
      // const previousContractorId =
      //   this.FuelingRequestViewModel.state.contractor?.id;
      // clear regions if contractor is different

      this.FuelingRequestViewModel.setContractor(contractor);

      // if (contractor.id !== previousContractorId) {
      //   // TODO: clear region and show empty field and after that need display error message that region is not match to contractor
      //   this.FuelingRequestViewModel.setRegion(null);

      //   this.RegionsListViewModel.setContractorFilter(contractor);

      //   await this.fetchListOfRegions(1, true);
      // }
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setWeight = (weight: number): void => {
    try {
      this.FuelingRequestViewModel.setWeight(weight);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setVolume = (volume: number): void => {
    try {
      this.FuelingRequestViewModel.setVolume(volume);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setFuelingType = (type: number): void => {
    try {
      this.FuelingRequestViewModel.setFuelingType(type);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setVehicleSearch = (contract: string): void => {
    try {
      const previousSearchState = this.VehiclesListViewModel.filters.search;

      this.VehiclesListViewModel.setSearch(contract);

      if (contract !== previousSearchState) {
        this.VehiclesListViewModel.fetchItemsBatchDebounce(1, true);
      }
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setRegion = (region: { id: number; name: string } | null): void => {
    try {
      const previousContractorId =
        this.FuelingRequestViewModel.state.contractor?.id;
      // clear regions if contractor is different

      this.FuelingRequestViewModel.setRegion(region);

      if (region?.id !== previousContractorId) {
        // TODO: clear region and show empty field and after that need display error message that region is not match to origin
        this.FuelingRequestViewModel.setContractor(null);

        this.ContractorsListViewModel.setRegion(region);

        if (region) {
          this.fetchListOfContractors(1, true);
        }
      }
    } catch (error) {
      toast.error(getMessageFromError(error));
    }

    try {
      this.FuelingRequestViewModel.setRegion(region);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setRegionSearch = (region: string): void => {
    try {
      const previousSearchState = this.RegionsListViewModel.filters.search;

      this.RegionsListViewModel.setSearch(region);

      if (region !== previousSearchState) {
        this.RegionsListViewModel.fetchItemsBatchDebounce(1, true);
      }
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setContractorSearch = (contractor: string): void => {
    try {
      const previousSearchState = this.ContractorsListViewModel.filters.search;

      this.ContractorsListViewModel.setSearch(contractor);

      if (contractor !== previousSearchState) {
        this.ContractorsListViewModel.fetchItemsBatchDebounce(1, true);
      }
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public createFueling = async (): Promise<void> => {
    try {
      // Create contract
      await this.FuelingRequestViewModel.createFueling(
        this.FuelingRequestViewModel.state
      );
    } catch (error) {
      toast.error(getMessageFromError(error));

      throw error;
    }
  };

  public clearFueling = (): void => {
    try {
      this.FuelingRequestViewModel.clearState();
      this.RegionsListViewModel.clearList();
    } catch (error) {
      toast.error(Utils.getMessageFromError(error));
    }
  };

  public beforeDestroy = (): void => {
    this.clearFueling();
  };
}

export { ScreenControllerFuelingCreate };
