import ContractsFiltersForm from "./components/FiltersForm";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import "./styles.scss";

const FiltersScene = observer(() => {
  // const UISceneController =
  //   useScene<IUISceneController<TFiltersSceneResult, TFiltersSceneParams>>();

  useEffect(() => {
    // Load list of contractors on mount
    // if (UISceneController.fields.contractor)
    //   UISceneController.fetchListOfContractors();
    // if (UISceneController.fields.contract)
    //   UISceneController.fetchListOfContracts();
    // if (UISceneController.fields.vehicle)
    //   UISceneController.fetchListOfVehicles();
    // if (UISceneController.fields.region) UISceneController.fetchListOfRegions();
    // if (UISceneController.fields.pilot) UISceneController.fetchListOfPilots();
  }, []);

  return (
    <div className="contract-create-scene">
      <ContractsFiltersForm />
    </div>
  );
});

export default FiltersScene;
