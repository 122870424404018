import { IScreenControllerFueling } from "@screens/FuelingScreen/connector/__types__/UIViewController";
import { Sorting } from "@shared/controls/Sorting/Sorting";
import { useControllers } from "@view/hooks/useControllers";
import { observer } from "mobx-react";

export const SortingMobile = observer((): JSX.Element => {
  const { UIViewController } = useControllers<{
    UIViewController: IScreenControllerFueling;
  }>();

  return (
    <Sorting
      list={[
        ["По дате", "createdAt", "Сначала ранние", "Сначала поздние"],
        ["По весу топлива", "weight", "Сначала с меньшим", "Сначала с большим"],
        [
          "По объему топлива",
          "volume",
          "Сначала с меньшим",
          "Сначала с большим",
        ],
      ]}
      sortBy={UIViewController.listOfFuelingFilters.order}
      sortDir={UIViewController.listOfFuelingFilters.direction}
      onChangeOrder={UIViewController.setFieldOrderBy}
    />
  );
});
