import {
  IErrors,
  IProfileEditState,
  IProfileState,
  IProfileViewModel,
} from "./__types__/IProfileViewModel.types";
import { Statuses } from "../statuses";
import { IProfileRepository } from "../../repositories/profile/__types__/repository";
import { ProfileRepository } from "../../repositories/profile/repository";
import { IRootTreeModel } from "@models/RootTreeModel";

import { AxiosRequestClient } from "@modules/request/libs/axios";
import { observable } from "mobx";
import { RequestStatus } from "@constants/repositories";
import _ from "lodash";
import { TranslationService } from "@services/translate";
import { isAxiosError } from "axios";

/**
 * Represents a view model for the profile page.
 */
export class ProfileViewModel implements IProfileViewModel {
  /**
   * Represents the statuses for various operations in the view model.
   */
  public statuses: Statuses = new Statuses([
    "saveMainEditInfo",
    "saveSecureEditInfo",
    "saveImageEditInfo",
    "fetchProfile",
  ]);

  /**
   * Represents the current profile state.
   */
  public profileState: IProfileState;

  public profileErrors: IErrors;

  /**
   * Represents the edit state of the profile.
   */
  public profileEditState: IProfileEditState;

  private profileInitialState = {
    email: "",
    firstName: "",
    image: "",
    lastName: "",
    middleName: "",
    oldPassword: "",
    password: "",
    phone: "",
    repeatPassword: "",
  };

  private repository: IProfileRepository = new ProfileRepository(
    new AxiosRequestClient()
  );

  /**
   * Creates an instance of ProfileViewModel.
   *
   * @param {IRootTreeModel} model - The root tree model.
   * @param {IProfileState} params - The initial profile state.
   */
  public constructor(private model: IRootTreeModel, params?: IProfileState) {
    if (params) {
      this.profileInitialState = { ...this.profileInitialState, ...params };
    }

    this.profileState = observable({ ...this.profileInitialState });

    this.profileErrors = observable({ ...this.profileInitialErrors });

    this.profileEditState = observable({ ...this.profileInitialState });
  }

  public get profileInitialErrors(): IErrors {
    return {
      errorOldPassword: null,
      errorPasswordNotEqual: null,
      errorPasswordValidationBigLetter: null,
      errorPasswordValidationNumber: null,
      errorPasswordValidationSymbols: null,
      errorPhone: null,
    };
  }

  public setErrorPhone = (error: boolean): void => {
    this.profileErrors.errorPhone = error;
  };

  public setErrorOldPassword = (error: boolean): void => {
    this.profileErrors.errorOldPassword = error;
  };

  public setErrorPasswordNotEqual = (error: boolean): void => {
    this.profileErrors.errorPasswordNotEqual = error;
  };

  public setErrorPasswordValidationSymbols = (error: boolean): void => {
    this.profileErrors.errorPasswordValidationSymbols = error;
  };

  public setErrorPasswordValidationNumber = (error: boolean): void => {
    this.profileErrors.errorPasswordValidationNumber = error;
  };

  public setErrorPasswordValidationBigLetter = (error: boolean): void => {
    this.profileErrors.errorPasswordValidationBigLetter = error;
  };

  /**
   * Sets the email in the edit state of the profile.
   *
   * @param {string} email - The email address.
   */
  public setEmail = (email: string): void => {
    this.profileEditState.email = email;
  };

  /**
   * Sets the image in the edit state of the profile.
   *
   * @param {File} image - The image file.
   */
  public setImage = (image: File): void => {
    this.profileEditState.image = image;
  };

  /**
   * Sets the first name in the edit state of the profile.
   *
   * @param {string} firstName - The first name.
   */
  public setFirstName = (firstName: string): void => {
    this.profileEditState.firstName = firstName;
  };

  /**
   * Sets the last name in the edit state of the profile.
   *
   * @param {string} lastName - The last name.
   */
  public setLastName = (lastName: string): void => {
    this.profileEditState.lastName = lastName;
  };

  /**
   * Sets the middle name in the edit state of the profile.
   *
   * @param {string} middleName - The middle name.
   */
  public setMiddleName = (middleName: string): void => {
    this.profileEditState.middleName = middleName;
  };

  /**
   * Sets the phone number in the edit state of the profile.
   *
   * @param {string} phone - The phone number.
   */
  public setPhone = (phone: string): void => {
    this.profileEditState.phone = phone;
  };

  /**
   * Sets the password in the edit state of the profile.
   *
   * @param {string} data - The password.
   */
  public setPassword = (data: string): void => {
    this.profileEditState.password = data;
  };

  /**
   * Sets the repeat password in the edit state of the profile.
   *
   * @param {string} data - The repeat password.
   */
  public setRepeatPassword = (data: string): void => {
    this.profileEditState.repeatPassword = data;
  };

  /**
   * Sets the old password in the edit state of the profile.
   *
   * @param {string} data - The old password.
   */
  public setOldPassword = (data: string): void => {
    this.profileEditState.oldPassword = data;
  };

  /**
   * Fetches the profile data from the repository.
   *
   * @returns {Promise<void>} - A Promise that resolves when the profile data is fetched.
   */
  public fetchProfileData = async (): Promise<void> => {
    try {
      this.statuses.setStatus("fetchProfile", RequestStatus.Pending);

      const profile = await this.repository.getProfile();

      this.profileState = _.merge(this.profileState, profile);
      this.profileEditState = _.merge(this.profileEditState, profile);
      this.profileInitialState = _.merge(this.profileInitialState, profile);

      this.statuses.setStatus("fetchProfile", RequestStatus.Success);
    } catch (error) {
      this.statuses.setStatus("fetchProfile", RequestStatus.Error);

      throw error;
    }
  };

  /**
   * Saves the edit state of the profile.
   *
   * @returns {Promise<void>} - A Promise that resolves when the profile edit state is saved.
   */
  public saveMainEditInfo = async (): Promise<void> => {
    try {
      if (!this.profileEditState.phone) {
        throw this.setErrorPhone(true);
      } else this.setErrorPhone(false);

      if (/[_]/.test(this.profileEditState.phone)) {
        throw this.setErrorPhone(true);
      } else this.setErrorPhone(false);

      this.statuses.setStatus("saveMainEditInfo", RequestStatus.Pending);

      await this.repository.updateProfile({
        firstName: this.profileEditState.firstName,
        lastName: this.profileEditState.lastName,
        middleName: this.profileEditState.middleName,
        phone: this.profileEditState.phone,
      });

      this.statuses.setStatus("saveMainEditInfo", RequestStatus.Success);
    } catch (error) {
      if (this.profileErrors.errorPhone || error) this.setErrorPhone(true);
      // this.statuses.setStatus("saveMainEditInfo", RequestStatus.Error);
      throw error;
    }
  };

  /**
   * (Password) Saves the edit state of the profile.
   *
   * @returns {Promise<void>} - A Promise that resolves when the profile edit state is saved.
   */
  public saveSecureEditInfo = async (): Promise<void> => {
    try {
      if (!this.profileEditState.oldPassword) {
        throw this.setErrorOldPassword(true);
      } else this.setErrorOldPassword(false);

      if (!this.profileEditState.password) {
        throw this.setErrorOldPassword(true);
      } else this.setErrorOldPassword(false);

      if (this.profileEditState.password.length < 8) {
        this.setErrorPasswordValidationSymbols(true);
      } else this.setErrorPasswordValidationSymbols(false);

      if (!/[A-Z]/.test(this.profileEditState.password)) {
        this.setErrorPasswordValidationBigLetter(true);
      } else this.setErrorPasswordValidationBigLetter(false);

      if (!/\d/.test(this.profileEditState.password)) {
        this.setErrorPasswordValidationNumber(true);
      } else this.setErrorPasswordValidationNumber(false);

      if (
        this.profileEditState.password.length < 8 ||
        !/[A-Z]/.test(this.profileEditState.password) ||
        !/\d/.test(this.profileEditState.password)
      ) {
        throw new Error();
      }

      if (
        this.profileEditState.password !== this.profileEditState.repeatPassword
      ) {
        throw this.setErrorPasswordNotEqual(true);
      } else this.setErrorPasswordNotEqual(false);

      this.statuses.setStatus("saveSecureEditInfo", RequestStatus.Pending);

      await this.repository.updateProfilePassword({
        oldPassword: this.profileEditState.oldPassword,
        password: this.profileEditState.password,
      });

      if (localStorage.getItem("PROFILE_ERROR_MSG")) {
        this.setErrorOldPassword(true);
        throw localStorage.removeItem("PROFILE_ERROR_MSG");
      }

      this.statuses.setStatus("saveSecureEditInfo", RequestStatus.Success);
    } catch (error) {
      if (isAxiosError(error)) {
        throw error;
      }
      if (this.profileErrors.errorOldPassword) this.setErrorOldPassword(true);
      if (this.profileErrors.errorPasswordNotEqual)
        this.setErrorPasswordNotEqual(true);
      if (this.profileErrors.errorPasswordValidationBigLetter)
        this.setErrorPasswordValidationBigLetter(true);
      if (this.profileErrors.errorPasswordValidationNumber)
        this.setErrorPasswordValidationNumber(true);
      if (this.profileErrors.errorPasswordValidationSymbols)
        this.setErrorPasswordValidationSymbols(true);
      if (this.profileErrors.errorPhone) this.setErrorPhone(true);
      this.statuses.setStatus("saveSecureEditInfo", RequestStatus.Error);

      throw error;
    }
  };

  /**
   * (Image) Saves the edit state of the profile.
   *
   * @returns {Promise<void>} - A Promise that resolves when the profile edit state is saved.
   */
  public saveImageEditInfo = async (): Promise<void> => {
    try {
      if (
        !this.profileEditState.image ||
        typeof this.profileEditState.image === "string"
      ) {
        throw new Error(TranslationService.t("txt_wrong_profile_image"));
      }

      this.statuses.setStatus("saveImageEditInfo", RequestStatus.Pending);

      await this.repository.updateProfileImage({
        image: this.profileEditState.image,
      });

      this.statuses.setStatus("saveImageEditInfo", RequestStatus.Success);
    } catch (error) {
      this.statuses.setStatus("saveImageEditInfo", RequestStatus.Error);

      throw error;
    }
  };

  /**
   * Clears the edit state of the profile.
   */
  public clearEditState = (): void => {
    this.profileEditState = this.profileInitialState;
  };

  /**
   * Performs necessary cleanup before destroying the view model.
   */
  public beforeDestroy = (): void => {
    // Object.values(this.reactions).forEach((r) => r());
    this.repository.beforeDestroy();
  };
}
