import { IScreenControllerDocScore } from "@screens/DocScoresScreen/connector/__types__/UIViewController";
import { DateFilters } from "@shared/controls/DateFilters/DateFilters";
import { useControllers } from "@view/hooks/useControllers";
import { observer } from "mobx-react";
import React from "react";

export const ScoresDateFilter = observer((): JSX.Element => {
  const { UIViewController } = useControllers<{
    UIViewController: IScreenControllerDocScore;
  }>();

  return (
    <DateFilters
      type={UIViewController.listOfScoresFilters.date_filter}
      date_to={UIViewController.listOfScoresFilters.date_to}
      date_from={UIViewController.listOfScoresFilters.date_from}
      onChangeDateFilter={UIViewController.setDateFilter}
    />
  );
});
