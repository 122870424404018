import { TDtoFuelingCommentListItemEntry } from "./__types__/TDtoFuelingCommentListItemEntry.types";
import { TParseDto } from "../__types__/dto";
import { Utils } from "@modules/utils";
import _ from "lodash";
import moment from "moment";

export const DtoFuelingCommentListItemParser: TParseDto<
  TDtoFuelingCommentListItemEntry
> = (data: any): TDtoFuelingCommentListItemEntry => ({
  comment: Utils.isString(data.comment) ? data.comment : "",
  file: data.file
    ? {
        mime: Utils.isString(data.file.mime) ? data.file.mime : "",
        size: Number(data.file.size),
        url: Utils.isString(data.file.url) ? data.file.url : "",
      }
    : undefined,
  fuelingRequest: data.fuelingRequest
    ? {
        coefficient: data.fuelingRequest.coefficient
          ? data.fuelingRequest.coefficient
          : 0,
        // comments: Array.isArray(data.fuelingRequest.comments)
        //   ? data.fuelingRequest.comments
        //   : [],
        contractor: data.fuelingRequest.contractor
          ? {
              id: data.fuelingRequest.contractor.id,
              name: Utils.isString(data.fuelingRequest.contractor.name)
                ? data.fuelingRequest.contractor.name
                : "",
              regions: Array.isArray(data.fuelingRequest.contractor.regions)
                ? data.fuelingRequest.contractor.regions
                : [],
            }
          : null,

        createdAt: Utils.isString(data.fuelingRequest.createdAt)
          ? data.fuelingRequest.createdAt
          : moment().utc(),
        fuelingType: data.fuelingRequest.fuelingType || 1,
        id: data.fuelingRequest.id,
        number: Utils.isString(data.fuelingRequest.number)
          ? data.fuelingRequest.number
          : "",
        region: data.fuelingRequest.region
          ? {
              contractors: [1, 4],
              id: data.fuelingRequest.region.id,
              name: Utils.isString(data.fuelingRequest.region.name)
                ? data.fuelingRequest.region.name
                : "",
            }
          : null,
        updatedAt: Utils.isString(data.fuelingRequest.updatedAt)
          ? data.fuelingRequest.updatedAt
          : moment().utc(),
        vehicle: data.fuelingRequest.vehicle
          ? {
              id: data.fuelingRequest.vehicle.id,
              number: Utils.isString(data.fuelingRequest.vehicle.number)
                ? data.fuelingRequest.vehicle.number
                : "",
            }
          : null,
        volume: data.fuelingRequest.volume
          ? String(data.fuelingRequest.volume)
          : "0",
          weight: data.fuelingRequest.weight
          ? String(data.fuelingRequest.weight)
          : "0",
      }
    : null,
  id: data.id,
  status: _.isNumber(data.status) ? data.status : 0,
});
