import { IScreenControllerRecalculations } from "@screens/RecalculationsScreen/connector/__types__/UIViewController";
import { TranslationService } from "@services/translate";
import TableOutput from "@shared/controls/TableOutput/TableOutput";
import { useControllers } from "@view/hooks/useControllers";
import { observer } from "mobx-react";
import moment from "moment";
import React from "react";

const headerList = {
  contract: [TranslationService.t("txt_col_contract")],
  deliveryDate: [TranslationService.t("txt_col_delivery_date"), true],
  price: [TranslationService.t("txt_col_price"), true],
  region: [TranslationService.t("txt_col_region")],
  score: [TranslationService.t("txt_col_score")],
  weight: [TranslationService.t("txt_col_weight"), true],
};

export const RecalculationsTable = observer((): JSX.Element => {
  const { UIViewController } = useControllers<{
    UIViewController: IScreenControllerRecalculations;
  }>();

  return (
    <TableOutput
      sortBy={UIViewController.listOfRecalculationsFilters.order}
      sortDir={UIViewController.listOfRecalculationsFilters.direction}
      onChangeOrder={UIViewController.setFieldOrderBy}
      headerObj={headerList}
      isLoading={UIViewController.isRequestInProgress}
      resetFilters={() => UIViewController.resetFilters()}
      list={UIViewController.listOfRecalculationsMetadata.data.map((score) => ({
        contract: score.number,
        contractor: score.contractor.name,
        deliveryDate: moment(score.deliveryDate).format("DD.MM.YY"),
        price: score.price,
        region: score.regions.map((region) => region.name).join(", "),
        recalculations: score.numberRecalculations,
        weight: score.weight,
      }))}
    />
  );
});
