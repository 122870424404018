import {
  IFuelTransferState,
  IFuelTransferViewModel,
} from "./__types__/IFuelTransferViewModel";
import { Statuses } from "../statuses";
import { IRootTreeModel } from "@models/RootTreeModel";
// import { ReportsRepository } from "@repositories/reports";
import { IReportsRepository } from "@repositories/reports/__types__/repository";

// import moment, { Moment } from "moment";
import { TranslationService } from "@services/translate";
import { observable } from "mobx";
import { RequestStatus } from "@constants/repositories";
import { AxiosRequestClient } from "@modules/index";
import { ReportsRepository } from "@repositories/reports/repository";

// Defining initialDateTime using moment library

export class FuelTransferViewModel implements IFuelTransferViewModel {
  /**
   * Statuses instance for managing status
   *
   * @type {Statuses}
   */
  public statuses: Statuses = new Statuses(["createFuelTransfer"]);

  /**
   * State of the FuelTransferViewModel
   *
   * @type {IFuelTransferState}
   */
  public state: IFuelTransferState;

  /**
   * Repository for managing reports
   *
   * @type {IReportsRepository}
   * @private
   */
  private repository: IReportsRepository = new ReportsRepository(
    new AxiosRequestClient()
  );

  /**
   * Constructor for FuelTransferViewModel
   *
   * @param {IRootTreeModel} model - RootTreeModel instance
   */
  public constructor(
    private model: IRootTreeModel,
    params?: Partial<IFuelTransferState>
  ) {
    this.state = observable({ ...this.initialState, ...params });
  }

  /**
   * Get the initial state of FuelTransferViewModel
   *
   * @returns {IFuelTransferState} - The initial state object
   * @private
   */
  private get initialState(): IFuelTransferState {
    return {
      contractor: null,
      regionFrom: null,
      regionTo: null,
      weight: 0,
    };
  }

  /**
   * Set the contract in the state
   *
   * @param {object} contract - The contract object
   * @param {number} contract.id - The contract ID
   * @param {string} contract.name - The contract name
   */

  /**
   * Set the price in the state
   *
   * @param {number} price - The price
   */
  // public setPrice = (price: number): void => {
  //   this.state.price = price;
  // };

  /**
   * Set the weight in the state
   *
   * @param {number} weight - The weight
   */
  public setWeight = (weight: number): void => {
    this.state.weight = weight;
  };

  public setRegionFrom = (
    region: { id: number; name: string } | null
  ): void => {
    this.state.regionFrom = region;
  };

  public setRegionTo = (region: { id: number; name: string } | null): void => {
    this.state.regionTo = region;
  };

  public setContractor = (data: { id: number; name: string } | null): void => {
    this.state.contractor = data;
  };

  /**
   * Create a new score
   *
   * @param {IFuelTransferState} data - The score data to be created
   * @returns {Promise<{ id: number }>} - The created score ID
   */
  public createFuelTransfer = async (
    data: IFuelTransferState
  ): Promise<{ id: number }> => {
    try {
      // Validation checks

      if (data.weight <= 0) {
        throw new Error(TranslationService.t("txt_wrong_weight_format"));
      }

      if (!data.weight) {
        throw new Error(TranslationService.t("txt_error_missing_weight"));
      }

      if (!data.contractor)
        throw new Error(TranslationService.t("txt_error_missing_contractor"));

      this.statuses.setStatus("createFuelTransfer", RequestStatus.Pending);

      if (!data.regionTo || !data.regionFrom)
        throw new Error(TranslationService.t("txt_error_missing_region"));

      // Create the score using the repository
      const response = await this.repository.createFuelTransfer({
        contractor: data.contractor.id,
        regionFrom: data.regionFrom.id,
        regionTo: data.regionTo.id,
        weight: data.weight,
      });

      this.statuses.setStatus("createFuelTransfer", RequestStatus.Success);

      return response;
    } catch (error) {
      this.statuses.setStatus("createFuelTransfer", RequestStatus.Error);
      throw error;
    }
  };

  /**
   * Clear the state of FuelTransferViewModel
   */
  public clearState = (): void => {
    this.state = this.initialState;
  };

  /**
   * Perform cleanup tasks before destroying the FuelTransferViewModel
   */
  public beforeDestroy = (): void => {
    // Additional cleanup tasks can be performed here
    this.repository.beforeDestroy();
  };
}
