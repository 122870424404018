import { IScreenControllerReportsByRegion } from "@screens/ReportByRegionsScreen/connector/__types__/UIViewController";
import { DateFilters } from "@shared/controls/DateFilters/DateFilters";
import { useControllers } from "@view/hooks/useControllers";
import { observer } from "mobx-react";
import React from "react";

export const ReportsByRegionDateFilter = observer((): JSX.Element => {
  const { UIViewController } = useControllers<{
    UIViewController: IScreenControllerReportsByRegion;
  }>();

  return (
    <DateFilters
      type={UIViewController.listOfFuelingFilters.date_filter}
      date_to={UIViewController.listOfFuelingFilters.date_to}
      date_from={UIViewController.listOfFuelingFilters.date_from}
      onChangeDateFilter={UIViewController.setDateFilter}
    />
  );
});
