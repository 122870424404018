// import { TranslationService } from "@services/translate";
import NotificationItem from "./NotificationItem/NotificationItem";
import {
  IUISceneController,
  TNotificationsSceneParams,
  TNotificationsSceneResult,
} from "../connector/UISceneController/types";
import { observer } from "mobx-react";
import { useScene } from "@view/hooks/useScene/useScene";
import { TranslationService } from "@services/translate";
import FilterComponent from "@shared/helpers/SideForm/SideForm";
import "./styles.scss";

const NotificationsList = observer((): JSX.Element => {
  const UISceneController =
    useScene<
      IUISceneController<TNotificationsSceneResult, TNotificationsSceneParams>
    >();

  return (
    <FilterComponent
      title={TranslationService.t("txt_notifications")}
      onClose={UISceneController.closeScene}
    >
      <div className="custom-form notifications">
        <div className="custom-field notifications__list">
          {UISceneController.listOfNotification.map((notification) => (
            <NotificationItem
              onRemoveNotification={UISceneController.removeNotification}
              key={notification.hash}
              notification={notification}
            />
          ))}
        </div>
      </div>
    </FilterComponent>
  );
});

export default NotificationsList;
