import { IScreenControllerDocContract } from "./__types__/UIViewController";
import { IReactionDisposer } from "mobx";
import { UILogicController } from "@view/providers/__proto__/withController";
import { IRootTreeModel } from "@models/RootTreeModel";
import { toast } from "react-toastify";
import { getMessageFromError } from "@modules/utils/getMessageFromError";
import { IContractListItemEntity } from "@entities/ContractListItemEntity";
import { RequestStatus } from "@constants/repositories";
import { TranslationService } from "@services/translate";
import { SceneControllerContractCreate } from "@scenes/ContractCreateScene";
import { SceneControllerContractsFilters } from "@scenes/FiltersScene";
import {
  IContractsListFilters,
  IContractsListViewModel,
  TContractsFieldsOrder,
} from "@viewModels/ContractsListViewModel/__types__/IContractsListViewModel";
import { ContractsListViewModel } from "@viewModels/ContractsListViewModel/ContractsListViewModel";
import { IPagination } from "@viewModels/pagination/types";
import { ContractCreateViewModel } from "@viewModels/ContractCreateViewModel/ContractCreateViewModel";
import {
  IContractCreateState,
  IContractCreateViewModel,
} from "@viewModels/ContractCreateViewModel/__types__/IContractsListViewModel";
import { ScenesViewModel } from "@viewModels/scenes/ScenesViewModel";
import { IFiltersViewModel } from "@viewModels/FiltersViewModel/__types__/IFiltersViewModel";
import { FiltersViewModel } from "@viewModels/FiltersViewModel/FiltersViewModel";
import { ErrorScene, ScenesErrors } from "@viewModels/scenes/ErrorScene";
import { DateFilerType } from "@viewModels/DatePeriodFilterViewModel/__types__/IDatePeriodFilterViewModel.types";

class ScreenControllerDocContract
  extends UILogicController
  implements IScreenControllerDocContract
{
  public disposers: { [key: string]: IReactionDisposer } = {};

  private ContractsListViewModel: IContractsListViewModel;

  private ContractCreateViewModel: IContractCreateViewModel;

  private FiltersViewModel: IFiltersViewModel;

  public constructor(model: IRootTreeModel) {
    super(model);

    this.ContractsListViewModel = new ContractsListViewModel(model, {
      search: "",
    });

    this.ContractCreateViewModel = new ContractCreateViewModel(model);

    this.FiltersViewModel = new FiltersViewModel(model, {});
  }

  /** Indicator for otp request confirmation or resending otp request */
  public get isRequestInProgress(): boolean {
    return (
      this.ContractsListViewModel.statuses.getStatus("fetchItems") ===
      RequestStatus.Pending
    );
  }

  public get totalNumberOfContracts(): number {
    return this.ContractsListViewModel.meta.total;
  }

  public get listOfContractsMetadata(): IPagination<IContractListItemEntity> {
    return this.ContractsListViewModel.metadata;
  }

  public get listOfContractsFilters(): IContractsListFilters {
    return this.ContractsListViewModel.filters;
  }

  public get createContractState(): IContractCreateState {
    return this.ContractCreateViewModel.state;
  }

  public get filterCount(): number {
    return this.FiltersViewModel.state.filterCount;
  }

  public setFieldOrderBy = (
    field: TContractsFieldsOrder,
    direction: "asc" | "desc"
  ): void => {
    try {
      this.ContractsListViewModel.setFieldOrder(field, direction);

      this.ContractsListViewModel.fetchItemsBatch(1, true);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  // public setDateFilter = (params: IDatePeriodFilterViewModelParams): void => {
  //   try {
  //     this.ContractsListViewModel.setDateFilter(params);

  //     this.ContractsListViewModel.fetchItemsBatch(1, true);
  //   } catch (error) {
  //     toast.error(getMessageFromError(error));
  //   }
  // };

  public setFilterCount(count: number): void {
    try {
      this.FiltersViewModel.setFilterCount(count);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  }

  public fetchListOfContracts = (): void => {
    try {
      this.ContractsListViewModel.fetchItemsBatch(
        this.ContractsListViewModel.meta.current_page + 1,
        false
      );
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public openCreateContractScene = async (): Promise<void> => {
    try {
      await ScenesViewModel.openRegisteredScene(SceneControllerContractCreate, {
        model: this.model,
      });

      // Notify user about success action
      toast.success(TranslationService.t("txt_success_contract_created"));

      await this.ContractsListViewModel.fetchItemsBatch(1, true);
    } catch (error) {
      if (error instanceof ErrorScene) {
        if (error.cause === ScenesErrors.UserClosedScene) {
          return;
        }
      }

      toast.error(getMessageFromError(error));
    }
  };

  public resetFilters = async (): Promise<void> => {
    try {
      this.ContractsListViewModel.setRegion(null);
      this.ContractsListViewModel.setContract(null);
      this.ContractsListViewModel.setContractor(null);
      this.ContractsListViewModel.setConclusionDateFilter(null);
      this.ContractsListViewModel.setTermDateFilter(null);

      this.setFilterCount(0);
      await this.ContractsListViewModel.fetchItemsBatch(1, true);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public openContractsFiltersScene = async (): Promise<void> => {
    try {
      const result = await ScenesViewModel.openRegisteredScene(
        SceneControllerContractsFilters,
        {
          conclusionDate: this.ContractsListViewModel.filters.conclusionDate,
          contract: this.ContractsListViewModel.filters.contract,
          contractor: this.ContractsListViewModel.filters.contractor,
          fields: {
            conclusionDate: true,
            contract: true,
            contractor: true,
            region: true,
            termDate: true,
          },
          model: this.model,
          region: this.ContractsListViewModel.filters.region || undefined,
          termDate: this.ContractsListViewModel.filters.termDate,
        }
      );
      this.ContractsListViewModel.setRegion(result.region);
      this.ContractsListViewModel.setContract(result.contract);
      this.ContractsListViewModel.setContractor(result.contractor);
      this.ContractsListViewModel.setConclusionDateFilter(
        result.conclusionDate
          ? {
              end: result.conclusionDate.date_to,
              start: result.conclusionDate.date_from,
              type: DateFilerType.Custom,
            }
          : null
      );
      this.ContractsListViewModel.setTermDateFilter(
        result.termDate
          ? {
              end: result.termDate.date_to,
              start: result.termDate.date_from,
              type: DateFilerType.Custom,
            }
          : null
      );

      this.setFilterCount(result.filterCount);
      await this.ContractsListViewModel.fetchItemsBatch(1, true);
    } catch (error) {
      if (error instanceof ErrorScene) {
        if (error.cause === ScenesErrors.UserClosedScene) {
          return;
        }
      }

      toast.error(getMessageFromError(error));
    }
  };

  public beforeDestroy = (): void => {
    this.ContractsListViewModel.beforeDestroy();
    this.ContractCreateViewModel.beforeDestroy();
    this.FiltersViewModel.beforeDestroy();
    /** Cancel all disposers */
    Object.values(this.disposers).map((func) => func.call(undefined));
  };
}

export { ScreenControllerDocContract };
