const IconUser = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="26"
    viewBox="0 0 22 26"
    fill="none"
  >
    <path
      d="M11 0C9.71076 0 8.45047 0.381218 7.37851 1.09545C6.30654 1.80968 5.47105 2.82484 4.97767 4.01256C4.4843 5.20028 4.35521 6.50721 4.60673 7.76809C4.85825 9.02896 5.47908 10.1872 6.39071 11.0962C7.30234 12.0052 8.46383 12.6243 9.7283 12.8751C10.9928 13.1259 12.3034 12.9972 13.4945 12.5052C14.6856 12.0132 15.7037 11.1801 16.4199 10.1112C17.1362 9.04229 17.5185 7.78558 17.5185 6.5C17.5185 4.77609 16.8317 3.12279 15.6093 1.90381C14.3868 0.684819 12.7288 0 11 0ZM11 10.5625C10.1942 10.5625 9.40654 10.3242 8.73657 9.87785C8.06659 9.43145 7.5444 8.79698 7.23605 8.05465C6.92769 7.31233 6.84701 6.49549 7.00421 5.70745C7.16141 4.9194 7.54943 4.19553 8.11919 3.62738C8.68896 3.05923 9.41489 2.67231 10.2052 2.51556C10.9955 2.35881 11.8146 2.43926 12.5591 2.74674C13.3035 3.05422 13.9398 3.57492 14.3875 4.243C14.8351 4.91107 15.0741 5.69651 15.0741 6.5C15.0741 7.57744 14.6448 8.61075 13.8808 9.37262C13.1168 10.1345 12.0805 10.5625 11 10.5625Z"
      fill="#303B57"
    />
    <path
      d="M0 21.5312V26H22V21.5312C22 17.6597 17.1681 14.625 11 14.625C4.83185 14.625 0 17.6597 0 21.5312ZM19.5556 21.5312V23.5625H2.44444V21.5312C2.44444 19.4188 5.9563 17.0625 11 17.0625C16.0437 17.0625 19.5556 19.4188 19.5556 21.5312Z"
      fill="#303B57"
    />
  </svg>
);

export default IconUser;
