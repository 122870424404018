import "./styles.scss";
import { ProfileMainForm } from "./components/MainForm/MainForm";
import { ProfileSecureForm } from "./components/SecureForm/SecureForm";
import { ScreenControllerProfile } from "./connector/UIViewController";
import { IScreenControllerProfile } from "./connector/__types__/UIViewController";
import { ProfileImageForm } from "./components/ImageForm/ImageForm";
import styled from "styled-components";
import withControllers from "@view/providers/__proto__/withController";
import { useControllers } from "@view/hooks/useControllers";
import { useEffect } from "react";
import { TranslationService } from "@services/translate";
import { Helmet } from "react-helmet";

const ProfileScreen = (): JSX.Element => {
  const { UIViewController } = useControllers<{
    UIViewController: IScreenControllerProfile;
  }>();

  useEffect(() => {
    UIViewController.fetchProfileData();
  }, []);

  return (
    <Col32 className="col">
      <Helmet>
        <title>{TranslationService.t("txt_title_profile")}</title>
      </Helmet>
      <h2>{TranslationService.t("txt_title_profile")}</h2>
      <Gap40 className="col">
        <FlexGroup>
          <ProfileMainForm />
          <ProfileImageForm />
        </FlexGroup>
        <ProfileSecureForm />
      </Gap40>
    </Col32>
  );
};
export default withControllers(ScreenControllerProfile)(ProfileScreen);

const Col32 = styled.div`
  gap: 32px;
  height: 100%;

  @media (max-width: 768px) {
    .custom-field {
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
    }
  }
`;

const Gap40 = styled.div`
  gap: 40px;
`;

const FlexGroup = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 26px;

  > div:first-child {
    width: 50%;
  }
  @media (max-width: 1024px) {
    flex-wrap: wrap-reverse;
    > div:first-child {
      width: 100%;
    }
  }
`;
