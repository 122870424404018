import { ScreenControllerFuelingTurnover } from "./connector/UIViewController";
import { HomeTable } from "./components/Table/Table";
import "./styles.scss";
import { HomeDateFilter } from "./components/DateFilter/DateFilter";
import { SortingMobile } from "./components/SortingMobile/SortingMobile";
import { FuelingTurnoverPagination } from "./components/Pagination/Pagination";
import styled from "styled-components";
import { TranslationService } from "@services/translate";
import withControllers from "@view/providers/__proto__/withController";
import { useEffect } from "react";
import { useControllers } from "@view/hooks/useControllers";
import Button from "@shared/controls/Button/Button";
import { IconFilter } from "@shared/controls/icons";
import { observer } from "mobx-react";
import { useWindowWidth } from "@view/hooks/useWindowWidth";
import { Helmet } from "react-helmet";

const HomeScreen = observer((): JSX.Element => {
  const windowWidth = useWindowWidth();

  const { UIViewController } = useControllers<{
    UIViewController: ScreenControllerFuelingTurnover;
  }>();

  useEffect(() => {
    UIViewController.fetchListOfFuelingTurnover();
  }, []);

  return (
    <Col32 className="col">
      <Helmet>
        <title>{TranslationService.t("txt_title_home")}</title>
      </Helmet>
      <h2>{TranslationService.t("txt_title_home")}</h2>
      <ColParameters>
        <div className="row justify-between align-start">
          <RowGap className="row">
            {windowWidth <= 768 ? (
              <>
                <HomeDateFilter />
                <SortingMobile />
              </>
            ) : null}
            <Button
              className={`${
                UIViewController.filterCount ? "primary" : "secondary"
              } w-fit-content ${windowWidth > 768 ? "" : "gap0"}`}
              size="small"
              text={
                windowWidth > 768
                  ? UIViewController.filterCount
                    ? TranslationService.t("txt_filter") +
                      ` ${UIViewController.filterCount}`
                    : TranslationService.t("txt_filter")
                  : ""
              }
              type={"button"}
              iconLeft={<IconFilter />}
              onClick={UIViewController.openFuelingTurnoverFiltersScene}
            />
            {windowWidth > 768 ? <HomeDateFilter /> : null}
          </RowGap>
        </div>
        <HomeTable />
      </ColParameters>
      <FuelingTurnoverPagination />
    </Col32>
  );
});

export default withControllers(ScreenControllerFuelingTurnover)(HomeScreen);

const Col32 = styled.div`
  gap: 32px;
  height: 100%;
`;

const ColParameters = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-bottom: 40px;

  .custom-select {
    max-width: 325px;
  }

  @media (max-width: 768px) {
    height: 100%;
  }
`;

const RowGap = styled.div`
  gap: 14px;
`;
