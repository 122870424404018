/* eslint-disable sort-keys/sort-keys-fix */
import { IScreenControllerReportsByContract } from "@screens/ReportByContractsScreen/connector/__types__/UIViewController";
import TableOutput from "@shared/controls/TableOutput/TableOutput";
import { useControllers } from "@view/hooks/useControllers";
import { observer } from "mobx-react";
import moment from "moment";

const headerList = {
  createdAt: ["Дата заправки", true],
  region: ["Регион"],
  vehicle: ["№ борта"],
  pilot: ["Командир ВС"],
  weight: ["Вес топлива, кг", true],
  volume: ["Объем топлива, л", true],
};

export const ReportsByContractsTable = observer((): JSX.Element => {
  const { UIViewController } = useControllers<{
    UIViewController: IScreenControllerReportsByContract;
  }>();

  return (
    <TableOutput
      sortBy={UIViewController.listOfFuelingFilters.order}
      sortDir={UIViewController.listOfFuelingFilters.direction}
      onChangeOrder={UIViewController.setFieldOrderBy}
      headerObj={headerList}
      isLoading={UIViewController.isRequestInProgress}
      resetFilters={() => UIViewController.resetFilters()}
      list={UIViewController.listOfFuelingMetadata.data.map((fueling) => ({
        createdAt: moment(fueling.createdAt).format("DD.MM.YY"),
        region: fueling.region?.name,
        // number: fueling.number,
        vehicle: fueling.vehicle?.number,
        weight: fueling.weight,
        volume: fueling.volume,
        contractor: fueling.contractor.name,
        pilot: fueling.pilot?.name,
        report: "report",
        reportContract: "contract",
        // comment: () => UIViewController.openFuelingCommentScene(fueling.id),
      }))}
    />
  );
});
