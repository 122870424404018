import { IHeaderController } from "./__types__/UIViewController";
import { IReactionDisposer } from "mobx";
import { UILogicController } from "@view/providers/__proto__/withController";
import { IRootTreeModel } from "@models/RootTreeModel";
import { IUserEntity } from "@entities/UserEntity";
import { SceneControllerNotificationsList } from "@scenes/NotificationsScene";
import { toast } from "react-toastify";
import { Utils } from "@modules/utils";
import { AuthViewModel } from "@viewModels/AuthViewModel/AuthViewModel";
import { IAuthViewModel } from "@viewModels/AuthViewModel/__types__/IAuthViewModel";
import { ISidebarViewModel } from "@viewModels/SidebarViewModel/__types__/SidebarViewModel.types";
import { SidebarViewModel } from "@viewModels/SidebarViewModel/SidebarViewModel";
import { ScenesViewModel } from "@viewModels/scenes/ScenesViewModel";
import { ErrorScene, ScenesErrors } from "@viewModels/scenes/ErrorScene";
import { IProfileEntity } from "@entities/ProfileEntity/ProfileEntity";

class HeaderController extends UILogicController implements IHeaderController {
  public disposers: { [key: string]: IReactionDisposer } = {};

  public SidebarViewModel: ISidebarViewModel;

  public AuthViewModel: IAuthViewModel;

  public constructor(model: IRootTreeModel) {
    super(model);

    this.SidebarViewModel = new SidebarViewModel(this.model);
    this.AuthViewModel = new AuthViewModel(this.model);
  }

  public get isUserLoggedIn(): boolean {
    return Boolean(this.model.user.isLogin);
  }

  public get isOpenedSideMenu(): boolean {
    return this.SidebarViewModel.isOpenedSideMenu;
  }

  public get userinfo(): IUserEntity | null {
    return this.model.user.user;
  }

  public get profileInfo(): IProfileEntity | null {
    return this.model.user.profile;
  }

  public get username(): string {
    if (this.model.user.profile?.firstName) {
      return [
        this.model.user.profile.lastName,
        this.model.user.profile.firstName
          ? `${this.model.user.profile.firstName[0]}.`
          : "",
        this.model.user.profile.middleName
          ? `${this.model.user.profile.middleName[0]}.`
          : "",
      ].join(" ");
    }

    // https://tewris-projects.atlassian.net/browse/ART-195
    // if (this.model.user.profile?.email) {
    //   return this.model.user.profile.email;
    // }
    return "";
  }

  public get notificationsCount(): number {
    return this.model.notifications.count;
  }

  public logout = (): void => {
    this.AuthViewModel.logout();
  };

  public closeSideMenu = (): void => this.SidebarViewModel.closeSideMenu();

  public openSideMenu = (): void => this.SidebarViewModel.openSideMenu();

  public toggleSideMenu = (): void => this.SidebarViewModel.toggleSideMenu();

  public openNotifications = async (): Promise<void> => {
    try {
      await ScenesViewModel.openRegisteredScene(
        SceneControllerNotificationsList,
        {
          model: this.model,
        }
      );
      // if (this.model.user.user?.roles.includes(UserRoles.EmployeeGSM)) {
      //   await ScenesViewModel.openRegisteredScene(
      //     SceneControllerNotificationsList,
      //     {
      //       model: this.model,
      //     }
      //   );
      // } else if (this.model.user.user?.roles.includes(UserRoles.Kvs)) {
      //   await ScenesViewModel.openRegisteredScene(
      //     SceneControllerNotificationsPilotList,
      //     {
      //       model: this.model,
      //     }
      //   );
      // }
    } catch (error) {
      if (error instanceof ErrorScene) {
        if (error.cause === ScenesErrors.UserClosedScene) {
          return;
        }
      }

      toast.error(Utils.getMessageFromError(error));
    }
  };

  public beforeDestroy = (): void => {
    /** Cancel all disposers */
    Object.values(this.disposers).map((func) => func.call(undefined));

    this.SidebarViewModel.beforeDestroy();
  };
}

export { HeaderController };
