import { IScreenControllerFuelingComments } from "./__types__/UIViewController";
import { IReactionDisposer } from "mobx";
import { UILogicController } from "@view/providers/__proto__/withController";
import { IRootTreeModel } from "@models/RootTreeModel";
import { toast } from "react-toastify";
import { getMessageFromError } from "@modules/utils/getMessageFromError";
import { RequestStatus } from "@constants/repositories";
import { IPagination } from "@viewModels/pagination/types";
import {
  IFuelingCommentsListFilters,
  IFuelingCommentsListViewModel,
  TFuelingCommentsFieldsOrder,
} from "@viewModels/FuelingCommentsListViewModel/__types__/IFuelingCommentsListViewMode";
import { FuelingCommentsListViewModel } from "@viewModels/FuelingCommentsListViewModel/FuelingCommentsListViewModel";
import { IFuelingCommentListItemEntity } from "@entities/FuelingCommentListItemEntity/FuelingCommentListItemEntity";
import { IUserEntity } from "@entities/UserEntity/UserEntity";
import { DateFilerType } from "@viewModels/DatePeriodFilterViewModel/__types__/IDatePeriodFilterViewModel.types";
// import { IAuthViewModel } from "@viewModels/AuthViewModel/__types__/IAuthViewModel";
// import { AuthViewModel } from "@viewModels/AuthViewModel/AuthViewModel";

class ScreenControllerFuelingComments
  extends UILogicController
  implements IScreenControllerFuelingComments
{
  public disposers: { [key: string]: IReactionDisposer } = {};

  private FuelingCommentsListViewModel: IFuelingCommentsListViewModel;

  public constructor(model: IRootTreeModel) {
    super(model);

    this.FuelingCommentsListViewModel = new FuelingCommentsListViewModel(
      model,
      {
        dateType: DateFilerType.Month,
        isNew: false,
      }
    );
  }

  public get userinfo(): IUserEntity | null {
    return this.model.user.user;
  }

  /** Indicator for otp request confirmation or resending otp request */
  public get isRequestInProgress(): boolean {
    return (
      this.FuelingCommentsListViewModel.statuses.getStatus("fetchItems") ===
      RequestStatus.Pending
    );
  }

  public get listOfFuelingCommentsMetadata(): IPagination<IFuelingCommentListItemEntity> {
    return this.FuelingCommentsListViewModel.metadata;
  }

  public get listOfFuelingCommentsFilters(): IFuelingCommentsListFilters {
    return this.FuelingCommentsListViewModel.filters;
  }

  public setFieldOrderBy = (
    field: TFuelingCommentsFieldsOrder,
    direction: "asc" | "desc"
  ): void => {
    try {
      this.FuelingCommentsListViewModel.setFieldOrder(field, direction);

      this.FuelingCommentsListViewModel.fetchItemsBatch(1, true);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public fetchListOfFuelingComments = (): void => {
    try {
      this.FuelingCommentsListViewModel.fetchItemsBatch(
        this.FuelingCommentsListViewModel.meta.current_page + 1,
        false
      );
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public beforeDestroy = (): void => {
    this.FuelingCommentsListViewModel.beforeDestroy();
    /** Cancel all disposers */
    Object.values(this.disposers).map((func) => func.call(undefined));
  };
}

export { ScreenControllerFuelingComments };
