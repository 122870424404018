const IconEye = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
  >
    <path
      d="M6.9997 5.33233C6.28148 5.33233 5.59279 5.6177 5.08495 6.12551C4.57715 6.63331 4.29178 7.32203 4.29178 8.04025C4.29178 8.75847 4.57715 9.44716 5.08495 9.955C5.59275 10.4628 6.28148 10.7482 6.9997 10.7482C7.71792 10.7482 8.4066 10.4628 8.91444 9.955C9.42225 9.4472 9.70761 8.75847 9.70761 8.04025C9.70761 7.32203 9.42225 6.63334 8.91444 6.12551C8.40664 5.6177 7.71792 5.33233 6.9997 5.33233ZM6.9997 9.08171C6.72349 9.08171 6.45861 8.97201 6.26318 8.77673C6.0679 8.5813 5.9582 8.31643 5.9582 8.04021C5.9582 7.76399 6.0679 7.49911 6.26318 7.30369C6.4586 7.10841 6.72348 6.99871 6.9997 6.99871C7.27592 6.99871 7.54079 7.10841 7.73622 7.30369C7.9315 7.49911 8.0412 7.76399 8.0412 8.04021C8.0412 8.31643 7.9315 8.5813 7.73622 8.77673C7.54079 8.97201 7.27592 9.08171 6.9997 9.08171Z"
      fill="#303B57"
    />
    <path
      d="M11.4989 4.17219L12.2593 3.10774H12.2591C12.4033 2.92917 12.4672 2.69903 12.4361 2.47176C12.405 2.24437 12.2815 2.03995 12.0947 1.90657C11.9079 1.77333 11.6744 1.72291 11.4494 1.76737C11.2242 1.81169 11.0274 1.94696 10.9052 2.14123L10.1429 3.20567C9.42683 2.81452 8.64281 2.56359 7.83279 2.46625V1.16643C7.83279 0.868718 7.67398 0.593686 7.41623 0.444901C7.15847 0.296117 6.84084 0.296117 6.58311 0.444901C6.32535 0.593686 6.16655 0.868751 6.16655 1.16643V2.46625C5.35653 2.5636 4.57251 2.81453 3.85648 3.20567L3.09411 2.14123C2.97191 1.94697 2.77518 1.8117 2.54997 1.76737C2.3249 1.72291 2.09141 1.77333 1.90469 1.90657C1.71784 2.03995 1.59433 2.24438 1.56324 2.47176C1.53214 2.69901 1.59607 2.92917 1.74021 3.10774L2.50054 4.17219H2.50039C1.83477 4.78243 1.26581 5.49046 0.81321 6.27187C0.498642 6.80891 0.333008 7.42001 0.333008 8.04234C0.333008 8.66478 0.498646 9.27591 0.81321 9.81295C1.65477 11.2502 3.57938 13.6642 6.99968 13.6642C10.42 13.6642 12.3448 11.2501 13.1861 9.80848C13.5007 9.27145 13.6663 8.66035 13.6663 8.03802C13.6663 7.41558 13.5007 6.80445 13.1861 6.26741C12.7331 5.48744 12.1642 4.78086 11.499 4.17193L11.4989 4.17219ZM11.7489 8.96305C11.0864 10.1003 9.58888 11.9979 6.99965 11.9979C4.41043 11.9979 2.91291 10.1003 2.25045 8.96725C2.0867 8.68567 2.00039 8.36587 2.00039 8.04025C2.00039 7.71464 2.0867 7.39482 2.25045 7.11325C2.91287 5.98022 4.41043 4.08262 6.99965 4.08262C9.58888 4.08262 11.0864 5.98022 11.7489 7.11336V7.11322C11.9126 7.3948 11.9989 7.7146 11.9989 8.04022C11.9989 8.36583 11.9126 8.68565 11.7489 8.96722V8.96305Z"
      fill="#303B57"
    />
  </svg>
);

export default IconEye;
