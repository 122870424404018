import { IconCross, IconSort } from "../icons";
import Button from "../Button/Button";
import { observer } from "mobx-react";
import { useCallback, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import styled from "styled-components";

type Props<T> = {
  sortBy?: T;
  sortDir?: "asc" | "desc";
  onChangeOrder?: (sortBy: T, sortDir: "asc" | "desc") => void;
  list: any;
};

export const Sorting = observer(
  <T,>({ sortBy, sortDir, onChangeOrder, list }: Props<T>): JSX.Element => {
    const [isOpenSort, setIsOpenSort] = useState(false);

    const onHeaderPress = useCallback((header: T, type: string) => {
      const direction: "desc" | "asc" = type === "asc" ? "asc" : "desc";
      if (onChangeOrder) onChangeOrder(header as T, direction);
      setIsOpenSort(false);
    }, []);

    return (
      <>
        <Button
          className="secondary gap0"
          size="small"
          type={"button"}
          iconLeft={<IconSort />}
          onClick={() => setIsOpenSort(true)}
        />
        <Offcanvas
          show={isOpenSort}
          onHide={setIsOpenSort}
          placement={"bottom"}
        >
          <Offcanvas.Header>
            <Offcanvas.Title>Сортировка</Offcanvas.Title>
            <IconCross onClick={() => setIsOpenSort(false)} />
          </Offcanvas.Header>
          <Offcanvas.Body>
            {list?.map((el: any, ind: number) => (
              <SortElement className="gap4 column" key={el + ind}>
                <SubTitle>{el[0]}</SubTitle>
                <div className="row gap4 align-center">
                  <Button
                    type={"button"}
                    text={el[2]}
                    className={
                      sortDir === "asc" && sortBy === el[1]
                        ? "primary"
                        : "secondary"
                    }
                    onClick={() => onHeaderPress(el[1] as T, "asc")}
                  />
                  <Button
                    type={"button"}
                    text={el[3]}
                    className={
                      sortDir === "desc" && sortBy === el[1]
                        ? "primary"
                        : "secondary"
                    }
                    onClick={() => onHeaderPress(el[1] as T, "desc")}
                  />
                </div>
              </SortElement>
            ))}
          </Offcanvas.Body>
        </Offcanvas>
      </>
    );
  }
);

const SubTitle = styled.p`
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
`;

const SortElement = styled.div`
  padding: 4px 0;
  width: 100%;

  button {
    padding: 2px 6px !important;
    font-size: 10px;
    font-weight: 500;
    line-height: 16px;
  }
`;
