import {
  IUISceneController,
  TFuelTransferSceneParams,
  TFuelTransferSceneResult,
} from "./types";
import { toast } from "react-toastify";
import { ScenesInstance } from "@scenes/PrototypeScene";
import { Utils } from "@modules/utils";
import { getMessageFromError } from "@modules/utils/getMessageFromError";
import { IContractorListItemEntity } from "@entities/ContractorListItemEntity";
import { IRegionListItemEntity } from "@entities/RegionListItemEntity";
import { debounce } from "lodash";
import {
  IFuelTransferState,
  IFuelTransferViewModel,
} from "@viewModels/FuelTransferViewModel/__types__/IFuelTransferViewModel";
import { FuelTransferViewModel } from "@viewModels/FuelTransferViewModel/FuelTransferViewModel";
import { TSceneDisplayOptions } from "@viewModels/scenes/__types__/SceneDisplay";
import { RegionsListViewModel } from "@viewModels/RegionsListViewModel/RegionsListViewModel";
import { ContractorsListViewModel } from "@viewModels/ContractorsListViewModel/ContractorsListViewModel";
import { IRegionsListViewModel } from "@viewModels/RegionsListViewModel/__types__/IRegionsListViewModel";
import { IContractorsListViewModel } from "@viewModels/ContractorsListViewModel/__types__/IContractorsListViewModel";
import { IPagination } from "@viewModels/pagination/types";

export class UISceneController<
    T extends TFuelTransferSceneResult,
    P extends TFuelTransferSceneParams
  >
  extends ScenesInstance<T, P>
  implements IUISceneController<T, P>
{
  public static _name: string = "FuelTransferScene";

  public fetchListOfContractorsDebounce = debounce(
    (page?: number, refreshing?: boolean) =>
      this.fetchListOfContractors(page, refreshing),
    400
  );

  private RegionsListViewModel: IRegionsListViewModel;

  private ContractorsListViewModel: IContractorsListViewModel;

  private FuelTransferViewModel: IFuelTransferViewModel;

  public constructor(
    options: TSceneDisplayOptions,
    private params: TFuelTransferSceneParams
  ) {
    super(options);

    this.RegionsListViewModel = new RegionsListViewModel(params.model);

    this.ContractorsListViewModel = new ContractorsListViewModel(params.model);

    this.FuelTransferViewModel = new FuelTransferViewModel(params.model, {
      ...params.fields,
    });
  }

  public get _name(): string {
    return UISceneController._name;
  }

  public get createFuelTransferState(): IFuelTransferState {
    return this.FuelTransferViewModel.state;
  }

  public get listOfContractorsMetadata(): IPagination<IContractorListItemEntity> {
    return this.ContractorsListViewModel.metadata;
  }

  public get listOfRegionsMetadata(): IPagination<IRegionListItemEntity> {
    return this.RegionsListViewModel.metadata;
  }

  public get isContractorsLoading(): boolean {
    return this.ContractorsListViewModel.statuses.isInProgress;
  }

  public get isRegionsLoading(): boolean {
    return this.RegionsListViewModel.statuses.isInProgress;
  }

  public get isFuelTransferInProgress(): boolean {
    return this.FuelTransferViewModel.statuses.isInProgress;
  }

  public fetchListOfRegions = (): void => {
    try {
      this.RegionsListViewModel.fetchItemsBatch(
        this.RegionsListViewModel.meta.current_page + 1,
        false
      );
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public fetchListOfContractors = (
    page = this.RegionsListViewModel.meta.current_page + 1,
    refreshing: boolean = false
  ): void => {
    try {
      this.ContractorsListViewModel.fetchItemsBatch(page, refreshing);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setContractor = async (contractor: {
    id: number;
    name: string;
  }): Promise<void> => {
    try {
      // const previousContractorId = this.createContractState.contractor?.id;
      // clear regions if contractor is different

      this.FuelTransferViewModel.setContractor(contractor);

      // if (contractor.id !== previousContractorId) {
      //   this.FuelTransferViewModel.setRegions(null);

      //   this.RegionsListViewModel.setContractorFilter(contractor);

      //   await this.fetchListOfRegions();
      // }
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setRegionFrom = (
    region: { id: number; name: string } | null
  ): void => {
    try {
      this.FuelTransferViewModel.setRegionFrom(region);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setRegionTo = (region: { id: number; name: string } | null): void => {
    try {
      this.FuelTransferViewModel.setRegionTo(region);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setWeight = (weight: number): void => {
    try {
      this.FuelTransferViewModel.setWeight(weight);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  // public setRegions = (regions: { id: number; name: string }[]): void => {
  //   try {
  //     this.FuelTransferViewModel.setRegions(regions);
  //   } catch (error) {
  //     toast.error(getMessageFromError(error));
  //   }
  // };

  public setContractorSearch = (contractor: string): void => {
    try {
      const previousSearchState = this.ContractorsListViewModel.filters.search;

      this.ContractorsListViewModel.setSearch(contractor);

      if (contractor !== previousSearchState) {
        this.ContractorsListViewModel.fetchItemsBatchDebounce(1, true);
      }
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setRegionSearch = (region: string): void => {
    try {
      const previousSearchState = this.RegionsListViewModel.filters.search;

      this.RegionsListViewModel.setSearch(region);

      if (region !== previousSearchState) {
        this.RegionsListViewModel.fetchItemsBatchDebounce(1, true);
      }
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public createFuelTransfer = async (): Promise<void> => {
    try {
      // Transfer fueling
      await this.FuelTransferViewModel.createFuelTransfer(
        this.FuelTransferViewModel.state
      );

      // @ts-ignore
      this.displays.handleResolve({});
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public closeScene = (): void => {
    try {
      this.displays.handleRejects();
    } catch (error) {
      toast.error(Utils.getMessageFromError(error));
    }
  };

  public clearFuelTransfer = (): void => {
    try {
      this.FuelTransferViewModel.clearState();
      this.RegionsListViewModel.clearList();
    } catch (error) {
      toast.error(Utils.getMessageFromError(error));
    }
  };
}
