import {
  IUISceneController,
  TFiltersSceneParams,
  TFiltersSceneResult,
} from "../connector/UISceneController/types";
import Button from "@shared/controls/Button/Button";
import SelectComponent from "@shared/controls/Select/Select";
import { observer } from "mobx-react";
import { useScene } from "@view/hooks/useScene/useScene";
import { TranslationService } from "@services/translate";
import Input from "@shared/controls/Input/Input";
import FilterComponent from "@shared/helpers/SideForm/SideForm";
import ToggleButton from "react-bootstrap/ToggleButton";
import "./style.scss";
import ButtonGroup from "react-bootstrap/esm/ButtonGroup";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "moment/locale/ru";

import moment from "moment";

const FiltersForm = observer((): JSX.Element => {
  const UISceneController =
    useScene<IUISceneController<TFiltersSceneResult, TFiltersSceneParams>>();

  moment.locale("ru");

  return (
    <FilterComponent
      title={TranslationService.t("txt_filter")}
      onClose={UISceneController.closeScene}
    >
      <div className="custom-form">
        {UISceneController.fields.contract ? (
          <div className="custom-field">
            <label>№ договора</label>
            <SelectComponent
              isClearable
              onFocus={() => UISceneController.fetchListOfContracts(1, true)}
              onMenuScrollToBottom={() =>
                UISceneController.fetchListOfContracts()
              }
              options={UISceneController.listOfContractsMetadata.data.map(
                (contract, index) => ({
                  id: contract.id,
                  index,
                  label: contract.number,
                  number: contract.number,
                  value: contract.id,
                })
              )}
              onInputChange={UISceneController.setContractSearch}
              isLoading={UISceneController.isContractsLoading}
              // value={UISceneController.filtersState.contract}
              value={
                UISceneController.filtersState.contract
                  ? {
                      label: UISceneController.filtersState.contract.number,
                      value: UISceneController.filtersState.contract.id,
                    }
                  : null
              }
              onChange={(contract: any) =>
                UISceneController.setContract(contract)
              }
            />
          </div>
        ) : null}
        {UISceneController.fields.pilot ? (
          <div className="custom-field">
            <label>Командир воздушного судна</label>
            <SelectComponent
              isClearable
              onFocus={() => UISceneController.fetchListOfPilots(1, true)}
              onMenuScrollToBottom={() => UISceneController.fetchListOfPilots()}
              options={UISceneController.listOfPilotsMetadata.data.map(
                (pilot, index) => ({
                  id: pilot.id,
                  index,
                  label: pilot.name,
                  name: pilot.name,
                  value: pilot.id,
                })
              )}
              onInputChange={UISceneController.setPilotSearch}
              isLoading={UISceneController.isRegionsLoading}
              // value={UISceneController.filtersState.pilot}
              value={
                UISceneController.filtersState.pilot
                  ? {
                      label: UISceneController.filtersState.pilot.name,
                      value: UISceneController.filtersState.pilot.id,
                    }
                  : null
              }
              onChange={(pilot: any) => UISceneController.setPilot(pilot)}
            />
          </div>
        ) : null}
        {UISceneController.fields.score ? (
          <div className="custom-field">
            <label>№ счета</label>
            <Input
              type="text"
              placeholder="Введите номер счета"
              value={UISceneController.filtersState.score}
              onChange={UISceneController.setScore}
            />
          </div>
        ) : null}
        {UISceneController.fields.fuelingNumber ? (
          <div className="custom-field">
            <label>№ топливного требования</label>
            <Input
              type="text"
              placeholder="Введите номер топливного требования"
              value={UISceneController.filtersState.fuelingNumber}
              onChange={UISceneController.setFuelingNumber}
            />
          </div>
        ) : null}
        {UISceneController.fields.fueling ? (
          <div className="custom-field">
            <label>№ топливного требования</label>
            <SelectComponent
              isClearable
              onFocus={() => UISceneController.fetchListOfFueling(1, true)}
              onMenuScrollToBottom={() =>
                UISceneController.fetchListOfFueling()
              }
              options={UISceneController.listOfFuelingMetadata.data.map(
                (fueling, index) => ({
                  id: fueling.id,
                  index,
                  label: fueling.number,
                  number: fueling.number,
                  value: fueling.id,
                })
              )}
              onInputChange={UISceneController.setFuelingSearch}
              isLoading={UISceneController.isFuelingLoading}
              value={
                UISceneController.filtersState.fueling
                  ? {
                      label: UISceneController.filtersState.fueling.number,
                      value: UISceneController.filtersState.fueling.id,
                    }
                  : null
              }
              onChange={(fueling: any) => UISceneController.setFueling(fueling)}
            />
          </div>
        ) : null}
        {UISceneController.fields.fuelingType ? (
          <div className="custom-field">
            <label htmlFor="fuel-filter">Тип заправки</label>
            <ButtonGroup>
              {[
                { text: "Все", value: 0 },
                { text: "Основная", value: 1 },
                { text: "Перегон", value: 2 },
              ].map((radio, idx) => (
                <ToggleButton
                  key={idx}
                  id={`fuel-filter-${idx}`}
                  type="radio"
                  variant="secondary"
                  name="radio"
                  value={radio.value}
                  checked={
                    UISceneController.filtersState.fuelingType === radio.value
                  }
                  onChange={(event) =>
                    UISceneController.setFuelingType(
                      Number(event.currentTarget.value)
                    )
                  }
                >
                  {radio.text}
                </ToggleButton>
              ))}
            </ButtonGroup>
          </div>
        ) : null}
        {UISceneController.fields.contractor ? (
          <div className="custom-field">
            <label>Поставщик</label>
            <SelectComponent
              isClearable
              onFocus={() => UISceneController.fetchListOfContractors(1, true)}
              onMenuScrollToBottom={() =>
                UISceneController.fetchListOfContractors()
              }
              options={UISceneController.listOfContractorsMetadata.data.map(
                (contractor, index) => ({
                  id: contractor.id,
                  index,
                  label: contractor.name,
                  name: contractor.name,
                  value: contractor.id,
                })
              )}
              onInputChange={UISceneController.setContractorSearch}
              isLoading={UISceneController.isContractorsLoading}
              // value={UISceneController.filtersState.contractor}
              value={
                UISceneController.filtersState.contractor
                  ? {
                      label: UISceneController.filtersState.contractor.name,
                      value: UISceneController.filtersState.contractor.id,
                    }
                  : null
              }
              onChange={(contractor: any) =>
                UISceneController.setContractor(contractor)
              }
            />
          </div>
        ) : null}
        {UISceneController.fields.conclusionDate ? (
          <div className="custom-field">
            <label>Дата заключения</label>
            <DateRangePicker
              clearIcon
              value={
                UISceneController.filtersState.conclusionDate
                  ? [
                      moment(
                        UISceneController.filtersState.conclusionDate?.date_from
                      ).toDate(),
                      moment(
                        UISceneController.filtersState.conclusionDate?.date_to
                      ).toDate(),
                    ]
                  : null
              }
              selectRange={true}
              locale={"ru-RU"}
              next2Label={null}
              prev2Label={null}
              view={"month"}
              onChange={(dates) => {
                if (Array.isArray(dates) && dates[0] && dates[1]) {
                  UISceneController.setConclusionDate({
                    date_from: moment(dates[0]).unix() * 1000,
                    date_to: moment(dates[1]).unix() * 1000,
                  });
                }
              }}
            />
          </div>
        ) : null}

        {UISceneController.fields.termDate ? (
          <div className="custom-field">
            <label>Срок договора</label>
            <DateRangePicker
              clearIcon
              value={
                UISceneController.filtersState.termDate
                  ? [
                      moment(
                        UISceneController.filtersState.termDate?.date_from
                      ).toDate(),
                      moment(
                        UISceneController.filtersState.termDate?.date_to
                      ).toDate(),
                    ]
                  : null
              }
              selectRange={true}
              locale={"ru-RU"}
              view={"month"}
              next2Label={null}
              prev2Label={null}
              onChange={(dates: any) => {
                if (Array.isArray(dates) && dates[0] && dates[1]) {
                  UISceneController.setTermDate({
                    date_from: moment(dates[0]).unix() * 1000,
                    date_to: moment(dates[1]).unix() * 1000,
                  });
                }
              }}
            />
          </div>
        ) : null}

        {UISceneController.fields.vehicle ? (
          <div className="custom-field">
            <label>№ борта</label>
            <SelectComponent
              isClearable
              onFocus={() => UISceneController.fetchListOfVehicles(1, true)}
              onMenuScrollToBottom={() =>
                UISceneController.fetchListOfVehicles()
              }
              options={UISceneController.listOfVehiclesMetadata.data.map(
                (vehicle, index) => ({
                  id: vehicle.id,
                  index,
                  label: vehicle.number,
                  number: vehicle.number,
                  value: vehicle.id,
                })
              )}
              onInputChange={UISceneController.setVehiclesSearch}
              isLoading={UISceneController.isVehiclesLoading}
              // value={UISceneController.filtersState.vehicle}
              value={
                UISceneController.filtersState.vehicle
                  ? {
                      label: UISceneController.filtersState.vehicle.number,
                      value: UISceneController.filtersState.vehicle.id,
                    }
                  : null
              }
              onChange={(vehicle: any) => UISceneController.setVehicle(vehicle)}
            />
          </div>
        ) : null}
        {UISceneController.fields.region ? (
          <div className="custom-field">
            <label>Регион</label>
            <SelectComponent
              isClearable
              onFocus={() => UISceneController.fetchListOfRegions(1, true)}
              onMenuScrollToBottom={() =>
                UISceneController.fetchListOfRegions()
              }
              options={UISceneController.listOfRegionsMetadata.data.map(
                (region, index) => ({
                  id: region.id,
                  index,
                  label: region.name,
                  name: region.name,
                  value: region.id,
                })
              )}
              onInputChange={UISceneController.setRegionSearch}
              isLoading={UISceneController.isRegionsLoading}
              // value={UISceneController.filtersState.region}
              value={
                UISceneController.filtersState.region
                  ? {
                      label: UISceneController.filtersState.region.name,
                      value: UISceneController.filtersState.region.id,
                    }
                  : null
              }
              onChange={(region: any) => UISceneController.setRegion(region)}
            />
          </div>
        ) : null}
      </div>

      <div className="row">
        <Button
          text={"Сбросить"}
          type={"button"}
          className={"secondary"}
          size="small"
          onClick={UISceneController.clearFiltersState}
        />
        <Button
          text={TranslationService.t("txt_apply")}
          type={"button"}
          className={"primary"}
          size="small"
          onClick={UISceneController.applyFilters}
        />
      </div>
    </FilterComponent>
  );
});

export default FiltersForm;
