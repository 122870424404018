import React, { ReactNode, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

type Props = {
  from: string;
  to: string;
};

export const Redirect = (props: Props): ReactNode => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === props.from || props.from === "*") {
      navigate(props.to);
    }
  }, [location]);

  return <React.Fragment></React.Fragment>;
};
