import { TDtoFuelingListItemEntry } from "./__types__/TDtoFuelingListItemEntry.types";
import { TParseDto } from "../__types__/dto";
import { Utils } from "../..";

export const DtoFuelingListItemParser: TParseDto<TDtoFuelingListItemEntry> = (
  data: any
): TDtoFuelingListItemEntry => ({
  contract: data.contract
    ? {
        conclusionDate: Utils.isString(data.contract.conclusionDate)
          ? data.contract.conclusionDate
          : "",
        id: data.contract.id ? Number(data.contract.id) : 0,
        number: Utils.isString(data.contract.number)
          ? data.contract.number
          : "",
      }
    : null,
  contractor: {
    id: Number(data.contractor.id),
    name: Utils.isString(data.contractor.name) ? data.contractor.name : "",
    // regions: Array.isArray(data.regions)
    //   ? data.regions.filter(Boolean).map((region: any) => Number(region))
    //   : [],
  },
  createdAt: Utils.isString(data.createdAt) ? data.createdAt : "",
  fuelingType: Number(data.fuelingType),
  id: Number(data.id),
  number: Utils.isString(data.number) ? data.number : "",
  pilot: data.createdBy
    ? {
        id: Number(data.createdBy.id),
        name: [
          data.createdBy.lastName,
          data.createdBy.firstName ? `${data.createdBy.firstName[0]}.` : "",
          data.createdBy.middleName ? `${data.createdBy.middleName[0]}.` : "",
        ].join(" "),
      }
    : null,
  region: {
    // contractors: Array.isArray(data.contractors)
    //   ? data.contractors
    //       .filter(Boolean)
    //       .map((contract: any) => Number(contract))
    //   : [],
    id: Number(data.region.id),
    name: Utils.isString(data.region.name) ? data.region.name : "",
  },
  vehicle: {
    id: Number(data.vehicle.id),
    number: Utils.isString(data.vehicle.number) ? data.vehicle.number : "",
  },
  volume: data.volume ? String(data.volume) : "0",
  weight: data.weight ? String(data.weight) : "0",
});
