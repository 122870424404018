type Props = {
  onClick?: () => void;
};

const IconBurger = ({ onClick }: Props): JSX.Element => (
  <svg
    onClick={onClick}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.3332 10.0007C18.3332 9.68632 18.2077 9.38486 17.9845 9.1626C17.7612 8.94033 17.4584 8.81547 17.1427 8.81547H2.85698C2.54125 8.81547 2.23844 8.94033 2.01519 9.1626C1.79193 9.38486 1.6665 9.68632 1.6665 10.0007C1.6665 10.315 1.79193 10.6164 2.01519 10.8387C2.23844 11.061 2.54125 11.1858 2.85698 11.1858H17.1427C17.4584 11.1858 17.7612 11.061 17.9845 10.8387C18.2077 10.6164 18.3332 10.315 18.3332 10.0007Z"
      fill="#303B57"
    />
    <path
      d="M2.85698 14.2969C2.54125 14.2969 2.23844 14.4218 2.01519 14.6441C1.79193 14.8663 1.6665 15.1678 1.6665 15.4821C1.6665 15.7965 1.79193 16.0979 2.01519 16.3202C2.23844 16.5425 2.54125 16.6673 2.85698 16.6673H17.1427C17.4584 16.6673 17.7612 16.5425 17.9845 16.3202C18.2077 16.0979 18.3332 15.7965 18.3332 15.4821C18.3332 15.1678 18.2077 14.8663 17.9845 14.6441C17.7612 14.4218 17.4584 14.2969 17.1427 14.2969H2.85698Z"
      fill="#303B57"
    />
    <path
      d="M1.6665 4.51917C1.6665 4.8335 1.79193 5.13496 2.01519 5.35722C2.23844 5.57949 2.54125 5.70435 2.85698 5.70435H17.1427C17.4584 5.70435 17.7612 5.57949 17.9845 5.35722C18.2077 5.13496 18.3332 4.8335 18.3332 4.51917C18.3332 4.20484 18.2077 3.90338 17.9845 3.68112C17.7612 3.45885 17.4584 3.33398 17.1427 3.33398H2.85698C2.54125 3.33398 2.23844 3.45885 2.01519 3.68112C1.79193 3.90338 1.6665 4.20484 1.6665 4.51917Z"
      fill="#303B57"
    />
  </svg>
);

export default IconBurger;
