import FuelTransferForm from "./components/FuelTransferForm";
import { observer } from "mobx-react";
import React from "react";
import "./styles.scss";

const FuelTransferScene = observer(() => (
  // const UISceneController =
  //   useScene<
  //     IUISceneController<TCreateContractSceneResult, TCreateContractSceneParams>
  //   >();

  <div className="contract-create-scene">
    <FuelTransferForm />
  </div>
));

export default FuelTransferScene;
