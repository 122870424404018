// import { TranslationService } from "@services/translate";
import {
  IUISceneController,
  TCreateContractSceneParams,
  TCreateContractSceneResult,
} from "../connector/UISceneController/types";
import SelectComponent from "@shared/controls/Select/Select";
import { useEffect } from "react";
import Input from "@shared/controls/Input/Input";
import { observer } from "mobx-react";
import { useScene } from "@view/hooks/useScene/useScene";
import moment from "moment";
import FilterComponent from "@shared/helpers/SideForm/SideForm";
import Button from "@shared/controls/Button/Button";
import { TranslationService } from "@services/translate";
import styled from "styled-components";

const CreateContractForm = observer((): JSX.Element => {
  const UISceneController =
    useScene<
      IUISceneController<TCreateContractSceneResult, TCreateContractSceneParams>
    >();

  useEffect(() => {
    // Load list of contractors on mount
    UISceneController.fetchListOfContractors();
    UISceneController.fetchListOfRegions();
  }, []);

  return (
    <FilterComponent
      title="Новый договор"
      onClose={UISceneController.closeScene}
    >
      <div className="custom-form">
        <div className="custom-field">
          <label>№ договора</label>
          <Input
            placeholder="Введите номер договора"
            value={UISceneController.createContractState.number}
            type="string"
            onChange={(value: string) =>
              UISceneController.setContractNumber(value)
            }
          />
        </div>
        <div className="custom-field">
          <label>Дата заключения</label>
          <Input
            placeholder="дд.мм.гг"
            value={UISceneController.createContractState.conclusionDate.format(
              "YYYY-MM-DD"
            )}
            type="date"
            onChange={(value: string) =>
              UISceneController.setConclusionDate(moment(value, "YYYY-MM-DD"))
            }
          />
        </div>
        <div className="custom-field">
          <label>Срок договора</label>
          <Input
            placeholder="дд.мм.гг"
            value={UISceneController.createContractState.termDate.format(
              "YYYY-MM-DD"
            )}
            type="date"
            onChange={(value: string) =>
              UISceneController.setTermDate(moment(value, "YYYY-MM-DD"))
            }
          />
        </div>
        <div className="custom-field">
          <label>Поставщик</label>
          <SelectComponent
            onInputChange={UISceneController.setContractorSearch}
            isClearable
            onMenuScrollToBottom={() =>
              UISceneController.fetchListOfContracts()
            }
            options={UISceneController.listOfContractorsMetadata.data.map(
              (contractor, index) => ({
                id: contractor.id,
                index,
                label: contractor.name,
                name: contractor.name,
                value: contractor.id,
              })
            )}
            isLoading={UISceneController.isContractorsLoading}
            value={
              UISceneController.createContractState.contractor
                ? {
                    label:
                      UISceneController.createContractState.contractor.name,
                    value: UISceneController.createContractState.contractor.id,
                  }
                : null
            }
            onChange={(contractor: any) =>
              UISceneController.setContractor(contractor)
            }
          />
        </div>
        <div className="custom-field">
          <label>Регион</label>
          <SelectComponent
            onInputChange={UISceneController.setRegionSearch}
            isClearable
            onMenuScrollToBottom={() => UISceneController.fetchListOfRegions()}
            options={UISceneController.listOfRegionsMetadata.data.map(
              (region, index) => ({
                id: region.id,
                index,
                label: region.name,
                name: region.name,
                value: region.id,
              })
            )}
            isMulti
            // isDisabled={!UISceneController.createContractState.contractor}
            isLoading={UISceneController.isRegionsLoading}
            value={
              UISceneController.createContractState.regions?.map(
                (region: any) => ({
                  id: region.id,
                  label: region.name,

                  name: region.name,
                  value: region.id,
                })
              ) || []
            }
            onChange={(region: any) => UISceneController.setRegions(region)}
          />
        </div>
      </div>

      <div className="col gap8">
        <Button
          text={TranslationService.t("txt_add")}
          type={"button"}
          className={"primary"}
          size="small"
          disabled={
            UISceneController.createContractState.conclusionDate &&
            UISceneController.createContractState.contractor?.id &&
            UISceneController.createContractState.number &&
            UISceneController.createContractState.regions?.length &&
            UISceneController.createContractState.termDate
              ? false
              : true
          }
          onClick={UISceneController.createContract}
        />
        {UISceneController.createContractState.conclusionDate &&
        UISceneController.createContractState.contractor?.id &&
        UISceneController.createContractState.number &&
        UISceneController.createContractState.regions?.length &&
        UISceneController.createContractState.termDate ? null : (
          <Hint>{TranslationService.t("txt_create_contract_hint")}</Hint>
        )}
      </div>
    </FilterComponent>
  );
});

export default CreateContractForm;

const Hint = styled.p`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
`;
