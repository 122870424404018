import Joi from "joi";

export const DtoFuelingListItemScheme = Joi.object({
  contract: Joi.object({
    conclusionDate: Joi.string().required().allow(""),
    id: Joi.number().required(),
    number: Joi.string().required().allow(""),
  }).allow(null),
  contractor: {
    id: Joi.number().required(),
    name: Joi.string().required(),
    // regions: Joi.array().items(Joi.number()).required(),
  },
  createdAt: Joi.string().required(),
  fuelingType: Joi.number().required(),
  id: Joi.number().required(),
  number: Joi.string().required(),
  pilot: Joi.object({
    id: Joi.number().required(),
    name: Joi.string().required().allow(""),
  }).allow(null),
  region: {
    // contractors: Joi.array().items(Joi.number()).required().allow(""),
    id: Joi.number().required(),
    name: Joi.string().required().allow(""),
  },
  vehicle: {
    id: Joi.number().required(),
    number: Joi.string().required(),
  },
  volume: Joi.string().required(),
  weight: Joi.string().required(),
});
