const IconComment = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.07815 0.951388C1.3543 0.951388 1.57815 1.17525 1.57815 1.45139L1.57815 8.9386C1.57815 9.21474 1.3543 9.4386 1.07815 9.4386C0.802012 9.4386 0.578154 9.21474 0.578154 8.9386L0.578154 1.45139C0.578154 1.17525 0.802012 0.951388 1.07815 0.951388Z"
      fill="#303B57"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.94556 1.55579C7.46938 1.60998 8.0118 1.50906 8.6219 1.05149C8.77341 0.937856 8.97611 0.919578 9.1455 1.00427C9.3149 1.08897 9.4219 1.2621 9.4219 1.45149L9.4219 6.79949C9.4219 6.95687 9.3478 7.10507 9.2219 7.19949C8.40586 7.81152 7.61127 7.978 6.84266 7.89849C6.12031 7.82376 5.43682 7.53076 4.83536 7.27291L4.80307 7.25907C4.16444 6.98537 3.61161 6.75282 3.05449 6.69519C2.53067 6.641 1.98825 6.74192 1.37815 7.19949C1.15724 7.36518 0.84384 7.32041 0.678154 7.09949C0.512469 6.87858 0.55724 6.56518 0.778154 6.39949C1.59419 5.78746 2.38878 5.62099 3.15739 5.7005C3.87974 5.77522 4.56322 6.06823 5.16469 6.32608C5.17548 6.3307 5.18625 6.33532 5.19698 6.33992C5.83561 6.61362 6.38844 6.84616 6.94556 6.9038C7.41145 6.95199 7.89205 6.87749 8.4219 6.53849V2.31871C7.88472 2.54655 7.35786 2.60378 6.84266 2.55048C6.12031 2.47576 5.43682 2.18275 4.83536 1.9249C4.82457 1.92028 4.8138 1.91566 4.80307 1.91106C4.16444 1.63736 3.61161 1.40482 3.05449 1.34718C2.53067 1.29299 1.98825 1.39392 1.37815 1.85149C1.15724 2.01717 0.84384 1.9724 0.678154 1.75149C0.512469 1.53057 0.55724 1.21717 0.778154 1.05149C1.59419 0.439458 2.38878 0.272979 3.15739 0.352491C3.87974 0.427217 4.56323 0.720225 5.16469 0.978071C5.17548 0.982697 5.18625 0.987313 5.19698 0.991915C5.83561 1.26561 6.38844 1.49816 6.94556 1.55579Z"
      fill="#303B57"
    />
  </svg>
);

export default IconComment;
