import { Back } from "../../../AuthorizationScreen/components/ForgetPassword/ForgetPassword";
import { TranslationService } from "@services/translate";
import Button from "@shared/controls/Button/Button";
import Input from "@shared/controls/Input/Input";
import {
  IconArrowLeftLong,
  IconCheck,
  IconCross,
} from "@shared/controls/icons";
import { observer } from "mobx-react";
import { useCallback, useState } from "react";
import styled from "styled-components";
import { useControllers } from "@view/hooks/useControllers";
import { IScreenControllerAuth } from "@screens/AuthorizationScreen/connector/__types__/UIViewController";
import { useNavigate, useParams } from "react-router";

export const NewPassword = observer((): JSX.Element => {
  const { UIViewController } = useControllers<{
    UIViewController: IScreenControllerAuth;
  }>();

  // const defaultStatus = 0;
  // const errorStatus = 1;
  // const successStatus = 2;

  const [status, setStatus] = useState(0);

  const [errorPasswordNotEqual, setErrorPasswordNotEqual] = useState(false);

  const history = useNavigate();

  const params = useParams();

  const onSubmit = useCallback((event: any) => {
    event.preventDefault();
    UIViewController.submitPlainPassword(
      params.token || "",
      UIViewController.plainPassword
    );
  }, []);

  return (
    <form onSubmit={onSubmit}>
      <Back className="row" onClick={() => history("/")}>
        <IconArrowLeftLong />
        <span>{TranslationService.t("txt_back")}</span>
      </Back>
      <div className="container col">
        <h2>{TranslationService.t("txt_new_password")}</h2>
        <div className="col gap10">
          <div
            className={`customField ${
              status === 1 || errorPasswordNotEqual ? "error" : ""
            }`}
          >
            <label>{TranslationService.t("txt_password_field")}</label>
            <Input
              type={"password"}
              placeholder={TranslationService.t("txt_enter_new_password")}
              value={UIViewController.plainPassword}
              onChange={UIViewController.setPlainPassword}
            />
            {status === 1 ? (
              <p className="error">
                {TranslationService.t("txt_error_password_validation")}
              </p>
            ) : null}
          </div>
          <Settings
            className={`row ${
              status === 1 ? "error" : status === 2 ? "success" : ""
            }`}
          >
            {[]}
            <Option>
              {status === 1 ? (
                <IconCross />
              ) : status === 2 ? (
                <IconCheck />
              ) : (
                <DefaultPoint />
              )}
              {TranslationService.t("txt_validation_symbols")}
            </Option>
            <Option>
              {status === 1 ? (
                <IconCross />
              ) : status === 2 ? (
                <IconCheck />
              ) : (
                <DefaultPoint />
              )}
              {TranslationService.t("txt_validation_numbers")}
            </Option>
            <Option>
              {status === 1 ? (
                <IconCross />
              ) : status === 2 ? (
                <IconCheck />
              ) : (
                <DefaultPoint />
              )}
              {TranslationService.t("txt_validation_big_letter")}
            </Option>
          </Settings>
          <div
            className={`customField ${errorPasswordNotEqual ? "error" : ""}`}
          >
            <label>{TranslationService.t("txt_password_repeat_label")}</label>
            <div className="col w-100">
              <Input
                type={"password"}
                placeholder={TranslationService.t("txt_password_repeat")}
                value={UIViewController.plainPasswordRepeat}
                onChange={UIViewController.setPlainPasswordRepeat}
              />
              {errorPasswordNotEqual ? (
                <p className="error">
                  {TranslationService.t("txt_error_password_not_equal")}
                </p>
              ) : null}
            </div>
          </div>
        </div>
        <Button
          text={TranslationService.t("txt_change")}
          type="submit"
          className="primary"
          size="small"
          disabled={
            !UIViewController.plainPassword ||
            !UIViewController.plainPasswordRepeat
          }
        />
      </div>
    </form>
  );
});

const Settings = styled.div`
  gap: 10px;
  padding-bottom: 22px;
  justify-content: space-between;

  svg {
    width: 16px;
    height: 16px;
  }

  svg path {
    fill: #ef5d58;
  }

  .success svg path {
    fill: #48b415;
  }
`;

const Option = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

const DefaultPoint = styled.div`
  content: "";
  background: #303b57;
  width: 13px;
  height: 13px;
  border-radius: 50%;
`;
