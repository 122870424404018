// import { TranslationService } from "@services/translate";
import {
  IUISceneController,
  TFuelingRequestSceneParams,
  TFuelingRequestSceneResult,
} from "../connector/UISceneController/types";
import Button from "@shared/controls/Button/Button";
import { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useScene } from "@view/hooks/useScene/useScene";
import { TranslationService } from "@services/translate";
import FilterComponent from "@shared/helpers/SideForm/SideForm";
import { useDropzone } from "react-dropzone";
import "./style.scss";
import styled from "styled-components";
import { IconAddFile, IconTrash } from "@shared/controls/icons";
import Modal from "react-bootstrap/Modal";

const FuelingRequestCommentForm = observer((): JSX.Element => {
  const UISceneController =
    useScene<
      IUISceneController<TFuelingRequestSceneResult, TFuelingRequestSceneParams>
    >();

  const [files, setFiles] = useState<any>([]);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    // accept: "image/*",
    accept: {
      "image/*": [],
    },
    multiple: false,
    onDrop: (acceptedFiles) => {
      UISceneController.setFile(acceptedFiles[0]);
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const removeFile = useCallback(() => {
    UISceneController.setFile(undefined);
    setFiles([]);
  }, []);

  // Fetch initial data to the form fields
  useEffect(() => {}, []);

  return (
    <>
      <FilterComponent
        title="Новая заявка"
        class="dropzone"
        onClose={UISceneController.closeScene}
      >
        <div className="custom-form">
          <div className="custom-field">
            <textarea
              placeholder={TranslationService.t(
                "txt_fueling_request_comment_placeholder"
              )}
              maxLength={256}
              onChange={(event) =>
                UISceneController.setComment(event.target.value)
              }
            />
          </div>

          <div
            className={`custom-field dropzone-custom-field ${
              files.length > 0 && "full-dropzone-custom-field"
            }`}
          >
            {files.length > 0 ? (
              <aside>
                <div key={files[0]?.name}>
                  <Row>
                    <img
                      src={files[0]?.preview}
                      onLoad={() => {
                        URL.revokeObjectURL(files[0]?.preview);
                      }}
                    />
                    <div className="col">
                      <p>{files[0]?.name}</p>
                      <p>{files[0]?.type.split("/")[1]}</p>
                    </div>
                    <Trash onClick={removeFile}>
                      <IconTrash />
                    </Trash>
                  </Row>
                </div>
              </aside>
            ) : null}
            <div {...getRootProps()}>
              {!files.length && (
                <>
                  <input {...getInputProps()} type={"file"} />
                  <RowPreview>
                    <IconAddFile />
                    <p>Перетащите или нажмите, чтобы прикрепить фото</p>
                  </RowPreview>
                </>
              )}
            </div>
            <span>
              Одно фото. Форматы: PNG, JPEG. Максимальный размер файла: 10Мб.
            </span>
          </div>
        </div>

        <div className="col">
          <Button
            text={TranslationService.t("txt_send")}
            type={"button"}
            className={"primary"}
            size="small"
            loading={UISceneController.isRequestInProgress}
            onClick={UISceneController.createFuelingComment}
            disabled={!UISceneController.state.comment}
          />
          {!UISceneController.state.comment ? (
            <p>Напишите комментарий</p>
          ) : null}
        </div>
      </FilterComponent>
    </>
  );
});

export default FuelingRequestCommentForm;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
`;

const Trash = styled.div`
  background: #fcfcfd;
  border-radius: 50%;
  width: 20px !important;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RowPreview = styled(Row)`
  align-items: center;
  gap: 8px;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
`;

const CustomModal = styled(Modal)`
  .modal-dialog {
    max-width: 100%;
    padding: 20px;
  }

  .modal-dialog-centered {
    height: calc(100% - 40px);
  }

  .modal.fade .modal-dialog {
    transform: none;
  }

  .modal-content {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }

    .modal-body {
      width: 100%;
    }

    img {
      max-width: 100%;
      ax-height: 100%;
    }
  }
`;
