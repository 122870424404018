const IconDocs = (): JSX.Element => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.1219 2.91564C17.8325 2.62446 17.4882 2.3936 17.1089 2.23643C16.7297 2.07926 16.323 1.9989 15.9125 2.00001H5.75C4.75544 2.00001 3.80161 2.3951 3.09835 3.09836C2.39509 3.80162 2 4.75545 2 5.75001V18.25C2 19.2446 2.39509 20.1984 3.09835 20.9017C3.80161 21.6049 4.75544 22 5.75 22H18.25C19.2445 22 20.1984 21.6049 20.9016 20.9017C21.6049 20.1984 22 19.2446 22 18.25V8.09063C22.0013 7.67961 21.9211 7.2724 21.7639 6.89261C21.6068 6.51282 21.3758 6.168 21.0844 5.87813L18.1219 2.91564ZM19.5 18.25C19.5 18.5815 19.3683 18.8995 19.1339 19.1339C18.8995 19.3683 18.5815 19.5 18.25 19.5H5.75C5.41848 19.5 5.10053 19.3683 4.86611 19.1339C4.63169 18.8995 4.5 18.5815 4.5 18.25V5.75001C4.5 5.41849 4.63169 5.10055 4.86611 4.86613C5.10053 4.63171 5.41848 4.50001 5.75 4.50001H15.9125C16.078 4.50071 16.2364 4.56701 16.3531 4.68438L19.3156 7.64688C19.3741 7.70503 19.4205 7.77417 19.4521 7.85033C19.4838 7.92649 19.5001 8.00816 19.5 8.09063V18.25Z"
      fill="#303B57"
    />
    <path
      d="M10.75 14.5H8.24999C7.91847 14.5 7.60053 14.6317 7.36611 14.8661C7.13169 15.1005 7 15.4185 7 15.75C7 16.0815 7.13169 16.3995 7.36611 16.6339C7.60053 16.8683 7.91847 17 8.24999 17H10.75C11.0815 17 11.3995 16.8683 11.6339 16.6339C11.8683 16.3995 12 16.0815 12 15.75C12 15.4185 11.8683 15.1005 11.6339 14.8661C11.3995 14.6317 11.0815 14.5 10.75 14.5Z"
      fill="#303B57"
    />
  </svg>
);

export default IconDocs;
