import { IScreenControllerFuelingComments } from "@screens/FuelingCommentsScreen/connector/__types__/UIViewController";
import Pagination from "@shared/controls/Pagination/Pagination";
import { useControllers } from "@view/hooks/useControllers";
import { observer } from "mobx-react";
import React from "react";

export const CommentsPagination = observer((): JSX.Element => {
  const { UIViewController } = useControllers<{
    UIViewController: IScreenControllerFuelingComments;
  }>();

  return (
    <Pagination
      count={UIViewController.listOfFuelingCommentsMetadata.data.length}
      total={UIViewController.listOfFuelingCommentsMetadata.meta.total}
      per_page={UIViewController.listOfFuelingCommentsMetadata.meta.per_page}
      loading={UIViewController.isRequestInProgress}
      onLoad={UIViewController.fetchListOfFuelingComments}
    />
  );
});
