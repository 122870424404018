import Joi from "joi";

export const DtoContractListScheme = Joi.array().items(
  Joi.object({
    conclusionDate: Joi.string().required(),
    contractor: {
      id: Joi.number().required(),
      name: Joi.string().required(),
      regions: Joi.array().items({
        contractors: Joi.array().items(Joi.string().allow("")),
        id: Joi.number().required(),
        name: Joi.string().required().allow(""),
      }),
    },
    id: Joi.number().required(),
    number: Joi.string().required(),
    region: {
      contractors: Joi.array().items(Joi.string().allow("")),
      id: Joi.number().required(),
      name: Joi.string().required().allow(""),
    },
    termDate: Joi.string().required(),
  })
);
