import {
  ITranslationService,
  TLanguageObject,
  TTranslate,
  TxKeyPath,
} from "./__types__";
import { i18n } from "@modules/i18n";
import { LANGUAGE_CODES } from "@constants/system/language";
import common from "@assets/locales/common.json";

import { TranslateOptions } from "i18n-js/typings";
import moment from "moment";

i18n.defaultLocale = LANGUAGE_CODES.RU;
i18n.locale = LANGUAGE_CODES.RU;

moment.locale("ru", {
  week: {
    dow: 8,
  },
});

export class TranslationService implements ITranslationService {
  public static get languages(): TLanguageObject[] {
    return common.languages as TLanguageObject[];
  }

  public static t(
    key: TxKeyPath | undefined | null,
    options?: TranslateOptions
  ): string {
    if (!key) {
      return "";
    }
    return key ? i18n.t(key, options) : "";
  }

  public static getTranslate(locale?: TTranslate | string | null): string {
    if (!locale) return "";

    if (typeof locale === "string") {
      return locale;
    }

    return locale?.en || "";
  }

  public static setupMomentLocale = (): void => {
    moment.locale(i18n.locale, {
      postformat: (string: any) => string,
      preparse: (string: any) => string,
      week: {
        dow: 0,
      },
    });
  };
}
