import { TDtoNotificationListItemEntry } from "./__types__/TDtoNotificationListItemEntry.types";
import { TParseDto } from "../__types__/dto";
import { Utils } from "@modules/utils";
import { NotificationType } from "@constants/notifications";
import moment from "moment";

type TypesMap = { [key: string]: string };
const typesMap = Object.entries(NotificationType).reduce(
  (acc: TypesMap, [key, value]) => {
    acc[value] = key;
    return acc;
  },
  {} as TypesMap
);

export const DtoNotificationListItemParser: TParseDto<
  TDtoNotificationListItemEntry
> = (data: any): TDtoNotificationListItemEntry => ({
  dt: data.dt ? data.dt : moment().toDate(),
  id: data.id,
  message: Utils.isString(data.message) ? data.message : "",
  type: typesMap[data.type]
    ? // @ts-ignore
      NotificationType[typesMap[data.type]]
    : NotificationType.Comment,
});
