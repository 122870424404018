import { IScreenControllerFuelingComments } from "@screens/FuelingCommentsScreen/connector/__types__/UIViewController";
import { Sorting } from "@shared/controls/Sorting/Sorting";
import { useControllers } from "@view/hooks/useControllers";
import { observer } from "mobx-react";

export const SortingMobile = observer((): JSX.Element => {
  const { UIViewController } = useControllers<{
    UIViewController: IScreenControllerFuelingComments;
  }>();

  return (
    <Sorting
      list={[
        ["По номеру", "id", "Сначала первый", "Сначала последний"],
        ["По дате поставки", "createdAt", "Сначала ранние", "Сначала поздние"],
      ]}
      sortBy={UIViewController.listOfFuelingCommentsFilters.order}
      sortDir={UIViewController.listOfFuelingCommentsFilters.direction}
      onChangeOrder={UIViewController.setFieldOrderBy}
    />
  );
});
