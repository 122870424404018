import { TDtoFuelingListEntry } from "./__types__/TDtoFuelingListEntry.types";
import { TParseDto } from "../__types__/dto";
import { Utils } from "../..";

export const DtoFuelingListParser: TParseDto<TDtoFuelingListEntry> = (
  data: any
): TDtoFuelingListEntry =>
  Array.isArray(data.list)
    ? data.list.map((listElement: any) => ({
        contractor: {
          id: Number(listElement.contractor.id),
          name: Utils.isString(listElement.contractor.name)
            ? listElement.contractor.name
            : "",
          region: {
            contractors: Array.isArray(listElement.region.contractors)
              ? listElement.region.map((element: any) =>
                  Utils.isString(element) ? element : ""
                )
              : [],
            id: Utils.isString(listElement.region.id)
              ? listElement.region.id
              : "",
            name: Utils.isString(listElement.region.name)
              ? listElement.region.name
              : "",
          },
        },
        createdAt: Utils.isString(listElement.createdAt)
          ? listElement.createdAt
          : "",
        fuelingType: Number(listElement.fuelingType),
        id: Number(data.id),
        number: Utils.isString(listElement.number) ? listElement.number : "",
        region: {
          contractors: Array.isArray(listElement.region.contractors)
            ? listElement.region.map((element: any) =>
                Utils.isString(element) ? element : ""
              )
            : [],
          id: Number(listElement.region.id),
          name: Utils.isString(listElement.region.name)
            ? listElement.region.name
            : "",
        },
        vehicle: {
          id: Number(listElement.vehicle.id),
          number: Utils.isString(listElement.vehicle.number)
            ? listElement.vehicle.number
            : "",
        },
        volume: Utils.isString(listElement.volume) ? listElement.volume : "",
        weight: Utils.isString(listElement.weight) ? listElement.weight : "",
      }))
    : [];
