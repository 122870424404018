import { ScreenControllerFuelingCreate } from "./connector/UIViewController";
import FuelingCreateForm from "./components/FuelingRequestForm";
import { observer } from "mobx-react";
import React from "react";
import "./styles.scss";
import withControllers from "@view/providers/__proto__/withController";
import { TranslationService } from "@services/translate";
import { Helmet } from "react-helmet";
import styled from "styled-components";

const FuelingCreateScreen = observer(() => (
  <Col32 className="col">
    <Helmet>
      <title>{TranslationService.t("txt_new_fueling")}</title>
    </Helmet>
    <h2>{TranslationService.t("txt_new_fueling")}</h2>
    <ColParameters>
      <FuelingCreateForm />
    </ColParameters>
  </Col32>
));

export default withControllers(ScreenControllerFuelingCreate)(
  FuelingCreateScreen
);

const Col32 = styled.div`
  gap: 32px;
  height: 100%;
`;

const ColParameters = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 316px;

  .btn {
    border-radius: 6px !important;
  }

  @media (max-width: 768px) {
    max-width: 100%;

    .btn-group {
      border-radius: 6px;
      border: 1px solid #dbdce1;
      flex-direction: row;
    }
  }

  .custom-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .custom-field {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    gap: 6px;
  }

  .custom-select {
    max-width: 325px;
    width: 100%;

    @media (max-width: 768px) {
      max-width: 100%;
    }
  }

  @media (max-width: 768px) {
    height: 100%;
  }
`;

const RowGap = styled.div`
  gap: 14px;
`;
