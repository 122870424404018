import { CommentsTable } from "./components/Table/Table";
import { CommentsPagination } from "./components/Pagination/Pagination";
import { ScreenControllerFuelingComments } from "./connector/UIViewController";
import { SortingMobile } from "./components/SortingMobile/SortingMobile";
import styled from "styled-components";
import { observer } from "mobx-react";
import { TranslationService } from "@services/translate";
import withControllers from "@view/providers/__proto__/withController";
import { useWindowWidth } from "@view/hooks/useWindowWidth";
import { Helmet } from "react-helmet";

const FuelingCommentsScreen = observer((): JSX.Element => {
  const windowWidth = useWindowWidth();

  return (
    <Col32 className="col">
      <Helmet>
        <title>{TranslationService.t("txt_title_application")}</title>
      </Helmet>
      <h2>{TranslationService.t("txt_title_application")}</h2>
      <ColParameters>
        <div className="row justify-between align-start">
          {windowWidth <= 1024 ? (
            <>
              <SortingMobile />
            </>
          ) : null}
        </div>
        <CommentsTable />
      </ColParameters>
      <CommentsPagination />
    </Col32>
  );
});

export default withControllers(ScreenControllerFuelingComments)(
  FuelingCommentsScreen
);

const Col32 = styled.div`
  gap: 32px;
  height: 100%;
`;

const ColParameters = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  .custom-select {
    max-width: 325px;
  }

  @media (max-width: 768px) {
    height: 100%;
  }
`;

const RowGap = styled.div`
  gap: 14px;
`;
