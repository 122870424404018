import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";

const CommentFuelingFileListItemEntity = types.model({
  mime: types.optional(types.string, ""),
  size: types.number,
  url: types.optional(types.string, ""),
});

export const FuelingCommentListItemEntity = types
  .model({
    comment: types.optional(types.string, ""),
    file: types.optional(CommentFuelingFileListItemEntity, {
      mime: "",
      size: 0,
      url: "",
    }),
    fuelingRequest: types.maybeNull(
      types.model({
        // comment: types.optional(types.array(types.number), []),
        contractor: types.maybeNull(
          types.model({
            id: types.number,
            name: types.optional(types.string, ""),
            regions: types.optional(types.array(types.number), []),
            transfers: types.optional(types.array(types.number), []),
          })
        ),
        createdAt: "2023-10-20T10:22:29+00:00",
        fuelingType: types.number,
        id: types.number,
        number: types.optional(types.string, ""),
        region: types.maybeNull(
          types.model({
            contractors: types.optional(types.array(types.number), []),
            id: types.number,
            name: types.optional(types.string, ""),
          })
        ),
        updatedAt: types.optional(types.string, ""),
        vehicle: types.maybeNull(
          types.model({
            id: types.number,
            number: types.optional(types.string, ""),
          })
        ),
        volume: types.optional(types.string, ""),
        weight: types.optional(types.string, ""),
      })
    ),
    id: types.number,
    status: types.optional(types.number, 0),
  })
  .named("FuelingCommentListItemEntity");

export type IFuelingCommentListItemEntity = Instance<
  typeof FuelingCommentListItemEntity
>;

export type IFuelingCommentListItemEntityIn =
  SnapshotIn<IFuelingCommentListItemEntity>;

export type IFuelingCommentListItemEntityOut =
  SnapshotOut<IFuelingCommentListItemEntity>;
