/* eslint-disable sort-keys/sort-keys-fix */
import { ScreenControllerFuelingTurnover } from "@screens/HomeScreen/connector/UIViewController";
import { TranslationService } from "@services/translate";
import TableOutput from "@shared/controls/TableOutput/TableOutput";
import { useControllers } from "@view/hooks/useControllers";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import { useCallback, useState } from "react";

const headerList = {
  region_contractor: [TranslationService.t("txt_col_region_contractor")],
  contract: [TranslationService.t("txt_col_contract")],
  remains: [TranslationService.t("txt_col_remains"), true],
  expense: [TranslationService.t("txt_col_expense"), true],
  income: [TranslationService.t("txt_col_income"), true],
  document_number: [TranslationService.t("txt_col_document_number")],
  status_value: [TranslationService.t("txt_col_status"), true],
  option: [null],
};

export const HomeTable = observer((): JSX.Element | null => {
  const { UIViewController } = useControllers<{
    UIViewController: ScreenControllerFuelingTurnover;
  }>();

  const [isOpenContractorId, setIsOpenContractorId] = useState<
    { id: number; regionId: number }[]
  >([]);

  const handleOpenContractor = useCallback(
    (id: number, regionId: number) => {
      let newArr: { id: number; regionId: number }[] = [];
      if (
        isOpenContractorId.find(
          (el) => el.id === id && el.regionId === regionId
        )
      ) {
        newArr = isOpenContractorId.filter(
          (el) => el.id !== id && el.regionId !== regionId
        );
      } else {
        newArr = [...isOpenContractorId];
        newArr.push({ id, regionId });
      }

      UIViewController.fetchListOfFuelingTurnoverContract(id, regionId);
      setIsOpenContractorId(newArr);
    },
    [isOpenContractorId, setIsOpenContractorId]
  );

  if (UIViewController.isRequestInProgress) return null;

  return (
    <TableOutput
      sortBy={UIViewController.listOfFuelingTurnoverFilters.order}
      sortDir={UIViewController.listOfFuelingTurnoverFilters.direction}
      onChangeOrder={UIViewController.setFieldOrderBy}
      headerObj={headerList}
      list={UIViewController.listOfItemsForTable}
      docListFromContractorId={toJS(UIViewController.listOfOpenContractList)}
      isOpenContractorId={isOpenContractorId}
      handleOpenContractor={handleOpenContractor}
      isLoading={UIViewController.isRequestInProgress}
      resetFilters={() => UIViewController.resetFilters()}
    />
  );
});
