import { IObservableArray } from "mobx";
import { ScenesViewModel } from "@viewModels/scenes/ScenesViewModel";
import {
  IScenesInstance,
  TSceneConstructorParams,
} from "@viewModels/scenes/__types__/ScenesInstance";

export class UIViewController {
  public get scenes(): IObservableArray<
    [string, IScenesInstance<any, TSceneConstructorParams>]
  > {
    return ScenesViewModel.openedScenes;
  }
}
