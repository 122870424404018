import { IValidator, TValidatorOptions, TValidatorResult } from "./types";
import Joi, { ValidationResult } from "joi";

const defaultOptions: TValidatorOptions = {
  stripUnknown: true,
};

const validateJoi = <T = any>(
  schema: Joi.Schema<any>,
  value: T,
  options?: TValidatorOptions
): ValidationResult<T> => {
  const _options = { ...defaultOptions, ...options };

  return schema.validate(value, {
    abortEarly: false,
    allowUnknown: false,
    stripUnknown: _options.stripUnknown,
  });
};

export const validate: IValidator = <T = any>(
  schema: Joi.Schema<any>,
  value: T,
  options?: TValidatorOptions
): TValidatorResult<T> => {
  if (Joi.isSchema(schema)) {
    return validateJoi(schema, value, options);
  }

  return { error: undefined, value, warning: undefined };
};
