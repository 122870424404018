import { useRootTreeModel } from "../RootTreeModelProvider/RootTreeModelProvider";
import { IRootTreeModel } from "@models/RootTreeModel";

import { IReactionDisposer } from "mobx";
import { Provider } from "mobx-react";
import React, { useEffect, useRef } from "react";

export interface IUILogicController {
  /** Method will be called at component unmount  */
  beforeDestroy(): void;
}

export class UILogicController implements IUILogicController {
  public static controllerName = "UIViewController";

  /** Disposers storage object. Object should be cleared before destroying controller */
  public disposers: { [key: string]: IReactionDisposer } = {};

  public constructor(protected model: IRootTreeModel) {}

  public beforeDestroy(): void {}
}

type TReturnedControllers = {
  [key: string]: UILogicController;
};

const withControllers =
  <D = any,>(...controllers: (typeof UILogicController)[]) =>
  (Component: React.FC<any>) =>
  (props: D): JSX.Element => {
    const modelTree = useRootTreeModel();

    const _controllers = useRef<TReturnedControllers>(
      controllers.reduce((acc, Controller): TReturnedControllers => {
        acc[Controller.controllerName] = new Controller(modelTree);
        return acc;
      }, {} as TReturnedControllers)
    ).current;

    useEffect(
      () => () => {
        Object.values(_controllers).forEach((controller) =>
          controller.beforeDestroy()
        );
      },
      []
    );

    return (
      <Provider {..._controllers}>
        <Component {...props} />
      </Provider>
    );
  };

export default withControllers;
