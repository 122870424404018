import { TDtoProfileEntry } from "./__types__/TDtoProfileEntry.type";
import { TParseDto } from "../__types__/dto";
import { Utils } from "../..";

export const DtoProfileParser: TParseDto<TDtoProfileEntry> = (
  data: any
): TDtoProfileEntry => ({
  email: Utils.isString(data.email) ? data.email : "",
  firstName: Utils.isString(data.firstName) ? data.firstName : "",
  image: Utils.isString(data.image) ? data.image : "",
  lastName: Utils.isString(data.lastName) ? data.lastName : "",
  middleName: Utils.isString(data.middleName) ? data.middleName : "",
  phone: Utils.isString(data.phone) ? data.phone : "",
});
