import { IScreenControllerProfile } from "@screens/ProfileScreen/connector/__types__/UIViewController";
import { TranslationService } from "@services/translate";
import Button from "@shared/controls/Button/Button";
import Input from "@shared/controls/Input/Input";
import { useControllers } from "@view/hooks/useControllers";
import { observer } from "mobx-react";
import styled from "styled-components";
import InputMask from "react-input-mask";

export const ProfileMainForm = observer(() => {
  const { UIViewController } = useControllers<{
    UIViewController: IScreenControllerProfile;
  }>();

  return (
    <Gap42 className="col">
      <Gap24 className="col">
        <h3>{TranslationService.t("txt_subtitle_profile")}</h3>
        <Gap30 className="col">
          <Gap24 className="col">
            <div className="custom-field">
              <label>Фамилия</label>
              <Input
                value={UIViewController.profileEditState.lastName}
                type={"text"}
                placeholder="Введите фамилию"
                onChange={(value) => UIViewController.setLastName(value)}
              />
            </div>
            <div className="custom-field">
              <label>Имя</label>
              <Input
                value={UIViewController.profileEditState.firstName}
                type={"text"}
                placeholder="Введите имя"
                onChange={(value) => UIViewController.setFirstName(value)}
              />
            </div>
            <div className="custom-field">
              <label>Отчество</label>
              <Input
                value={UIViewController.profileEditState.middleName}
                type={"text"}
                placeholder="Введите отчество"
                onChange={(value) => UIViewController.setMiddleName(value)}
              />
            </div>
          </Gap24>
          <Gap30 className="col">
            <div
              className={`custom-field ${
                UIViewController.profileErrors.errorPhone ? "error" : ""
              }`}
            >
              <label>Номер телефона</label>
              <div className="col">
                <InputMask
                  mask={"+7 999 999 99 99"}
                  id="phone"
                  name="phone"
                  required
                  type="tel"
                  placeholder="+7 (xxx) xxx-xx-xx"
                  value={UIViewController.profileEditState.phone}
                  onChange={(event) =>
                    UIViewController.setPhone(
                      event.target.value.replaceAll(" ", "")
                    )
                  }
                />
                {UIViewController.profileErrors.errorPhone && (
                  <Error>Проверьте количество цифр в номере телефона</Error>
                )}
              </div>
            </div>
            <div className={`custom-field disabled`}>
              <label>Email</label>
              <div className="col">
                <Input
                  value={UIViewController.profileEditState.email}
                  disabled={true}
                  placeholder="Введите email"
                  type={"text"}
                />
                <Hint>Нельзя изменить</Hint>
              </div>
            </div>
          </Gap30>
        </Gap30>
      </Gap24>
      <Button
        text="Сохранить"
        type="button"
        className="primary"
        size="small"
        onClick={UIViewController.saveProfileMainInfo}
        loading={
          UIViewController.isMainInProgress ||
          UIViewController.isFetchingInProgress
        }
        disabled={
          !UIViewController.profileEditState.firstName?.length ||
          !UIViewController.profileEditState.lastName?.length ||
          !UIViewController.profileEditState.middleName?.length ||
          UIViewController.isMainInProgress ||
          UIViewController.isFetchingInProgress
        }
      />
    </Gap42>
  );
});

const Gap42 = styled.div`
  gap: 42px;
`;

const Gap24 = styled.div`
  gap: 24px;
`;

const Gap30 = styled.div`
  gap: 30px;
`;

const Error = styled.p`
  position: absolute;
  left: 0;
  bottom: -20px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #ef5d58;
`;

const Hint = styled.p`
  position: absolute;
  left: 0;
  bottom: -20px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
`;
