import { IScreenControllerDocScore } from "./__types__/UIViewController";
import { IReactionDisposer } from "mobx";
import { UILogicController } from "@view/providers/__proto__/withController";
import { IRootTreeModel } from "@models/RootTreeModel";
import { toast } from "react-toastify";
import { getMessageFromError } from "@modules/utils/getMessageFromError";
import { IScoreListItemEntity } from "@entities/ScoreListItemEntity";
import { RequestStatus } from "@constants/repositories";
import { TranslationService } from "@services/translate";
import { SceneControllerScoreCreate } from "@scenes/ScoreCreateScene";
import { SceneControllerContractsFilters } from "@scenes/FiltersScene";
import {
  IScoresListFilters,
  IScoresListViewModel,
  TScoresFieldsOrder,
} from "@viewModels/ScoreListViewModel/__types__/IScoresListViewModel";
import { ScoresListViewModel } from "@viewModels/ScoreListViewModel/ScoresListViewModel";
import { IPagination } from "@viewModels/pagination/types";
import { ScoreCreateViewModel } from "@viewModels/ScoreCreateViewModel/ScoreCreateViewModel";
import {
  IScoreCreateState,
  IScoreCreateViewModel,
} from "@viewModels/ScoreCreateViewModel/__types__/IScoresListViewModel";
import { ScenesViewModel } from "@viewModels/scenes/ScenesViewModel";
import { IFiltersViewModel } from "@viewModels/FiltersViewModel/__types__/IFiltersViewModel";
import { FiltersViewModel } from "@viewModels/FiltersViewModel/FiltersViewModel";
import { ErrorScene, ScenesErrors } from "@viewModels/scenes/ErrorScene";
import {
  DateFilerType,
  IDatePeriodFilterViewModelParams,
} from "@viewModels/DatePeriodFilterViewModel/__types__/IDatePeriodFilterViewModel.types";

class ScreenControllerDocScore
  extends UILogicController
  implements IScreenControllerDocScore
{
  public disposers: { [key: string]: IReactionDisposer } = {};

  private ScoresListViewModel: IScoresListViewModel;

  private ScoreCreateViewModel: IScoreCreateViewModel;

  private FiltersViewModel: IFiltersViewModel;

  public constructor(model: IRootTreeModel) {
    super(model);

    this.ScoresListViewModel = new ScoresListViewModel(model, {
      dateType: DateFilerType.Month,
      search: "",
    });

    this.ScoreCreateViewModel = new ScoreCreateViewModel(model);

    this.FiltersViewModel = new FiltersViewModel(model, {});
  }

  /** Indicator for otp request confirmation or resending otp request */
  public get isRequestInProgress(): boolean {
    return (
      this.ScoresListViewModel.statuses.getStatus("fetchItems") ===
      RequestStatus.Pending
    );
  }

  public get totalNumberOfScores(): number {
    return this.ScoresListViewModel.meta.total;
  }

  public get listOfScoresMetadata(): IPagination<IScoreListItemEntity> {
    return this.ScoresListViewModel.metadata;
  }

  public get listOfScoresFilters(): IScoresListFilters {
    return this.ScoresListViewModel.filters;
  }

  public get createScoreState(): IScoreCreateState {
    return this.ScoreCreateViewModel.state;
  }

  public get filterCount(): number {
    return this.FiltersViewModel.state.filterCount;
  }

  public setFieldOrderBy = (
    field: TScoresFieldsOrder,
    direction: "asc" | "desc"
  ): void => {
    try {
      this.ScoresListViewModel.setFieldOrder(field, direction);

      this.ScoresListViewModel.fetchItemsBatch(1, true);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setDateFilter = (params: IDatePeriodFilterViewModelParams): void => {
    try {
      this.ScoresListViewModel.setDateFilter(params);

      this.ScoresListViewModel.fetchItemsBatch(1, true);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setFilterCount(count: number): void {
    try {
      this.FiltersViewModel.setFilterCount(count);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  }

  public fetchListOfScores = (): void => {
    try {
      this.ScoresListViewModel.fetchItemsBatch(
        this.ScoresListViewModel.meta.current_page + 1,
        false
      );
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public openCreateScoreScene = async (): Promise<void> => {
    try {
      await ScenesViewModel.openRegisteredScene(SceneControllerScoreCreate, {
        model: this.model,
      });

      // Notify user about success action
      toast.success(TranslationService.t("txt_success_contract_created"));

      await this.ScoresListViewModel.fetchItemsBatch(1, true);
    } catch (error) {
      if (error instanceof ErrorScene) {
        if (error.cause === ScenesErrors.UserClosedScene) {
          return;
        }
      }

      toast.error(getMessageFromError(error));
    }
  };

  public resetFilters = async (): Promise<void> => {
    try {
      this.ScoresListViewModel.setRegion(null);
      this.ScoresListViewModel.setScore(null);
      this.ScoresListViewModel.setContract(null);

      this.setFilterCount(0);

      await this.ScoresListViewModel.fetchItemsBatch(1, true);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public openScoresFiltersScene = async (): Promise<void> => {
    try {
      const result = await ScenesViewModel.openRegisteredScene(
        SceneControllerContractsFilters,
        {
          contract: this.ScoresListViewModel.filters.contract,
          fields: {
            contract: true,
            region: true,
            score: true,
          },
          model: this.model,
          region: this.ScoresListViewModel.filters.region || undefined,
          score: this.ScoresListViewModel.filters.score,
        }
      );

      this.ScoresListViewModel.setRegion(result.region);
      this.ScoresListViewModel.setScore(result.score);
      this.ScoresListViewModel.setContract(result.contract);

      this.setFilterCount(result.filterCount);

      await this.ScoresListViewModel.fetchItemsBatch(1, true);
    } catch (error) {
      if (error instanceof ErrorScene) {
        if (error.cause === ScenesErrors.UserClosedScene) {
          return;
        }
      }

      toast.error(getMessageFromError(error));
    }
  };

  public beforeDestroy = (): void => {
    this.ScoresListViewModel.beforeDestroy();
    this.ScoreCreateViewModel.beforeDestroy();
    this.FiltersViewModel.beforeDestroy();
    /** Cancel all disposers */
    Object.values(this.disposers).map((func) => func.call(undefined));
  };
}

export { ScreenControllerDocScore };
