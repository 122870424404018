import {
  IScoreCreateState,
  IScoreCreateViewModel,
} from "./__types__/IScoresListViewModel";
import { Statuses } from "../statuses";
import { IRootTreeModel } from "@models/RootTreeModel";
import { ScoresRepository } from "@repositories/scores";
import { IScoresRepository } from "@repositories/scores/__types__/repository";

import moment, { Moment } from "moment";
import { TranslationService } from "@services/translate";
import { observable } from "mobx";
import { RequestStatus } from "@constants/repositories";
import { AxiosRequestClient } from "@modules/index";

// Defining initialDateTime using moment library
const initialDateTime: Moment = moment();

/**
 * ScoreCreateViewModel class
 *
 * @class
 * @implements {IScoreCreateViewModel}
 */
export class ScoreCreateViewModel implements IScoreCreateViewModel {
  /**
   * Statuses instance for managing status
   *
   * @type {Statuses}
   */
  public statuses: Statuses = new Statuses(["createScore"]);

  /**
   * State of the ScoreCreateViewModel
   *
   * @type {IScoreCreateState}
   */
  public state: IScoreCreateState = observable(this.initialState);

  /**
   * Repository for managing scores
   *
   * @type {IScoresRepository}
   * @private
   */
  private repository: IScoresRepository = new ScoresRepository(
    new AxiosRequestClient()
  );

  /**
   * Constructor for ScoreCreateViewModel
   *
   * @param {IRootTreeModel} model - RootTreeModel instance
   */
  public constructor(private model: IRootTreeModel) {}

  /**
   * Get the initial state of ScoreCreateViewModel
   *
   * @returns {IScoreCreateState} - The initial state object
   * @private
   */
  private get initialState(): IScoreCreateState {
    return {
      amount: 0,
      contract: null,
      deliveryDate: initialDateTime,
      number: "",
      price: 0,
      regions: null,
      weight: 0,
    };
  }

  /**
   * Set the contract in the state
   *
   * @param {object} contract - The contract object
   * @param {number} contract.id - The contract ID
   * @param {string} contract.name - The contract name
   */
  public setContract = (
    contract: { id: number; number: string } | null
  ): void => {
    this.state.contract = contract;
  };

  /**
   * Set the score number in the state
   *
   * @param {string} numberScore - The score number
   */
  public setScoreNumber = (numberScore: string): void => {
    this.state.number = numberScore;
  };

  /**
   * Set the delivery date in the state
   *
   * @param {Moment} date - The delivery date
   */
  public setDeliveryDate = (date: Moment): void => {
    this.state.deliveryDate = date;
  };

  /**
   * Set the regions in the state
   *
   * @param {Array} data - The regions data
   */
  public setRegions = (data: { id: number; name: string }[] | null): void => {
    this.state.regions = data ? data : null;
  };

  /**
   * Set the price in the state
   *
   * @param {number} price - The price
   */
  public setPrice = (price: number): void => {
    this.state.price = price;
  };

  /**
   * Set the amount in the state
   *
   * @param {number} amount - The amount
   */
  public setAmount = (amount: number): void => {
    this.state.amount = amount;
  };

  /**
   * Set the weight in the state
   *
   * @param {number} weight - The weight
   */
  public setWeight = (weight: number): void => {
    this.state.weight = weight;
  };

  /**
   * Create a new score
   *
   * @param {IScoreCreateState} data - The score data to be created
   * @returns {Promise<{ id: number }>} - The created score ID
   */
  public createScore = async (
    data: IScoreCreateState
  ): Promise<{ id: number }> => {
    try {
      // Validation checks
      if (!data.contract || data.contract === null) {
        throw new Error(
          TranslationService.t("txt_wrong_contract_number_format")
        );
      }

      if (!data.number) {
        throw new Error(TranslationService.t("txt_wrong_score_format"));
      }

      if (data.price <= 0) {
        throw new Error(TranslationService.t("txt_wrong_price_format"));
      }

      if (!data.price) {
        throw new Error(TranslationService.t("txt_error_missing_price"));
      }

      if (data.weight <= 0) {
        throw new Error(TranslationService.t("txt_wrong_weight_format"));
      }

      if (!data.weight) {
        throw new Error(TranslationService.t("txt_error_missing_weight"));
      }

      if (!data.regions?.length)
        throw new Error(TranslationService.t("txt_error_missing_region"));

      if (!data.deliveryDate)
        throw new Error(TranslationService.t("txt_wrong_delivery_date"));

      if (!data.deliveryDate.isValid())
        throw new Error(TranslationService.t("txt_wrong_delivery_date"));

      this.statuses.setStatus("createScore", RequestStatus.Pending);

      // Create the score using the repository
      const response = await this.repository.createScore({
        contract: data.contract?.id,
        deliveryDate: moment(data.deliveryDate).format("YYYY-MM-DD"),
        number: data.number,
        price: data.price,
        regions: data.regions.map((region) => region.id),
        weight: data.weight,
      });

      this.statuses.setStatus("createScore", RequestStatus.Success);

      return response;
    } catch (error) {
      this.statuses.setStatus("createScore", RequestStatus.Error);
      throw error;
    }
  };

  /**
   * Clear the state of ScoreCreateViewModel
   */
  public clearState = (): void => {
    this.state = this.initialState;
  };

  /**
   * Perform cleanup tasks before destroying the ScoreCreateViewModel
   */
  public beforeDestroy = (): void => {
    // Additional cleanup tasks can be performed here
    this.repository.beforeDestroy();
  };
}
